import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";
import {
    ExcursionDataCreated,
    ExcursionDataGet,
    ExcursionDataPost,
    ExсursionDataPatch,
} from "../containers/services/excursion/helpers/types";

const excursionsV2PartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.excursionsV2}`;

export const createExcursion = async (data: ExcursionDataPost) => {
    const service: ExcursionDataCreated = await fetchRequest.post(excursionsV2PartnerServiceUrl, data, {
        isProtected: true,
    });
    return service;
};

export const updateExcursion = async (data: ExсursionDataPatch) => {
    const result: ExcursionDataCreated = await fetchRequest.patch(excursionsV2PartnerServiceUrl, data, {
        isProtected: true,
    });

    return result;
};

export const deleteExcursion = async (id: string) => {
    const result = await fetchRequest.delete(`${excursionsV2PartnerServiceUrl}/${id}`, {
        isProtected: true,
    });

    return result;
};

export const sendExcursionForApproval = async (id: string) => {
    const content = await fetchRequest.patch(`${excursionsV2PartnerServiceUrl}/sendForApproval/${id} `, null, {
        isProtected: true,
    });
    return content;
};

export const getExcursion = async (id: string): Promise<ExcursionDataGet> => {
    const excursion = await fetchRequest.get(`${excursionsV2PartnerServiceUrl}/${id}`, null, {
        isProtected: true,
    });
    return excursion;
};

export const getExcursionsAll = async () => {
    const data = await fetchRequest.get(excursionsV2PartnerServiceUrl, null, {
        isProtected: true,
    });
    return data;
};
