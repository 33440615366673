import { InputField } from "@russpass/partner-front-ui";
import React from "react";

export const SignatoryPositionField = () => (
    <InputField
        label="Должность подписанта"
        name={"position"}
        noHover
        placeholder="Генеральный директор"
        maxLength={200}
    />
);
