/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { createFacility, sendFacilityForApproval, updateFacility } from "../../../../api/facility";
import { createService, sendServiceForApproval, updateService } from "../../../../api/service";
import { updateTariff } from "../../../../api/billing";
import { POST_DATA_ERROR, StatusRequestCardSubtitleError } from "../../../../constants/errors";
import { navbarService, NavbarServiceNamesEnum } from "../helpers/constants";
import { NewServiceData, NewServiceDataPost, ServiceDataCreated, ServiceDataPatch } from "../helpers/types";
import { getWorkingTimeValidation } from "../../components/service-place/validation-schema";

import { NotificationProps } from "../../../../components/notification";

import { Tariff, TariffDates } from "../../components/tariffs/types";
import { cloneDeep } from "lodash";

import { processingForUpdateTariff } from "../../utils";
import { createTariffs } from "../../components/tariffs/utils";
import { eventDispatcher, StatusRequestEnum } from "@russpass/partner-front-ui";
import { getTimetableData } from "../utils";
import useUserInfo from "../../../../hooks/useUserInfo";
import { Facility, PartnerFacilityType } from "../../types";
import { useCreateEventModalContext } from "../../components/contexts/createEventStatusModal";
import { CreateEventResultStatusEnum } from "../../components/CreateEventResultModal/create-event-modal-enum";
import history from "../../../../history";
import { checkValidServiceFields } from "../helpers/checkValidServiceFields";
import { getErrorForm } from "../helpers/getErrorForm";
import { useLocation, useParams } from "react-router-dom";
import { Params } from "@sentry/react/types/types";
import { formatDraftServiceToPost, formatDuration, formatFacilityToPost, formatServiceToPost } from "../helpers/format";
import { saveImagesForDraft, saveImagesToCMS } from "../../components/photosNew/utils";
import { patchServiceDraft, saveServiceDraft } from "../../../../api/draft/service";
import CustomError from "../../../../lib/custom-error";
import routes from "../../../../constants/routes";
import { navbarServicesNames } from "../../all/constants";

type Props = {
    data: NewServiceData;
    initialData?: NewServiceData;
    tariffs: Tariff[];
    facility: Facility;
    selectedPartnerFacility: PartnerFacilityType | null;
    isExistingPartnerFacility: boolean;
    onChangeTariff: (tariffs: Tariff[]) => void;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeData: (values: NewServiceData) => void;
    reloadData: () => void;
    isArchive?: boolean;
};

export const useServiceActions = ({
    data,
    initialData,
    tariffs,
    facility,
    selectedPartnerFacility,
    isExistingPartnerFacility,
    onChangeTariff,
    setEditMode,
    onChangeData,
    reloadData,
    isArchive,
}: Props) => {
    const { draftServiceId }: Params = useParams();

    const [navbar, setNavbar] = useState(cloneDeep(navbarService));

    const [isFormSending, setIsFormSending] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const [isDraftSave, setIsDraftSave] = useState(false);

    const [facilitySaved, setFacilitySaved] = useState<Pick<Facility, "id" | "cmsFacilityId"> | null>(null);
    const [serviceSaved, setServiceSaved] = useState<ServiceDataCreated | null>(null);
    const [tariffsAreSaved, setTariffsAreSaved] = useState(false);
    const [facilitiesAreApproved, setFacilitiesAreApproved] = useState(false);

    const location = useLocation();

    const goBack = () => {
        history.push({
            pathname: routes.servicesAll,
            state: isArchive
                ? {
                      status: navbarServicesNames.archive,
                      redirectFromArchive: true,
                  }
                : location?.state,
        });
    };

    const isValidFacilityWorkingTime = useMemo(
        () => getWorkingTimeValidation().isValidSync(facility.workingTime),
        [facility.workingTime]
    );

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const { userFullInfo } = useUserInfo();
    const { openEventStatusModal } = useCreateEventModalContext();

    const checkIsValid = (isSubmittingForm: boolean) => {
        const { isValidInfo, isValidPlace, isValidPhoto, isValidTariffs, isValidAll } = checkValidServiceFields({
            data,
            facility,
            tariffs,
            isSubmittingForm,
        });

        setNavbar((prev) => {
            return prev.map((item: any) => {
                if (item.name === NavbarServiceNamesEnum.Service) item.isError = !isValidInfo;
                if (item.name === NavbarServiceNamesEnum.Place) item.isError = !isValidPlace;
                if (item.name === NavbarServiceNamesEnum.Photo) item.isError = !isValidPhoto;
                if (item.name === NavbarServiceNamesEnum.Tariff) item.isError = !isValidTariffs;
                return item;
            });
        });

        if (!isValidAll) {
            const error = getErrorForm({
                isValidInfo,
                isValidPlace,
                isValidPhoto,
                isValidTariffs,
            });

            callNotification({
                status: StatusRequestEnum.Error,
                body: error || "",
            });
        }

        return isValidAll;
    };

    useEffect(() => {
        setFacilitySaved(null);
        setServiceSaved(null);
        setTariffsAreSaved(false);
        setFacilitiesAreApproved(false);
    }, [data]);

    const sendToDraft = useCallback(
        async (tariffDates: TariffDates) => {
            setIsSubmittingForm(false);
            setIsDraftSave(true);
            if (!checkIsValid(false) || !userFullInfo?.applicationId) {
                return Promise.reject(new Error("Не заполнены обязательные поля"));
            }
            setIsFormSending(true);
            try {
                const serviceImages = await saveImagesForDraft(data.imagesFiles, userFullInfo.applicationId);
                const draftData = await formatDraftServiceToPost(data, facility, tariffs, serviceImages, tariffDates);
                if (draftServiceId) {
                    await patchServiceDraft(draftServiceId, draftData);
                } else {
                    await saveServiceDraft(userFullInfo?.applicationId, draftData);
                }

                callNotification({
                    status: StatusRequestEnum.Success,
                    body: "Услуга сохранена в черновике",
                });
            } catch (err) {
                console.error(err);
                callNotification({
                    status: StatusRequestEnum.Error,
                    body: "Не удалось сохранить черновик. Повторите попытку или обратитесь в Поддержку для уточнения информации",
                });
                return Promise.reject(
                    new Error(
                        "Не удалось сохранить черновик. Повторите попытку или обратитесь в Поддержку для уточнения информации"
                    )
                );
            } finally {
                setIsFormSending(false);
            }
        },
        [data, userFullInfo, tariffs, draftServiceId, facility]
    );

    const sendServiceToModerate = async (tariffDates: TariffDates) => {
        if ((!userFullInfo && !userFullInfo.email) || isFormSending) {
            return Promise.reject(new Error("Нет данных профиля"));
        }

        setIsSubmittingForm(true);
        if (!checkIsValid(true)) {
            return Promise.reject(new Error("Не заполнены обязательные поля"));
        }

        setIsFormSending(true);

        try {
            const facilityData = formatFacilityToPost(facility);

            const facilityCreated = !isExistingPartnerFacility
                ? facilitySaved
                    ? { ...facilitySaved }
                    : await createFacility(facilityData)
                : selectedPartnerFacility;
            setFacilitySaved(facilityCreated);

            const serviceImages = await saveImagesToCMS(data.imagesFiles, !!draftServiceId);

            const serviceData: NewServiceDataPost = formatServiceToPost(
                data,
                facility,
                facilityCreated?.cmsFacilityId || "",
                tariffs,
                serviceImages
            );

            const serviceCreated: ServiceDataCreated = serviceSaved
                ? { ...serviceSaved }
                : await createService({
                      ...serviceData,
                  });

            setServiceSaved(serviceCreated);

            if (tariffs.length > 0 && !tariffsAreSaved) {
                await createTariffs(tariffs, +serviceCreated.billingProductId, tariffDates);
                setTariffsAreSaved(true);
            }

            if (!facilitiesAreApproved && !isExistingPartnerFacility && facilityCreated?.cmsFacilityId) {
                await sendFacilityForApproval(facilityCreated?.cmsFacilityId);
                setFacilitiesAreApproved(true);
            }

            await sendServiceForApproval(serviceCreated.cmsEventId);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                title: `Услуга «${data.title}» отправлена на модерацию.`,
                desc: (
                    <>
                        В течение 5 рабочих дней вы получите ответ на{" "}
                        <span className="footnote">{userFullInfo.email}</span>
                    </>
                ),
                closeBtnAction: goBack,
            });
        } catch (err) {
            console.error(err);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                title: `Не удалось отправить на модерацию «${data.title}».`,
                desc: StatusRequestCardSubtitleError,
                errorBtnAction: () => sendServiceToModerate(tariffDates),
                closeBtnAction: goBack,
            });

            return Promise.reject(new Error(`Не удалось отправить на модерацию «${data.title}».`));
        } finally {
            setIsFormSending(false);
        }
    };

    const sendServiceUpdate = async (
        sendToBilling: boolean,
        sendToCms: boolean,
        patchMode?: boolean,
        serviceForApproval?: boolean
    ) => {
        if (!data.id || isFormSending) return;

        //TODO при редактировании услуги не меняется data.facility, поэтому вх параметр sendToCms = false, хотя нужен true - разобраться при рефакторинге
        // в некоторых случаях facility.cmsFacilityId = undefined, а data.facility = null, и сравнение получается true РАЗОБРАТЬСЯ при рефакторинге
        const _cmsFacilityId = facility.cmsFacilityId || undefined;
        const _facilityId = data.facility || undefined;
        const sendToCms_ = sendToCms || _cmsFacilityId !== _facilityId;
        const patchMode_ = patchMode !== undefined ? patchMode : sendToCms_;

        setIsSubmittingForm(true);
        if (!checkIsValid(true)) return;

        setIsFormSending(true);

        let newCmsFacilityId;
        if (facility.id !== data.facility) {
            if (facility.id) {
                //TODO вызывать не всегда, а только если данные площадки изменились от той, что уже есть в цмс
                await updateFacility({
                    address: facility.address,
                    city: facility.city || [],
                    geoData: facility.geoData || [],
                    id: facility.id,
                    isAvailableForGuide: !!facility.isAvailableForGuide,
                    partnerType: facility.partnerType,
                    phones: facility.phones,
                    region: facility.region || "",
                    title: facility.title,
                    workingTime: getTimetableData(facility.workingTime),
                });
            } else {
                const facilityData = formatFacilityToPost(facility);

                newCmsFacilityId = (await createFacility(facilityData)).cmsFacilityId;
                await sendFacilityForApproval(newCmsFacilityId);
            }
        }

        try {
            const serviceImages = await saveImagesToCMS(data.imagesFiles, !!draftServiceId);

            const serviceData = await formatServiceToPost(
                data,
                facility,
                newCmsFacilityId || facility.cmsFacilityId || "",
                tariffs,
                serviceImages
            );
            await updateService({
                ...serviceData,
                sendToBilling: sendToBilling,
                sendToCms: sendToCms_,
                patchMode: patchMode_,
            });

            if (serviceForApproval) {
                await sendServiceForApproval(serviceData.cmsEventId);
            }

            reloadData();

            setEditMode(false);

            const moderateMessage = {
                title: `Услуга «${data.title}» отправлена на модерацию.`,
                desc: (
                    <>
                        В течение 5 рабочих дней вы получите ответ на{" "}
                        <span className="footnote">{userFullInfo.email}</span>
                    </>
                ),
            };

            const updateMessage = {
                title: `Услуга «${data.title}» сохранена.`,
                desc: "Изменения опубликованы на сайте",
            };

            const publishMessage = {
                title: `Услуга «${data.title}» опубликована.`,
                desc: "Изменения опубликованы на сайте",
            };

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                ...(isArchive && !serviceForApproval ? publishMessage : sendToCms_ ? moderateMessage : updateMessage),
                closeBtnAction: goBack,
            });
        } catch {
            const moderateErrorMessage = {
                title: `Не удалось отправить на модерацию «${data.title}».`,
            };

            const updateErrorMessage = {
                title: `Не удалось сохранить услугу ${data.title}`,
            };
            const publishErrorMessage = {
                title: `Не удалось опубликовать услугу ${data.title}`,
            };

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                desc: StatusRequestCardSubtitleError,
                ...(isArchive && !serviceForApproval
                    ? publishErrorMessage
                    : sendToCms_
                    ? moderateErrorMessage
                    : updateErrorMessage),
                errorBtnAction: () => sendServiceUpdate(sendToBilling, sendToCms_, patchMode_),
                closeBtnAction: goBack,
            });
        } finally {
            setIsFormSending(false);
        }
    };

    const addTariffForExistingService = useCallback(
        async (newTariffs, tariffDates) => {
            setIsFormSending(true);

            if (userFullInfo && userFullInfo.email && newTariffs.length > 0 && data.billingProductId) {
                try {
                    setIsFormSending(true);
                    const createdTariffs = await createTariffs(newTariffs, +data.billingProductId, tariffDates);
                    onChangeTariff([...tariffs, ...createdTariffs]);
                    callNotification({
                        status: StatusRequestEnum.Success,
                        body: "Новый тариф отправлен",
                    });
                } catch (error) {
                    console.error(error);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                } finally {
                    setIsFormSending(false);
                }
            }
        },
        [userFullInfo, data, tariffs]
    );

    const deleteTariffForExistingService = useCallback(
        async (tariff) => {
            if (userFullInfo && userFullInfo.email) {
                setIsFormSending(true);
                try {
                    await updateTariff(processingForUpdateTariff({ tariff, isDelete: true }), tariff.id);
                    setIsFormSending(false);
                    callNotification({
                        status: StatusRequestEnum.Success,
                        body: "Тариф удален",
                    });
                } catch (error) {
                    console.error(error);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                }
            }
        },
        [userFullInfo, data]
    );

    const updateTariffForExistingService = async (
        newTariff: Tariff,
        showNotification = true,
        isSendRegistration = true
    ) => {
        setIsFormSending(true);

        if (userFullInfo && userFullInfo.email && newTariff.id) {
            setIsFormSending(true);
            try {
                await updateTariff(
                    processingForUpdateTariff({ tariff: newTariff, isDelete: false, isSendRegistration }),
                    newTariff.id
                );
                if (showNotification) {
                    callNotification({
                        status: StatusRequestEnum.Success,
                        body: "Обновленный тариф отправлен",
                    });
                }
            } catch (error) {
                console.error(error);
                throw new CustomError({
                    message: "Ошибка обновления тарифа",
                });
            } finally {
                setIsFormSending(false);
            }
        }
    };

    const addTariffForDraftService = useCallback(
        async (newTariffs) => {
            setIsFormSending(true);
            onChangeTariff([...tariffs, ...newTariffs]);
            setIsFormSending(false);
        },
        [tariffs]
    );

    const deleteTariffForDraftService = useCallback(
        async (tariff) => {
            setIsFormSending(true);
            onChangeTariff(tariffs.filter((item) => tariff.id !== item.id));
            setIsFormSending(false);
        },
        [tariffs]
    );

    const updateTariffForDraftService = useCallback(
        async (tariff) => {
            setIsFormSending(true);
            onChangeTariff(
                tariffs.map((item) => {
                    if (tariff.id === item.id) {
                        return tariff;
                    }
                    return item;
                })
            );
            setIsFormSending(false);
        },
        [tariffs]
    );

    //функция только для апдейта базовой цены/онлайн продажи
    const updateServiceFields = async (updateFields: { [x in keyof ServiceDataPatch]?: any }) => {
        if (draftServiceId) return;
        if (!initialData) return;

        setIsFormSending(true);

        const additionalFields: Pick<
            ServiceDataPatch,
            "personalizedTickets" | "ndsPercent" | "ticketPrice" | "sendToBilling" | "sendToCms" | "patchMode"
        > = {
            personalizedTickets: initialData.personalizedTickets ?? false, // если значение не указано - заполняем false
            ndsPercent: initialData?.ndsPercent || "",
            ticketPrice: tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "",
            //это стандартные поля
            sendToBilling: false,
            sendToCms: true,
            patchMode: false,
            ...updateFields,
        };

        try {
            await updateService({
                ...initialData,
                duration: formatDuration(initialData.duration),
                ...additionalFields,
            });

            if (updateFields.hasOwnProperty("isCanBuy")) {
                callNotification({
                    status: StatusRequestEnum.Success,
                    body: data.isCanBuy
                        ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                        : "Продажа на RUSSPASS включена",
                });
            }

            onChangeData({
                ...data,
                ...updateFields,
            });
        } catch (err) {
            console.error(err);

            callNotification({
                status: StatusRequestEnum.Error,
                body: POST_DATA_ERROR,
            });
        } finally {
            setIsFormSending(false);
        }

        setIsFormSending(false);
    };

    return {
        sendServiceToModerate,
        sendServiceUpdate,
        isValidFacilityWorkingTime,
        isFormSending,
        setIsFormSending,
        navbar,
        isSubmittingForm,
        addTariffForExistingService,
        deleteTariffForExistingService,
        updateTariffForExistingService,
        addTariffForDraftService,
        deleteTariffForDraftService,
        updateTariffForDraftService,
        updateServiceFields,
        sendToDraft,
        isDraftSave,
    };
};
