import React, { useCallback, useMemo } from "react";
import history from "../../../history";
import { navbarTourComment, navbarTourNames, navbarTourRatings } from "./constants";
import TourInfo from "./components/tour-info";
import useTour from "./useTour";
import TourProgram from "./components/tour-program";
import useSendToModerate from "./useSendToModerate";
import TourComment from "./components/tour-comment";
import routes from "../../../constants/routes";
import { useLocation } from "react-router-dom";
import { navbarTypeEventNames } from "../all/constants";
import { Ratings } from "../components/ratings/ratings";
import { EmptyList, CommonLayout, UploadPhotosItem } from "@russpass/partner-front-ui";
import { successTitleText } from "../../../constants/layout";
import { noop } from "lodash";
import { PlaceTimetableButtonKeys } from "../../../components/timetable/place-timetable/constants";
import Tariffs from "../components/tariffs/tariffs";
import useTariffs from "../components/tariffs/useTariffs";
import { ServiceTariffsContext } from "../components/tariffs/tariffs-context";
import Photos from "../components/photos";
import ActionsContainer from "../components/actions-container";
import useUserInfo from "../../../hooks/useUserInfo";

const Tour = () => {
    const { tariffs, loadTariffList, onChangeTariffList, isLoadingTariffList } = useTariffs();
    const { isConcluded } = useUserInfo();
    const {
        isLoadingForm,
        step,
        changeTab,
        tourData,
        onChangeData,
        region,
        changeRegion,
        city,
        changeCity,
        isViewTour,
        optionsAgeRestriction,
        goToTabTour,
        isViewComment,
        isArchive,
        sendArchiveToModerate,
    } = useTour({ loadTariffList });

    const {
        isSubmittingForm,
        isAwaitRequest,
        goToModerate,
        addTariffForExistingTour,
        deleteTariffForExistingTour,
        updateTariffForExistingTour,
        navbar,
    } = useSendToModerate({
        data: tourData,
        tariffs: tariffs.content,
        onChangeTariff: onChangeTariffList,
        step,
    });

    const localNavbar = useMemo(() => {
        let _navbar = navbar;
        if (isViewComment) {
            _navbar = navbarTourComment.concat(_navbar);
        }
        if (isViewTour) {
            _navbar = _navbar.concat(navbarTourRatings);
        }
        return _navbar;
    }, [navbar, isViewComment, isViewTour]);

    const location = useLocation<{ status: string }>();

    const topRightButton = useMemo(
        () =>
            !isViewTour
                ? {
                      title: successTitleText.sendToModerate.text,
                      onClick: !isAwaitRequest ? goToModerate : noop,
                  }
                : undefined,
        [goToModerate, isAwaitRequest, isViewTour]
    );

    const backButton = useMemo(
        () => ({
            title: "Назад",
            onClick: () => {
                history.push({
                    pathname: routes.servicesAll,
                    state: { ...location?.state, type: navbarTypeEventNames.tours },
                });
            },
        }),
        [location?.state]
    );

    const actionsObj = {
        sendPublish: {
            text: "Опубликовать",
            action: () => {
                sendArchiveToModerate();
            },
        },
    };

    const tariffsProviderValue = useMemo(
        () => ({
            tariffs,
            loadTariffList,
            billingProductId: tourData.billingProductId,
            onChangeTariffList,
        }),
        [loadTariffList, onChangeTariffList, tariffs, tourData.billingProductId]
    );

    const images = useMemo(() => tourData.images?.map((image) => ({ image })) || [], [tourData.images]);

    const onChangeImage = useCallback(
        (values: UploadPhotosItem[]) =>
            onChangeData({
                ...tourData,
                images: values.map((item) => item.image),
                imageDetailedPageMain: values?.length ? [values[0].image] : [],
                imageExplorePreview: values?.length ? [values[0].image] : [],
            }),
        [tourData, onChangeData]
    );

    return (
        <ServiceTariffsContext.Provider value={tariffsProviderValue}>
            <CommonLayout
                pageTitle={tourData.title || "Новый тур"}
                topRightButton={isConcluded ? topRightButton : undefined}
                backButton={backButton}
                activeTabName={step}
                handlerChangeActiveTab={changeTab}
                isLoadingWithoutContent={isLoadingForm}
                isLoadingWithContent={isAwaitRequest || isLoadingTariffList}
                navbar={localNavbar}
                fullHeight={step === navbarTourNames.ratings}
                isHideControlPanel
            >
                <div data-name={navbarTourNames.comment} data-hide={!isViewComment}>
                    <TourComment textNotes={tourData?.rejectionReason ?? ""} />
                </div>
                <div data-name={navbarTourNames.tour}>
                    <TourInfo
                        optionsAgeRestriction={optionsAgeRestriction}
                        data={tourData}
                        onChange={onChangeData}
                        region={region}
                        onChangeRegion={changeRegion}
                        city={city}
                        onChangeCity={changeCity}
                        onSubmit={changeTab}
                        isShowErrors={isSubmittingForm}
                        disabled={isViewTour}
                    />
                </div>
                <div data-name={navbarTourNames.tariffs}>
                    <Tariffs
                        onSubmit={() => {
                            changeTab(navbarTourNames.program);
                        }}
                        isExistingService={isViewTour}
                        addTariffForExistingService={addTariffForExistingTour}
                        deleteTariffForExistingService={deleteTariffForExistingTour}
                        updateTariffForExistingService={updateTariffForExistingTour}
                        keysVisibleTabs={[PlaceTimetableButtonKeys.WeekDays, PlaceTimetableButtonKeys.Days]}
                        isRenderValueWithPronoun
                        isVisiblePlaceTimetable={false}
                        label="Начало тура"
                        btnAddItemTitle="Добавить дни"
                        validateWithoutTime
                    />
                </div>
                <div data-name={navbarTourNames.photos}>
                    <Photos
                        items={images}
                        disabled={isViewTour}
                        onChange={onChangeImage}
                        onSubmit={isConcluded && !!images.length && !isViewTour ? goToModerate : undefined}
                    />
                </div>
                <div data-name={navbarTourNames.program}>
                    {tourData.nights ? (
                        <TourProgram
                            data={tourData}
                            onChange={onChangeData}
                            onSubmit={changeTab}
                            isShowErrors={isSubmittingForm}
                            disabled={isViewTour}
                        />
                    ) : (
                        <EmptyList
                            buttonTitle="Перейти на вкладку Тур"
                            description="Пожалуйста, укажите количество ночей"
                            onClick={goToTabTour}
                        />
                    )}
                </div>
                <div data-name={navbarTourNames.ratings}>
                    <Ratings id={tourData.cmsTourId} />
                </div>
                <>
                    {isArchive && (
                        <ActionsContainer
                            onClickNextButton={isConcluded ? actionsObj.sendPublish.action : undefined}
                            nextButtonText={actionsObj.sendPublish.text}
                            // TODO сделать превью необязательным параметром
                            onClickPreviewButton={() => {}}
                            showPreviwButton={false}
                        />
                    )}
                </>
            </CommonLayout>
        </ServiceTariffsContext.Provider>
    );
};

export default Tour;
