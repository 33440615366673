import { useCallback, useEffect, useState } from "react";
import { OfferForm } from "../types";
import { signOffer, validateBankAccount } from "../utils";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import history from "../../../../../history";
import routes from "../../../../../constants/routes";
import { sendEvent } from "../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../product_analytics/constants";
import { IBank, IBankField } from "../../../../../types/profile";
import { debounce } from "lodash";
import { useOfferTypeStatus } from "./useOfferTypeStatus";
import { getUserContactInfo } from "../../../../../api/profile";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../../constants/errors";
import { useLegalStateStatus } from "./useLegalStateStatus";
import useUserInfo from "../../../../../hooks/useUserInfo";

const useCompanyInfoForm = () => {
    const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);

    const { userFullInfo } = useUserInfo();

    const legalStatus = useLegalStateStatus(userFullInfo?.legalState);
    const { isHotel } = useOfferTypeStatus();

    const [objectData, setObjectData] = useState<OfferForm | null>(null);
    const [statusSigningOffer, setStatusSigningOffer] = useState<StatusRequestEnum | null>(null);
    const [bankErrors, setBankErrors] = useState<{
        corrAccount?: string;
        checkingAccount?: string;
    }>({});

    const handleSignOffer = useCallback(
        async (values: OfferForm) => {
            if (!userFullInfo) return;
            setIsLoadingForm(true);

            try {
                await signOffer(userFullInfo.applicationId, values, isHotel, legalStatus);
                setStatusSigningOffer(StatusRequestEnum.Success);
                sendEvent(AnalyticEvent.sign_success);
            } catch (err) {
                setStatusSigningOffer(StatusRequestEnum.Error);
                sendEvent(AnalyticEvent.sign_fail);
            } finally {
                setIsLoadingForm(false);
            }
        },
        [isHotel, legalStatus, userFullInfo]
    );

    const handleValidateBankAccount = useCallback(
        debounce(async (bank: IBank, fieldType: IBankField) => {
            if (userFullInfo && bank) {
                setBankErrors({ ...bankErrors, [fieldType]: undefined });
                const errorData = await validateBankAccount({ partnerId: userFullInfo.applicationId, bank, fieldType });
                if (errorData) {
                    setBankErrors(errorData);
                }
            }
        }, 300),
        [userFullInfo]
    );

    const onCloseOffer = useCallback(async () => {
        if (!statusSigningOffer) {
            history.push({
                pathname: routes.offer,
                state: {
                    background: {
                        pathname: routes.documents,
                    },
                },
            });
        } else {
            window.location.replace(routes.documents);
        }
    }, [statusSigningOffer]);

    const loadData = useCallback(async () => {
        if (userFullInfo?.status?.id !== 11) return;

        setIsLoadingForm(true);
        try {
            const userContractInfo = await getUserContactInfo(userFullInfo.applicationId);
            if (userContractInfo.identityNumber?.length === 10) {
                const series = userContractInfo.identityNumber.slice(0, 4);
                const number = userContractInfo.identityNumber.slice(4, 10);
                userContractInfo.identityNumber = `${series} ${number}`;
            }
            setObjectData({ applicationAdditionData: userContractInfo, filesData: {} } as OfferForm);
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: UNEXPECTED_ERROR,
            });
        } finally {
            setIsLoadingForm(false);
        }
    }, [userFullInfo]);

    useEffect(() => {
        loadData().then(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        statusSigningOffer,
        isLoadingForm,
        handleSignOffer,
        onCloseOffer,
        objectData,
        handleValidateBankAccount,
        bankErrors,
    };
};

export default useCompanyInfoForm;
