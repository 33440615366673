import { RatingsData } from "../types";
import classNames from "classnames";

export const RatingsInfo = ({ rating }: { rating: RatingsData }) => {
    const getRatingItemClassName = (value: number) => {
        if (value < 5) {
            return "ratings-info__mark__colored--low";
        } else if (value >= 5 && value < 7) {
            return "ratings-info__mark__colored--medium";
        }
    };

    const formatRating = (value: number) => {
        return value % 1 === 0 ? value : value.toFixed(1);
    };

    return (
        <div className="ratings-info">
            <div className="ratings-info__badges">
                <div className={classNames("ratings-info__mark", getRatingItemClassName(rating.mark.generalRating))}>
                    {formatRating(rating.mark.generalRating)}
                </div>
                {rating.mark.badges.map((badge) => (
                    <div className="ratings-info__badge" key={badge.code}>
                        {badge.description.replace(/[n\\\r]/g, "").trim()}
                        {badge.count > 1 && <span> • {badge.count}</span>}
                    </div>
                ))}
            </div>
        </div>
    );
};
