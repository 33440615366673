import "./styles.sass";
import moment from "moment/moment";
import { DOTTED_MID_DATE_FORMAT } from "../../../../constants/dates-format";

type OfferCardProps = {
    name: string;
    date?: string;
    onClick: () => void;
};

const OfferCard = ({ name, date, onClick }: OfferCardProps) => {
    return (
        <div className="offer-card" onClick={onClick}>
            <div className="offer-card__title">Оферта</div>
            <div className="offer-card__section">
                <div className="offer-card__text">ФИО подписанта</div>
                <div className="offer-card__content">{name}</div>
            </div>
            {date && (
                <div className="offer-card__section">
                    <div className="offer-card__text">Дата подписания</div>
                    <div className="offer-card__content">{moment(date).format(DOTTED_MID_DATE_FORMAT) + " г."}</div>
                </div>
            )}
        </div>
    );
};

export default OfferCard;
