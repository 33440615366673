import React, { useCallback, useState } from "react";
import "./styles.sass";
import { newTariffData } from "./mock";
import { Tariff, TariffDates } from "./types";
import TariffModal from "./tariff-modal";
import { cloneDeep } from "lodash";
import TariffsItem from "./tariffs-item";
import uuid from "react-native-uuid";
import { PlaceTimetableItemType, PlaceTimetableOptions } from "../../../../components/timetable/place-timetable/types";
import { Paginate, Card, CardsGrid, useModalAlertContext, Input } from "@russpass/partner-front-ui";
import { useServiceTariffsContext } from "./tariffs-context";
import { maskedInputTimeInHoursAndMinutesTariff } from "../../../../utils/validation";

type Props = {
    isExistingService: boolean;
    facilityWorkingTime?: PlaceTimetableItemType[];
    addTariffForExistingService: (tariffs: Tariff[], tariffDates?: TariffDates) => void;
    deleteTariffForExistingService: (tariff: Tariff) => void;
    updateTariffForExistingService: (
        tariffs: Tariff,
        showNotification?: boolean,
        isBasicUpdated?: boolean
    ) => Promise<void>;
    updateDataField?: (val: string) => Promise<void>;
    isDisplayRegistration?: boolean;
    currentBasicTariff?: Tariff | null;
    tariffDates?: TariffDates;
    setTariffDates?: React.Dispatch<React.SetStateAction<TariffDates>>;
    disabled?: boolean;
} & PlaceTimetableOptions;

const Tariffs = ({
    isExistingService,
    facilityWorkingTime,
    addTariffForExistingService,
    deleteTariffForExistingService,
    updateTariffForExistingService,
    updateDataField,
    isRenderValueWithPronoun = false,
    isVisiblePlaceTimetable = true,
    isDisplayRegistration,
    currentBasicTariff,
    tariffDates,
    setTariffDates,
    disabled,
    ...restOptions
}: Props) => {
    const { tariffs, loadTariffList, billingProductId, onChangeTariffList } = useServiceTariffsContext();
    const { openModalAlert } = useModalAlertContext();

    const [isOpenWindow, setIsOpenWindow] = useState(false);

    const [tariffData, setTariffData] = useState<Tariff>(newTariffData);

    const onDeleteTariff = useCallback(
        async (tariff: Tariff) => {
            if (tariff.isBasic) {
                return openModalAlert(
                    {
                        title: "Базовый тариф нельзя удалить",
                        description: `Вы можете выбрать другой тариф базовым и затем вернуться к удалению тарифа «${tariff.tariffCaption[0].content}» `,
                        OKButton: " Понятно",
                    },
                    () => {}
                );
            }

            const details = {
                title: "Вы уверены, что хотите удалить тариф?",
                OKButton: "Удалить",
                cancelButton: "Отменить",
            };

            const onOKButtonClickAction = isExistingService
                ? async () => {
                      await deleteTariffForExistingService(tariff);
                      loadTariffList(billingProductId, tariffs.number);
                  }
                : () => onChangeTariffList(tariffs.content.filter((prevTariff) => prevTariff.id !== tariff.id));

            openModalAlert(details, onOKButtonClickAction);
        },
        [
            openModalAlert,
            isExistingService,
            deleteTariffForExistingService,
            loadTariffList,
            billingProductId,
            tariffs.number,
            tariffs.content,
            onChangeTariffList,
        ]
    );

    const updatePrevBasicTariff = useCallback(async () => {
        //если новый базовый тариф, ставим прошлому isBasic = false
        if (currentBasicTariff) {
            const activeSinceTime = new Date(currentBasicTariff.activeSince ?? 0);
            if (currentBasicTariff.openedSince) {
                activeSinceTime.setHours(Number(currentBasicTariff.openedSince.split(":")[0]));
                activeSinceTime.setMinutes(Number(currentBasicTariff.openedSince.split(":")[1]));
                activeSinceTime.setSeconds(Number(currentBasicTariff.openedSince.split(":")[2]));
            }
            const currentTime = new Date().getTime();

            //если это делается у существующего сервиса - запрос
            await updateTariffForExistingService(
                {
                    ...currentBasicTariff,
                    isBasic: false,
                    ...tariffDates,
                },
                false, //не показываем уведомление об обновлении прошлого базового тарифа
                activeSinceTime.getTime() > currentTime //нужно ли отправлять поля регистрации
            );
        }
    }, [currentBasicTariff, updateTariffForExistingService, tariffDates]);

    const onSaveTariff = useCallback(
        async (tariff: Tariff[]) => {
            if (isExistingService) {
                if (tariff[0].isBasic && tariff[0].id !== currentBasicTariff?.id) {
                    await updatePrevBasicTariff();
                }

                await addTariffForExistingService(tariff, tariffDates);

                if (tariff[0].isBasic && tariff[0].itemPrices[0].price !== currentBasicTariff?.itemPrices[0].price) {
                    await updateDataField?.(tariff[0].itemPrices[0].price);
                }
                loadTariffList(billingProductId, tariffs.number);
            } else {
                let updatedTariffs = [...tariffs.content];
                const tariffIdx = updatedTariffs.findIndex((i) => i.id === tariff[0].id);
                const isNewBasic = tariff[0].isBasic && currentBasicTariff && tariff[0].id !== currentBasicTariff?.id;

                if (isNewBasic) {
                    const prevBasicTarifIdx = updatedTariffs.findIndex((i) => i.id === currentBasicTariff?.id);
                    updatedTariffs[prevBasicTarifIdx].isBasic = false;
                }

                if (tariffIdx !== -1) {
                    updatedTariffs[tariffIdx] = tariff[0];
                } else {
                    //если индекс не нашел значит это новый тариф
                    updatedTariffs = updatedTariffs.concat(tariff);
                }

                onChangeTariffList(updatedTariffs);
            }
            changeIsOpen(false);
        },
        [
            isExistingService,
            currentBasicTariff,
            addTariffForExistingService,
            loadTariffList,
            billingProductId,
            tariffs.number,
            tariffs.content,
            updatePrevBasicTariff,
            updateDataField,
            onChangeTariffList,
        ]
    );

    const onUpdateTariff = useCallback(
        async (tariff: Tariff) => {
            if (isExistingService) {
                if (tariff.isBasic && tariff.id !== currentBasicTariff?.id) {
                    await updatePrevBasicTariff();
                }
                await updateTariffForExistingService({ ...tariff, ...tariffDates });

                if (tariff.isBasic && tariff.itemPrices[0].price !== currentBasicTariff?.itemPrices[0].price) {
                    await updateDataField?.(tariff.itemPrices[0].price);
                }

                loadTariffList(billingProductId, tariffs.number);
                changeIsOpen(false);
            }
        },
        [tariffs, isExistingService, updateTariffForExistingService, loadTariffList, billingProductId, tariffDates]
    );

    const onEditTariff = useCallback((tariff: Tariff) => {
        setTariffData(tariff);
        changeIsOpen(true);
    }, []);

    const onCopyTariff = useCallback(
        (tariff: Tariff) => {
            let newTariff = cloneDeep(tariff);
            newTariff.id = uuid.v4() as string;
            newTariff.tariffCaption[0].content = "Копия " + newTariff.tariffCaption[0].content;
            newTariff.isBasic = false;
            onChangeTariffList([...tariffs.content, newTariff]);
        },
        [tariffs, onChangeTariffList]
    );

    const changeIsOpen = (status: boolean) => {
        setIsOpenWindow(status);
    };

    const handleCardClick = useCallback(() => {
        onEditTariff(cloneDeep(newTariffData));
    }, [onEditTariff]);

    return (
        <>
            {isDisplayRegistration && (
                <div className="tariff_openclose__container">
                    <div className="tariff_openclose__inner">
                        <div className="tariff_openclose__text">
                            По умолчанию продажа открывается сразу после публикации услуги и доступна до конца сеанса,
                            на который пользователь покупает билет. Вы можете изменить эти настройки
                        </div>
                        <div className="tariff_openclose__fields">
                            <Input
                                label="Открыть продажи"
                                noHover
                                inputProps={{
                                    placeholder: "за 48 ч 00 мин до сеанса",
                                    value: tariffDates?.registrationOnDateByTime ?? "",
                                    onChange: (e) =>
                                        setTariffDates?.((tariffDates) => ({
                                            ...tariffDates,
                                            registrationOnDateByTime: e.target.value,
                                        })),
                                    disabled: disabled,
                                }}
                                renderComponent={maskedInputTimeInHoursAndMinutesTariff}
                            />
                            <Input
                                label="Закрыть продажи"
                                noHover
                                inputProps={{
                                    placeholder: "за 00 ч 45 мин до сеанса",
                                    value: tariffDates?.registrationOffDateByTime ?? "",
                                    onChange: (e) =>
                                        setTariffDates?.((tariffDates) => ({
                                            ...tariffDates,
                                            registrationOffDateByTime: e.target.value,
                                        })),
                                    disabled: disabled,
                                }}
                                renderComponent={maskedInputTimeInHoursAndMinutesTariff}
                            />
                        </div>
                    </div>
                </div>
            )}
            <CardsGrid>
                {!disabled && (
                    <Card
                        title="Добавить тариф"
                        desc="Укажите название и стоимость услуги"
                        onClick={handleCardClick}
                        styles={{
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
                            border: "none",
                            padding: "24px 0",
                        }}
                    />
                )}
                {tariffs.content.map((tariffItem) => {
                    return (
                        <TariffsItem
                            key={tariffItem.id}
                            data={tariffItem}
                            isDeleting={
                                isExistingService ? !(tariffs.number === 0 && tariffs.content.length === 1) : true
                            }
                            isBasic={tariffItem.isBasic}
                            isCopy={!isExistingService}
                            onDelete={onDeleteTariff}
                            onClick={onEditTariff}
                            onCopy={onCopyTariff}
                            isRenderValueWithPronoun={isRenderValueWithPronoun}
                            isVisiblePlaceTimetable={isVisiblePlaceTimetable}
                            disabled={disabled}
                        />
                    );
                })}
            </CardsGrid>
            <div className="content-wrapper mb-0">
                <div className="mt-32 mb-40">
                    <Paginate
                        page={tariffs.number}
                        totalPages={tariffs.totalPages}
                        onPageChange={(page) => {
                            loadTariffList(billingProductId, page);
                        }}
                    />
                </div>
            </div>
            <TariffModal
                isOpen={isOpenWindow}
                onClose={changeIsOpen}
                tariffData={tariffData}
                currentBasicTariffName={currentBasicTariff?.tariffCaption[0].content || null}
                onSave={onSaveTariff}
                onUpdateTariff={onUpdateTariff}
                isExistingService={isExistingService}
                isRenderValueWithPronoun={isRenderValueWithPronoun}
                isVisiblePlaceTimetable={isVisiblePlaceTimetable}
                facilityWorkingTime={facilityWorkingTime || []}
                disabled={disabled}
                {...restOptions}
            />
        </>
    );
};

export default Tariffs;
