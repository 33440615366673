import moment from "moment";
import { getHoldingSpace } from "../../../../api/dictionaries";
import { getDraftImageByURL, getImageById } from "../../../../api/files";
import {
    DATE_2061,
    DAY_DATE_FORMAT,
    MID_DAY_MONTH_DATE_FORMAT,
    MIN_DAY_LONG_MONTH_DATE_FORMAT,
    MIN_DAY_MONTH_DATE_FORMAT,
} from "../../../../constants/dates-format";
import { TSelectValue } from "../../../../types/dictionaries";
import { Tariff, TariffDates } from "../../components/tariffs/types";
import {
    DraftExcursionDataPost,
    ExcursionDataClient,
    ExcursionDataGet,
    ExcursionDataPost,
    ExcursionEventItem,
    ExсursionDataPatch,
} from "./types";
import { getHoursAndMinutes, getStringFromHoursMinutes, getTariffFormatString } from "../../utils";
import {
    getIsDaysExisting,
    getIsWeekDaysExisting,
    getRangeArray,
    getStringArray,
} from "../../../../components/timetable/place-timetable/utils";
import { weekDaysNames } from "../../../../components/timetable/place-timetable/constants";
import { DateControlValue, extendedDateFormat, isEqualObjects, UploadPhotosItemNew } from "@russpass/partner-front-ui";
import { PlaceTimetableItemType } from "../../../../components/timetable/place-timetable/types";
import { EventPreviewDataType } from "../../components/EventPreviewModal";
import { FacilityServer } from "../../types";
import { saveImagesForDraft, saveImagesToCMS } from "../../components/photosNew/utils";

export const formatDraftExcursionToPost = async (
    data: ExcursionDataClient,
    tariffs: Tariff[],
    facility: FacilityServer | null,
    partnerId: string,
    tariffDates: TariffDates
): Promise<DraftExcursionDataPost> => {
    const excursionImages = await saveImagesForDraft(data.imagesFiles, partnerId);
    const { formatRegistrationOnDate, formatRegistrationOffDate } = getTariffFormatString({
        registrationOnDateByTime: tariffDates?.registrationOnDateByTime,
        registrationOffDateByTime: tariffDates?.registrationOffDateByTime,
    });
    const tariffData = tariffs.map((tariff) => {
        const newTariff = {
            ...tariff,
            registrationOnDateByTime: formatRegistrationOnDate || null,
            registrationOffDateByTime: formatRegistrationOffDate || null,
        };
        delete newTariff.registrationOnDate;
        delete newTariff.registrationOffDate;

        return newTariff;
    });
    const formattedData: DraftExcursionDataPost = {
        ...data,
        isCanBuy: tariffs.length > 0,
        images: excursionImages.length ? excursionImages.slice(1) : [],
        imageExplorePreview: excursionImages.length ? [excursionImages[0]] : [],
        imageDetailedPageMain: excursionImages.length ? [excursionImages[0]] : [],
        route: await Promise.all(
            data.route.map(async (routeItem) => ({
                title: routeItem.title,
                events: await Promise.all(
                    routeItem.events
                        .filter((event) => event.imageFiles)
                        .map(async (event) => {
                            const eventImages = await saveImagesForDraft(event.imageFiles || [], partnerId);
                            return {
                                title: event.title,
                                images: eventImages,
                            };
                        })
                ),
            }))
        ),
        seasonStart: data.seasonUnlimited
            ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonStart,
        seasonEnd: data.seasonUnlimited
            ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonEnd,
        price: tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "",
        durationHours: getHoursAndMinutes(data.duration)[0],
        durationMinutes: getHoursAndMinutes(data.duration)[1],
        facility: facility?.cmsFacilityId,
        facilityObject: facility,
        rateObject: tariffData,
    };
    delete formattedData["id"];
    delete formattedData["imagesFiles"];
    delete formattedData["duration"];

    return formattedData;
};

export const formatExcursionToPost = async (
    data: ExcursionDataClient,
    tariffs: Tariff[],
    facility: string | null,
    draftId?: string
): Promise<ExcursionDataPost> => {
    const excursionImages = await saveImagesToCMS(data.imagesFiles, !!draftId);
    const formattedData: any = {
        ...data,
        isCanBuy: tariffs.length > 0,
        images: excursionImages.length ? excursionImages.slice(1) : [],
        imageExplorePreview: excursionImages.length ? [excursionImages[0]] : [],
        imageDetailedPageMain: excursionImages.length ? [excursionImages[0]] : [],
        route: await Promise.all(
            data.route.map(async (routeItem) => ({
                title: routeItem.title,
                events: await Promise.all(
                    routeItem.events
                        .filter((event) => event.imageFiles)
                        .map(async (event) => {
                            const eventImages = await saveImagesToCMS(event.imageFiles || [], !!draftId);
                            return {
                                title: event.title,
                                images: eventImages,
                            };
                        })
                ),
            }))
        ),
        seasonStart: data.seasonUnlimited
            ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonStart,
        seasonEnd: data.seasonUnlimited
            ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonEnd,
        facility,
        price: tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "",
        durationHours: getHoursAndMinutes(data.duration)[0],
        durationMinutes: getHoursAndMinutes(data.duration)[1],
        patchMode: false,
    };
    facility === null && delete formattedData["facility"];
    delete formattedData["id"];
    delete formattedData["imagesFiles"];
    delete formattedData["duration"];

    return formattedData;
};

// TODO Посмотреть отличается ли патч от поста (схлопнуть в одну функцию)
export const formatExcursionToPatch = async (
    data: ExcursionDataClient,
    tariffs: Tariff[],
    excursionViewDefaultId: string
): Promise<Omit<ExсursionDataPatch, "facility" | "sendToBilling" | "sendToCms" | "patchMode">> => {
    const duration = data.duration.split(" ");
    const hours = duration[0];
    const minutes = duration[2];
    const ticketPrice = tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "";

    const excursionImages = await saveImagesToCMS(data.imagesFiles);

    return {
        ...data,
        id: data.id,
        durationHours: +hours,
        durationMinutes: +minutes || 0,
        city: data.city || "",
        price: +ticketPrice,
        images: excursionImages?.length ? excursionImages.slice(1) : [],
        imageDetailedPageMain: excursionImages?.length ? [excursionImages[0]] : [],
        imageExplorePreview: excursionImages?.length ? [excursionImages[0]] : [],
        maxGroupCount: data.maxGroupCount || data.minGroupCount || "",
        personalizedTickets: data.personalizedTickets ?? false,
        excursionView: data.excursionView || excursionViewDefaultId,
        seasonStart: data.seasonUnlimited
            ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonStart,
        seasonEnd: data.seasonUnlimited
            ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonEnd,
        route: await Promise.all(
            data.route.map(async (routeItem) => ({
                title: routeItem.title,
                events: await Promise.all(
                    routeItem.events
                        .filter((event) => event.imageFiles)
                        .map(async (event) => {
                            const eventImages = await saveImagesToCMS(event.imageFiles || []);
                            return {
                                title: event.title,
                                images: eventImages,
                            };
                        })
                ),
            }))
        ),
    };
};

export const formatDraftExcursionFromGet = async (
    initialExcursion: ExcursionDataClient,
    data: DraftExcursionDataPost
): Promise<ExcursionDataClient> => {
    const events = await Promise.all(
        data.route?.[0]?.events.map(async (event: ExcursionEventItem) => {
            event.imageFiles = [];
            if (!event.images) {
                return event;
            }
            event.imageFiles = await Promise.all(
                event.images.map(async (image) => {
                    const imageFile = await getDraftImageByURL(image);
                    return {
                        preview: URL.createObjectURL(imageFile),
                        file: imageFile,
                        url: image,
                        id: image,
                    };
                })
            );
            return event;
        })
    );
    const imagesFiles = await Promise.all(
        [...data.imageExplorePreview, ...data.images].map(async (img) => {
            const imageFile = await getDraftImageByURL(img);
            return {
                url: img,
                preview: URL.createObjectURL(imageFile),
                file: imageFile,
                id: img,
            };
        })
    );

    const excursion: ExcursionDataClient = {
        id: data.id || initialExcursion.id,
        cmsExcursionId: initialExcursion.cmsExcursionId,
        billingProductId: initialExcursion.billingProductId,
        title: data.title || initialExcursion.title,
        status: data.status || initialExcursion.status,
        isRejected: initialExcursion.isRejected,
        rejectionReason: initialExcursion.rejectionReason,
        description: data.description || initialExcursion.description,
        program: data.program || initialExcursion.program,
        region: data.region || initialExcursion.region,
        city: data.city || initialExcursion.city,
        excursionForm: data.excursionForm || initialExcursion.excursionForm,
        price: data.price || initialExcursion.price,
        duration: getStringFromHoursMinutes(data.durationHours, data.durationMinutes),
        durationHours: data.durationHours || initialExcursion.durationHours,
        durationMinutes: data.durationMinutes || initialExcursion.durationMinutes,
        seasonUnlimited: data.seasonUnlimited ?? false,
        minGroupCount: data.minGroupCount || initialExcursion.minGroupCount,
        maxGroupCount: data.maxGroupCount || initialExcursion.maxGroupCount,
        seasonStart: data.seasonStart || initialExcursion.seasonStart,
        seasonEnd: data.seasonEnd || initialExcursion.seasonEnd,
        language: data.language || initialExcursion.language,
        route: [
            {
                ...data.route[0],
                events,
            },
        ],
        images: data.images || initialExcursion.images,
        imageDetailedPageMain: data.imageDetailedPageMain || initialExcursion.imageDetailedPageMain,
        imageExplorePreview: data.imageExplorePreview || initialExcursion.imageExplorePreview,
        isCanBuy: data.isCanBuy || initialExcursion.isCanBuy,
        excursionView: data.excursionView || initialExcursion.excursionView,
        holdingSpace: data.holdingSpace || initialExcursion.holdingSpace,
        facility: data.facility || initialExcursion.facility || null,
        ndsPercent: data.ndsPercent || initialExcursion.ndsPercent,
        paymentType: data.paymentType || initialExcursion.paymentType,
        personalizedTickets: data.personalizedTickets || initialExcursion.personalizedTickets,
        included: data.included || initialExcursion.included,
        paidSeparately: data.paidSeparately || initialExcursion.paidSeparately,
        startPointAddress: data.startPointAddress || initialExcursion.startPointAddress,
        startPointAdditInfo: data.startPointAdditInfo || initialExcursion.startPointAdditInfo,
        creationDt: initialExcursion.creationDt,
        archivedDt: initialExcursion.archivedDt,
        url: initialExcursion.url,
        imagesFiles,
    };

    return excursion;
};

export const formatExcursionFromGet = (
    initialExcursion: ExcursionDataClient,
    data: ExcursionDataGet
): ExcursionDataClient => {
    const excursion = { ...initialExcursion, ...data };
    const events = excursion.route[0]?.events.map((event: ExcursionEventItem) => {
        if (!event.images) {
            return event;
        }
        event.imageFiles = event.images.map((image) => ({
            url: image,
            preview: getImageById(image),
            id: image,
        }));
        return event;
    });
    const imagesFiles: UploadPhotosItemNew[] = [...excursion.imageExplorePreview, ...excursion.images].map((img) => {
        return {
            preview: getImageById(img),
            url: img,
            id: img,
        };
    });
    return {
        ...excursion,
        imagesFiles,
        route: [
            {
                ...excursion.route[0],
                events,
            },
        ],
        duration: getStringFromHoursMinutes(excursion.durationHours, excursion.durationMinutes),
        seasonUnlimited: excursion.seasonUnlimited ?? false,
    };
};

export const getHoldingSpaceOptions = async (): Promise<TSelectValue[]> => {
    let data: TSelectValue[] = [];
    try {
        const { rows } = await getHoldingSpace();
        data = rows;
    } catch (err) {
        console.error(err);
    }
    return data;
};

const getArrayValue = (values: string): string[] => {
    if (values === "") {
        return [""];
    }

    const arrValue = values.split("\n");

    const arrValueWithoutEmptyString: string[] = [];
    const lengthArrValues = arrValue.length - 1;

    for (let i = 0; i <= lengthArrValues; i++) {
        if (i === lengthArrValues && arrValue[i] === "") {
            break;
        }

        if (arrValue[i][0] === "-") {
            arrValueWithoutEmptyString.push(arrValue[i].substring(1).trim());
        } else {
            arrValueWithoutEmptyString.push(arrValue[i].trim());
        }
    }

    return arrValueWithoutEmptyString;
};

const getTariffRowsPreviewDatesAndTimes = (workingTime: PlaceTimetableItemType[]) => {
    const weekDays: string[] = [];
    const separateDays: string[] = [];
    const rangeDays: string[] = [];

    workingTime?.forEach((item) => {
        const workingDays = item.workingDays as DateControlValue;

        const isWeekDays = getIsWeekDaysExisting(workingDays);
        const isSeparateDays = getIsDaysExisting(workingDays);

        if (isWeekDays || isSeparateDays) {
            const days = getStringArray(workingDays);

            days.forEach((day) => {
                if (weekDaysNames.hasOwnProperty(day)) {
                    weekDays.push(weekDaysNames[day as keyof typeof weekDaysNames]);
                } else {
                    separateDays.push(moment(day).format(MIN_DAY_MONTH_DATE_FORMAT));
                }
            });
        } else {
            //range
            const days = getRangeArray(workingDays);

            days.forEach((range) => {
                const rangeFrom = range.from;
                const rangeTo = range.to;

                const isSameMonth = moment(rangeFrom).isSame(rangeTo, "month");

                const from = moment(rangeFrom).format(isSameMonth ? DAY_DATE_FORMAT : MID_DAY_MONTH_DATE_FORMAT);
                const to = moment(rangeTo).format(MID_DAY_MONTH_DATE_FORMAT);

                rangeDays.push(from + " - " + to);
            });
        }
    });

    return {
        weekDays,
        separateDays,
        rangeDays,
    };
};

const getTariffRowsPreview = (tariffs: Tariff[]) => {
    let weekDaysRow = "";
    let separateDaysRow = "";
    let rangeDaysRow = "";

    const weekDays: {
        dates: string[];
        times: string[];
    } = {
        dates: [],
        times: [],
    };

    const separateDays: {
        dates: string[];
        times: string[];
    } = {
        dates: [],
        times: [],
    };

    const rangeDays: {
        dates: string[];
        times: string[];
    } = {
        dates: [],
        times: [],
    };

    const filteredTariffs = tariffs.filter(
        (tariff, indx, self) =>
            indx ===
            self.findIndex(
                (t) => isEqualObjects(tariff.workingTime, t.workingTime) && tariff.openedSince === t.openedSince
            )
    );

    filteredTariffs.forEach((tariff) => {
        const rawDatesAndTimes = getTariffRowsPreviewDatesAndTimes(tariff.workingTime);
        const time = tariff.openedSince.slice(0, 5);

        if (rawDatesAndTimes.weekDays.length) {
            weekDays.dates = weekDays.dates.concat(rawDatesAndTimes.weekDays);
            weekDays.times = weekDays.times.concat(time);
        }

        if (rawDatesAndTimes.separateDays.length) {
            separateDays.dates = separateDays.dates.concat(rawDatesAndTimes.separateDays);
            separateDays.times = separateDays.times.concat(time);
        }
        if (rawDatesAndTimes.rangeDays.length) {
            rangeDays.dates = rangeDays.dates.concat(rawDatesAndTimes.rangeDays);
            rangeDays.times = rangeDays.times.concat(time);
        }
    });

    const getRowItem = (items: string[]) =>
        items
            .filter((item, indx, self) => self.indexOf(item) === indx)
            .reduce((prev, next, indx) => prev + (indx !== items.length && indx !== 0 ? ", " : "") + next, "");

    if (weekDays.dates.length) {
        weekDaysRow += getRowItem(weekDays.dates) + " - " + getRowItem(weekDays.times);
    }

    if (separateDays.dates.length) {
        separateDaysRow += getRowItem(separateDays.dates) + " - " + getRowItem(separateDays.times);
    }

    if (rangeDays.dates.length) {
        rangeDaysRow += getRowItem(rangeDays.dates) + " - " + getRowItem(rangeDays.times);
    }

    return [weekDaysRow, separateDaysRow, rangeDaysRow];
};

export const getExcursionEventPreviewData = (data: ExcursionDataClient, tariffs: Tariff[]): EventPreviewDataType => ({
    title: data.title || "Название экскурсии",
    eventType: "Экскурсия",
    workingDate: data.seasonUnlimited
        ? "Круглогодично"
        : data.seasonStart && data.seasonEnd
        ? `${moment(data.seasonStart).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)} 
            - ${moment(data.seasonEnd).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)}`
        : "",
    images: data.imagesFiles || [],
    description: data.description,
    workingTime: {
        emptyValue: "ч",
        value: data.duration || "",
    },
    checkList: {
        program: getArrayValue(data.program),
        schedule: getTariffRowsPreview(tariffs),
        startPointAddress: data?.startPointAddress,
        startPointAdditInfo: data?.startPointAdditInfo,
        included: getArrayValue(data.included || ""),
        paidSeparately: getArrayValue(data.paidSeparately || ""),
    },
});
