import { array as arrayYup, object as objectYup, string as stringYup, boolean as booleanYup } from "yup";
import {
    FORM_FIELD_REQUIRED_ERROR,
    FORM_MIN_ERROR,
    FORMAT_ERROR,
    LENGTH_EXCEEDS_MAXIMUM_ERROR,
} from "../../../../constants/errors";
import { SERVICE_MAX_TITLE_LENGTH } from "../../constants";
import { checkIsStrIsTime } from "../../utils";
import { DESC_FIELD_MIN_LENGTH } from "../../../../constants/inputs";

const MIN_GROUP_COUNT_ERROR = "Минимальная численность группы должна быть меньше максимальной";

export const draftExcursionInfoValidation = objectYup().shape({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR).max(SERVICE_MAX_TITLE_LENGTH, LENGTH_EXCEEDS_MAXIMUM_ERROR),
});

export const excursionInfoValidation = objectYup().shape({
    title: stringYup().required(FORM_FIELD_REQUIRED_ERROR).max(SERVICE_MAX_TITLE_LENGTH, LENGTH_EXCEEDS_MAXIMUM_ERROR),
    description: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR).min(DESC_FIELD_MIN_LENGTH, FORM_MIN_ERROR),
    holdingSpace: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    excursionView: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    region: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    city: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    excursionForm: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    minGroupCount: stringYup()
        .nullable()
        .required(FORM_FIELD_REQUIRED_ERROR)
        .test("compare", MIN_GROUP_COUNT_ERROR, function (value) {
            const { maxGroupCount } = this.parent;
            return Number(maxGroupCount) >= Number(value);
        }),
    maxGroupCount: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    paymentType: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    personalizedTickets: booleanYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    ndsPercent: stringYup().nullable().required(FORM_FIELD_REQUIRED_ERROR),
    duration: stringYup().test("checkIsTime", FORMAT_ERROR, checkIsStrIsTime).required(FORM_FIELD_REQUIRED_ERROR),

    seasonStart: stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
        const { seasonUnlimited } = this.parent;
        if (seasonUnlimited) return true;

        return !!value;
    }),
    seasonEnd: stringYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
        const { seasonUnlimited, seasonStart } = this.parent;
        if (seasonUnlimited || seasonStart) return true;

        return !!value;
    }),
    seasonUnlimited: booleanYup().required(),
});

export const excursionProgramValidation = objectYup().shape({
    program: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    route: arrayYup()
        .of(
            objectYup()
                .shape({
                    events: arrayYup()
                        .of(
                            objectYup().shape({
                                title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
                            })
                        )
                        .min(1, FORM_FIELD_REQUIRED_ERROR),
                })
                .required()
        )
        .required(FORM_FIELD_REQUIRED_ERROR)
        .min(1, FORM_FIELD_REQUIRED_ERROR),
});
