import { NewDraftServiceDataGet, NewDraftServiceDataPost, NewServiceData, NewServiceDataPost } from "./types";
import { Facility, FacilityServer } from "../../types";
import { Tariff, TariffDates } from "../../components/tariffs/types";
import moment from "moment/moment";
import { DATE_2061, extendedDateFormat } from "@russpass/partner-front-ui";
import { getTimetableData } from "../utils";
import { getDraftImageByURL } from "../../../../api/files";
import { getStringFromHoursMinutes, getTariffFormatString } from "../../utils";

export const formatFacilityToPost = (facility: Facility) => {
    const facilityData: FacilityServer = {
        ...facility,
        phones: facility.phone ? [facility.phone] : [],
        address: facility.addressDetail?.unrestricted_value || "",
        geoData: facility.coordinates ? facility.coordinates.split(",").map((v) => +v) : [],
        workingTime: getTimetableData(facility.workingTime),
        isAvailableForGuide: false,
    };
    delete facilityData.phone;
    delete facilityData.addressDetail;
    delete facilityData.coordinates;

    return facilityData;
};

export const formatDuration = (duration: string) => {
    const durationArray = duration.split(" ");
    const hours = durationArray[0] ? durationArray[0].replace(/_/g, "0") : "";
    const minutes = durationArray[2] ? durationArray[2].replace(/_/g, "0") : "";
    return {
        hours,
        minutes,
    };
};

export const formatServiceToPost = (
    data: NewServiceData,
    facility: Facility,
    facilityId: string,
    tariffs: Tariff[],
    serviceImages?: string[]
) => {
    const duration = formatDuration(data.duration);

    const ticketPrice = tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "";
    const seasonStart = data.seasonUnlimited
        ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
        : data.seasonStart;
    const seasonEnd = data.seasonUnlimited
        ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
        : data.seasonEnd;

    const serviceData: NewServiceDataPost = {
        ...data,
        facility: facilityId,
        images: serviceImages?.length ? serviceImages.slice(1) : [],
        imageExplorePreview: serviceImages?.length ? [serviceImages[0]] : [],
        imageDetailedPageMain: serviceImages?.length ? [serviceImages[0]] : [],
        city: facility?.city?.[0] || "",
        region: facility.region || "",
        duration,
        ticketPrice,
        seasonStart,
        seasonEnd,
    };

    delete serviceData["imagesFiles"];

    return serviceData;
};

export const formatDraftServiceToPost = async (
    data: NewServiceData,
    facility: Facility,
    tariffs: Tariff[],
    serviceImages?: string[],
    tariffDates?: TariffDates
) => {
    const facilityData = formatFacilityToPost(facility);

    const { formatRegistrationOnDate, formatRegistrationOffDate } = getTariffFormatString({
        registrationOnDateByTime: tariffDates?.registrationOnDateByTime,
        registrationOffDateByTime: tariffDates?.registrationOffDateByTime,
    });

    const tariffData = tariffs.map((tariff) => {
        const newTariff = {
            ...tariff,
            registrationOnDateByTime: formatRegistrationOnDate || null,
            registrationOffDateByTime: formatRegistrationOffDate || null,
        };
        delete newTariff.registrationOnDate;
        delete newTariff.registrationOffDate;

        return newTariff;
    });

    const serviceData: NewDraftServiceDataPost = {
        ...formatServiceToPost(data, facility, "", tariffs, serviceImages),
        facilityObject: facilityData,
        rateObject: tariffData,
    };

    return serviceData;
};

export const formatDraftServiceFromGet = async (
    initialService: NewServiceData,
    data: NewDraftServiceDataGet
): Promise<NewServiceData> => {
    const imagesFiles = await Promise.all(
        [...data.imageExplorePreview, ...data.images].map(async (img) => {
            const imageFile = await getDraftImageByURL(img);
            return {
                url: img,
                preview: URL.createObjectURL(imageFile),
                file: imageFile,
                id: img,
            };
        })
    );

    let hours;
    let minutes;

    if (data.duration.hours?.length) {
        hours = Number(data.duration.hours);
    }

    if (data.duration.minutes?.length) {
        minutes = Number(data.duration.minutes);
    }

    const service: NewServiceData = {
        ...initialService,
        ...data,
        imagesFiles,
        duration: getStringFromHoursMinutes(hours, minutes),
    };

    return service;
};
