import React, { useCallback, useMemo } from "react";
import { ExcursionDataClient, ExcursionEventItem } from "../helpers/types";
import { NavbarExcursionNamesEnum } from "../helpers/constants";
import { Form, Formik } from "formik";
import { excursionProgramValidation } from "../helpers/validation-schema";
import {
    AutoSubmit,
    DndUploadPhotosNew,
    InputListField,
    UploadPhotosItemNew,
    useViewportContext,
} from "@russpass/partner-front-ui";
import { getImageById } from "../../../../api/files";
import { onFileTooLarge, onPhotosLimit } from "../../../../utils/images";
import styles from "./excursion.module.sass";
import classNames from "classnames";

type Props = {
    data: ExcursionDataClient;
    onChange: (values: ExcursionDataClient) => void;
    onSubmit: (path: string) => void;
    isShowErrors: boolean;
    disabled: boolean;
    isSendToModerate: boolean;
};

const ExcursionProgram = ({ data, onChange, onSubmit, isShowErrors, disabled, isSendToModerate }: Props) => {
    const { isLaptop } = useViewportContext();

    const images = useMemo(() => {
        const _images: UploadPhotosItemNew[] = [];
        data?.route?.[0]?.events?.forEach((event, indx) => {
            if (event.imageFiles?.length) {
                _images.push({
                    id: event.imageFiles[0].id,
                    file: event.imageFiles[0].file,
                    url: event.imageFiles[0].url,
                    preview: event.imageFiles[0].preview,
                    isNew: event.imageFiles[0].isNew,
                    description: {
                        name: `route[${0}].events[${indx}].title`,
                        placeholder: "Добавить описание",
                        noHover: true,
                        maxRows: 2,
                        errorSettings: { showOnChange: isShowErrors },
                        disabled,
                        className: styles.photoDescText,
                    },
                });
            }
        });
        return _images;
    }, [data, disabled, isShowErrors]);

    const cardsInRowMaxCount = useMemo(() => {
        const minPhotosCount = 2;
        const maxPhotosCount = isLaptop ? 3 : 4;
        if (images.length < minPhotosCount) return minPhotosCount;
        else return images.length < maxPhotosCount ? images.length : maxPhotosCount;
    }, [images.length, isLaptop]);

    const handleChange = useCallback(
        (values: ExcursionEventItem[]) =>
            onChange({
                ...data,
                route: [{ events: [...values], title: "" }],
            }),
        [onChange, data]
    );

    const uploadPhotos = (values: UploadPhotosItemNew[]) => {
        const mapped = new Map(data?.route?.[0]?.events?.map((i) => [i.imageFiles?.[0]?.id, i.title]));

        const updatedData = values.map((item, indx) => {
            return {
                title: mapped.get(item.id) || "",
                imageFiles: [item],
            };
        });

        handleChange(updatedData);
    };

    return (
        <Formik
            /* TODO так как формик не валидирует повторно, если не изменились значения,
                    то при отправке на модерацию и подсвечиванию ошибок незаполненных полей, а зетам сохранение черновика
                    форма не обновляется: поискать другое решение при рефакторинге
                    (проблема на информации события, экскурсии и программы экскурсии) */
            key={isSendToModerate ? "excursionProgram" : "draftExcursionProgram"}
            initialValues={data}
            enableReinitialize
            validateOnMount
            validationSchema={isSendToModerate && excursionProgramValidation}
            onSubmit={() => {
                onSubmit(NavbarExcursionNamesEnum.Photos);
            }}
        >
            <Form>
                <div className={classNames("content-wrapper", styles.wrapper)}>
                    <div className="content-wrapper__form">
                        <AutoSubmit onChange={onChange} />
                        <InputListField
                            label="Программа"
                            name="program"
                            placeholderItems={["07:45 Сбор группы", "08:00 Отправление автобуса"]}
                            errorSettings={{ showOnChange: isShowErrors }}
                            uniquePrefixId="program"
                            disabled={disabled}
                        />
                        <InputListField
                            label="Входит в стоимость"
                            name="included"
                            placeholderItems={["Поездка на автобусе", "Услуги гида"]}
                            errorSettings={{ showOnChange: isShowErrors }}
                            uniquePrefixId="included"
                            disabled={disabled}
                        />
                        <InputListField
                            label="Оплачивается отдельно"
                            name="paidSeparately"
                            placeholderItems={["Обед и ужин", "Посещение музея"]}
                            errorSettings={{ showOnChange: isShowErrors }}
                            uniquePrefixId="paidSeparately"
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className={styles.containerPhotos}>
                    <DndUploadPhotosNew
                        items={images}
                        onChange={uploadPhotos}
                        disabled={disabled}
                        title="Добавить фото объектов экскурсии"
                        cardHeight={316}
                        cardMinHeight={316}
                        cardsGap={{ columnGap: 16, rowGap: 48 }}
                        onFileTooLarge={onFileTooLarge}
                        onPhotosLimit={onPhotosLimit}
                        viewTag={""}
                        columnCount={cardsInRowMaxCount}
                        isMaxWidthCardsUnset
                        cardMinWidth={213}
                        errorSettings={{
                            showOnChange: isSendToModerate,
                        }}
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default ExcursionProgram;
