import { AnySchema } from "yup";
import { FILE_TOO_LARGE, FORM_FIELD_REQUIRED_ERROR, FORMAT_ERROR } from "../../../../constants/errors";
import { LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS } from "../../../../constants/images";
import { VICARIOUS_AUTHORITY } from "./constants";
import { OfferTypeStatus } from "./hooks/useOfferTypeStatus";
import { LegalStateStatus } from "./hooks/useLegalStateStatus";
import { SignatoryRoleEnum } from "../../../../enums/signatory-role";
import { yup } from "../../../../yup";

const checkFileIsNotTooLarge = (value: any): boolean => {
    if (!value || !value.length) {
        return true;
    } else {
        return value[0].size < LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS * 1024 * 1024;
    }
};

const requiredFileSchema = yup
    .array()
    .min(1, FORM_FIELD_REQUIRED_ERROR)
    .test("is-file-too-large", FILE_TOO_LARGE(LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS), checkFileIsNotTooLarge);

const notRequiredFileSchema = yup
    .array()
    .test("is-file-too-large", FILE_TOO_LARGE(LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS), checkFileIsNotTooLarge);

function authorityTestV2(value: any) {
    if (value?.length) return true;
    // @ts-ignore
    const { from } = this;
    return (
        from[1].value.signLegalBasis !== VICARIOUS_AUTHORITY && from[1].value.signatoryRole !== SignatoryRoleEnum.AGENT
    );
}

const checkIsRequired = {
    is: (value: string | null) => !value,
    then: (schema: yup.StringSchema<string | null | undefined>) => schema.required(),
};

// Step 1
export const getStepOneSchema = (
    { isHotel, isApartment, isGuide }: OfferTypeStatus,
    { isIP, isSE, isUL }: LegalStateStatus
) => {
    const applicationAdditionDataBase: Record<string, AnySchema> = {
        // ФИО подписанта
        signatory: yup.string().required().max(200),
        // Банковские реквизиты
        bank: yup.object({
            // БИК банка
            bic: yup
                .string()
                .nullable(true)
                .onlyNumbers()
                .length(9, "В БИК должно быть 9 цифр")
                .when("treasuryAccount", checkIsRequired),
            // Наименование банка
            name: yup.string().when("treasuryAccount", checkIsRequired).max(300),
            // Кор. счет
            corrAccount: yup
                .string()
                .onlyNumbers()
                .length(20, "В счете должно быть 20 цифр")
                .when("treasuryAccount", checkIsRequired),
            // Расчетный счет
            checkingAccount: yup
                .string()
                .onlyNumbers()
                .length(20, "В счете должно быть 20 цифр")
                .when("treasuryAccount", checkIsRequired),
            // Казначейский счет
            treasuryAccount: yup.string().onlyNumbers().length(20, "В счете должно быть 20 цифр"),
        }),
        // Доверенность
        authority: yup.object({
            // Номер доверенности
            number: yup.string().max(50).test("authority", FORM_FIELD_REQUIRED_ERROR, authorityTestV2),
            // Дата выдачи
            dateIssue: yup.string().test("authority", FORM_FIELD_REQUIRED_ERROR, authorityTestV2),
            // Срок действия
            term: yup.string().max(100).test("authority", FORM_FIELD_REQUIRED_ERROR, authorityTestV2),
        }),
    };

    // Файлы
    const filesDataBase: Record<string, AnySchema> = {
        // Доверенность
        authority: yup
            .array()
            .test("authority", FORM_FIELD_REQUIRED_ERROR, function (value) {
                if (value && value.length) return true;
                else {
                    // @ts-ignore
                    const { from } = this;
                    return (
                        from[1].value.applicationAdditionData.signLegalBasis !== VICARIOUS_AUTHORITY &&
                        from[1].value.applicationAdditionData.signatoryRole !== SignatoryRoleEnum.AGENT
                    );
                }
            })
            .test("is-file-too-large", FILE_TOO_LARGE(LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS), checkFileIsNotTooLarge),
    };

    // Поля, зависимые от типа пользователя и типа оферты

    if (isUL) {
        // Должность подписанта
        applicationAdditionDataBase.position = yup.string().required().max(200);
        // Юр. основание права подписи
        applicationAdditionDataBase.signLegalBasis = yup.string().required();
    }
    if ((isUL && (isHotel || isApartment)) || isIP) {
        // НДС
        applicationAdditionDataBase.ndsRate = yup.string().required();
    }
    if (isHotel || isApartment || isGuide) {
        // ИНН
        filesDataBase.inn = requiredFileSchema;
    }
    if ((isHotel || isApartment || isGuide) && (isUL || isIP)) {
        // ОГРН
        filesDataBase.ogrn = requiredFileSchema;
    }
    if (isGuide) {
        // Аттестат экскурсовода
        filesDataBase.guideCertificate = notRequiredFileSchema;
    }
    if (isApartment) {
        // Выписка из ЕГРН
        filesDataBase.egrn = requiredFileSchema;
    }
    if (!isUL && isApartment) {
        // Кто подписывает
        applicationAdditionDataBase.signatoryRole = yup.string().required().oneOf(Object.values(SignatoryRoleEnum));
    }
    if (!(isUL || isIP)) {
        // Серия и номер паспорта
        applicationAdditionDataBase.identityNumber = yup.string().required().length(11, FORMAT_ERROR);
        // Адрес регистрации
        applicationAdditionDataBase.registrationAddress = yup.object().shape({
            unrestrictedValue: yup.string().required(),
        });
    }
    if (isSE) {
        // Справка о самозанятости
        filesDataBase.selfEmploymentCertificate = requiredFileSchema;
    }

    return yup.object().shape({
        applicationAdditionData: yup.object(applicationAdditionDataBase),
        filesData: yup.object(filesDataBase),
    });
};

// Step 2
export const tourCompanyInfoValidation = yup.object().shape({
    applicationAdditionData: yup.object({
        tourOperatorInfo: yup.object({
            registerNumber: yup.string().max(50).required(),
            financialSecurityAmount: yup.string().onlyNumbers().max(10).required(),
            financialSecurityType: yup.string().required(),
            financialSecurityNumber: yup.string().max(50).required(),
            financialSecurityDate: yup.date().required().max(new Date()),
            financialSecurityTerm: yup.string().max(100).required(),
            financialSecurityProvider: yup.string().max(300).required(),
            financialSecurityProviderLegalAddress: yup.string().max(300).required(),
            financialSecurityProviderActualAddress: yup.string().max(300).required(),
        }),
    }),
    filesData: yup.object({
        contract: requiredFileSchema,
    }),
});
