import { russpassApiServices } from "../../constants/api";
import fetchRequest from "../manager";
import { NewDraftServiceDataPost } from "../../containers/services/service/helpers/types";

const filesDraftServiceUrl = `${russpassApiServices.draft}`;

export const saveServiceDraft = async (externalPartnerId: string, draft: NewDraftServiceDataPost) => {
    const data = await fetchRequest.post(
        `${filesDraftServiceUrl}/services`,
        {
            externalPartnerId,
            typeSource: "LKP",
            draft,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const getServiceDraft = async (serviceId: string) => {
    const data = await fetchRequest.get(`${filesDraftServiceUrl}/services/${serviceId}`, null, {
        isProtected: true,
    });
    return data;
};

export const patchServiceDraft = async (id: string, draft: NewDraftServiceDataPost) => {
    const data = await fetchRequest.patch(
        `${filesDraftServiceUrl}/services`,
        {
            id,
            draft,
        },
        {
            isProtected: true,
        }
    );
    return data;
};
