import { PhotoGuideModalProps } from "@russpass/partner-front-ui";
import Photo1_01 from "../../assets/images/photoGuide/apartment/photo-guide-row-1_01.png";
import Photo1_02 from "../../assets/images/photoGuide/apartment/photo-guide-row-1_02.png";
import Photo2_01 from "../../assets/images/photoGuide/apartment/photo-guide-row-2_01.png";
import Photo2_02 from "../../assets/images/photoGuide/apartment/photo-guide-row-2_02.png";
import Photo3_01 from "../../assets/images/photoGuide/apartment/photo-guide-row-3_01.png";
import Photo3_02 from "../../assets/images/photoGuide/apartment/photo-guide-row-3_02.png";
import Photo4_01 from "../../assets/images/photoGuide/apartment/photo-guide-row-4_01.png";
import Photo4_02 from "../../assets/images/photoGuide/apartment/photo-guide-row-4_02.png";
import Photo5_01 from "../../assets/images/photoGuide/apartment/photo-guide-row-5_01.png";
import Photo5_02 from "../../assets/images/photoGuide/apartment/photo-guide-row-5_02.png";

export const photoGuideApartmentData: PhotoGuideModalProps = {
    disclaimer: [
        {
            text: "Вес одной фотографии — до 15 Мб, формат — png, jpeg",
            isCorrectly: true,
        },
        {
            text: "В кадре не должно быть алкоголя, сигарет, кальяна, наркотиков, логотипов, водяных знаков, детей и посторонних людей",
            isCorrectly: false,
        },
    ],
    blocks: [
        {
            images: [Photo1_01, Photo1_02],
            tip: {
                text: "Покажите интересные детали интерьера: картины, декор, текстиль",
                isCorrectly: true,
            },
        },
        {
            images: [Photo2_01, Photo2_02],
            tip: {
                text: "Добавьте фотографии всех помещений, включая балкон, коридор, прихожую и т. д.",
                isCorrectly: true,
            },
        },
        {
            images: [Photo3_01, Photo3_02],
            tip: {
                text: "Покажите вид из окна",
                isCorrectly: true,
            },
        },
        {
            images: [Photo4_01, Photo4_02],
            tip: {
                text: "Не загружайте снимки, где важные объекты расположены по краям: в некоторых разделах фотографии обрезаются по вертикали и используется их центральная часть",
                isCorrectly: false,
            },
        },
        {
            images: [Photo5_01, Photo5_02],
            tip: {
                text: "Не загружайте темные, смазанные снимки",
                isCorrectly: false,
            },
        },
    ],
};
