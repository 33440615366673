import { useCallback } from "react";
import { ListItem, ListServiceDraftItem } from "./types";
import { getEventsAll } from "../../../api/service";
import { modifyDraftList, packingEventsByStatus } from "./helper";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { getDrafts } from "../../../api/draft/draft";
import useUserInfo from "../../../hooks/useUserInfo";
import { ServiceCategory } from "./constants";

const useEvents = () => {
    const { userFullInfo } = useUserInfo();
    const loadEvents = useCallback(async () => {
        try {
            const draftServices: ListServiceDraftItem[] = await getDrafts(
                userFullInfo?.applicationId,
                ServiceCategory.Services
            );
            const modifiedDraftServices = await modifyDraftList(draftServices, "draftServiceId");
            const responseEvents: ListItem[] = await getEventsAll().catch(() => {
                return [];
            });

            const {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            } = packingEventsByStatus(modifiedDraftServices.concat(responseEvents));

            return {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            };
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                draftServicesArray: [],
                moderationServicesArray: [],
                publishedServicesArray: [],
                refusedServicesArray: [],
                archiveServicesArray: [],
                allServicesArray: [],
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        loadEvents,
    };
};

export default useEvents;
