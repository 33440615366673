import {
    EMAIL_FORMAT_ERROR,
    FORM_FIELD_REQUIRED_ERROR,
    FORM_MIN_ERROR,
    FORMAT_ERROR,
    MIN_COUNT_PHOTOS_ERROR,
} from "../../../constants/errors";
import { object as objectYup, string as stringYup, array as arrayYup } from "yup";
import { emailRegexp, phoneRegexp } from "../../../utils/validation";
import { placeTimetableValidation } from "../../../components/timetable/place-timetable/validation-schema";
import { isEnableRestaurantNewFields } from "../../../constants/feature-toggles";
import { DESC_FIELD_MIN_LENGTH } from "../../../constants/inputs";

const simpleStrValidation = stringYup().required(FORM_FIELD_REQUIRED_ERROR);

export const restaurantInfoValidation = objectYup().shape({
    name: simpleStrValidation,
    description: stringYup().required(FORM_FIELD_REQUIRED_ERROR).min(DESC_FIELD_MIN_LENGTH, FORM_MIN_ERROR),
    bill: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
    cuisines: arrayYup().test("isRequired", FORM_FIELD_REQUIRED_ERROR, function (cuisines) {
        if (cuisines) {
            return cuisines.length > 0;
        }
        return false;
    }),
    ...(isEnableRestaurantNewFields && {
        restaurantTypes: objectYup().required(FORM_FIELD_REQUIRED_ERROR).nullable(),
        restaurantTags: arrayYup().test("isRequired", FORM_FIELD_REQUIRED_ERROR, function (restaurantTags) {
            if (restaurantTags) {
                return restaurantTags.length > 0;
            }
            return false;
        }),
    }),
    phone: simpleStrValidation.matches(phoneRegexp, FORMAT_ERROR).nullable(),
    email: stringYup()
        .matches(emailRegexp, {
            message: EMAIL_FORMAT_ERROR,
        })
        .required(FORM_FIELD_REQUIRED_ERROR),
    region: objectYup({ id: simpleStrValidation }),
    city: objectYup({ id: simpleStrValidation }),
    address: objectYup({ unrestricted_value: simpleStrValidation }),
    images: arrayYup()
        .test("isRequired", FORM_FIELD_REQUIRED_ERROR, function (images) {
            if (images) {
                return images.length > 0;
            }
            return false;
        })
        .min(2, MIN_COUNT_PHOTOS_ERROR),
    workingTime: arrayYup().of(placeTimetableValidation(false)),
});
