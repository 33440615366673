import { Category, Gender, RatingsStatus, TripPurpose } from "./types";
import { isRatingV2 } from "../../../../constants/feature-toggles";

export const ratingsStatusesLabels: { [key: string]: string } = isRatingV2
    ? {
          ALL: "Все отзывы",
          PUBLISHED: "Опубликованы",
          IN_DISPUTE: "Оспариваются",
      }
    : {
          ALL: "Все статусы",
          PUBLISHED: "Опубликована",
          IN_DISPUTE: "Оспаривается",
      };

export type RatingsStatusItem = {
    label: string;
    value: RatingsStatus;
};

export const ratingsStatuses: RatingsStatusItem[] = [
    {
        label: ratingsStatusesLabels.ALL,
        value: RatingsStatus.ALL,
    },
    {
        label: ratingsStatusesLabels.PUBLISHED,
        value: RatingsStatus.PUBLISHED,
    },
    {
        label: ratingsStatusesLabels.IN_DISPUTE,
        value: RatingsStatus.IN_DISPUTE,
    },
];

export const GENDER_MAP: { [key in Gender]: string } = {
    [Gender.MALE]: "Мужской",
    [Gender.FEMALE]: "Женский",
};

export const CATEGORY_MAP: { [key in Category]: string } = {
    [Category.GROUP]: "Группа",
    [Category.SINGLE]: "Самостоятельный",
    [Category.CHILDREN]: "С детьми",
};

export const TRIP_PURPOSE_MAP: { [key in TripPurpose]: string } = {
    [TripPurpose.REST]: "Отдых",
    [TripPurpose.BUSINESS]: "Работа",
};
