import { useCallback, useEffect, useMemo, useState } from "react";
import { getTariffs } from "../../../../api/billing";
import { Tariff, TariffDates, TariffListWithPagination } from "./types";
import { TariffList } from "./mock";

const useTariffs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [initialTariffs, setInitialTariffs] = useState<TariffListWithPagination>(TariffList);
    const [tariffs, setTariffs] = useState<TariffListWithPagination>(TariffList);
    const [tariffDates, setTariffDates] = useState<TariffDates>({
        registrationOnDateByTime: "",
        registrationOffDateByTime: "",
    });
    const [initialTariffDates, setInitialTariffDates] = useState<TariffDates>({
        registrationOnDateByTime: "",
        registrationOffDateByTime: "",
    });

    const currentBasicTariff = useMemo(() => tariffs.content.find((item) => item.isBasic) || null, [tariffs]);

    const formatRegistrationDateString = useCallback(
        (dateString: string) =>
            dateString
                ? `${dateString.split(":")[0].padStart(4, "0")} ч ${dateString
                      .split(":")[1]
                      .padStart(2, "0")} мин до сеанса`
                : "",
        []
    );

    useEffect(() => {
        const basicTariff = tariffs.content.find((item) => item.isBasic) || null;

        const registrationOnDateString = basicTariff
            ? basicTariff.registrationOnDate
            : tariffs.content?.find((tariff) => tariff.registrationOnDate)?.registrationOnDate ?? "";

        const registrationOffDateString = basicTariff
            ? basicTariff.registrationOffDate
            : tariffs.content?.find((tariff) => tariff.registrationOffDate)?.registrationOffDate ?? "";

        setTariffDates((tariffDates) => {
            if (tariffDates.registrationOnDateByTime || tariffDates.registrationOffDateByTime) return tariffDates;
            return {
                registrationOnDateByTime: formatRegistrationDateString(registrationOnDateString ?? ""),
                registrationOffDateByTime: formatRegistrationDateString(registrationOffDateString ?? ""),
            };
        });
    }, [tariffs]);

    const loadTariffList = useCallback(async (billingProductId?: number | string, page?: number, size?: number) => {
        if (billingProductId) {
            setIsLoading(true);
            let tariffs;
            do {
                tariffs = await getTariffs(billingProductId, page, 21);
                if (page) {
                    page = page - 1;
                }
            } while (page === undefined || page === -1 ? false : tariffs.content.length === 0);

            const basicTariff = tariffs.content.find((item) => item.isBasic);

            const registrationOnDateString = basicTariff?.registrationOnDate
                ? basicTariff.registrationOnDate
                : tariffs.content?.find((tariff) => tariff.registrationOnDate)?.registrationOnDate ?? "";

            const registrationOffDateString = basicTariff?.registrationOffDate
                ? basicTariff.registrationOffDate
                : tariffs.content?.find((tariff) => tariff.registrationOffDate)?.registrationOffDate ?? "";

            setInitialTariffDates({
                registrationOnDateByTime: formatRegistrationDateString(registrationOnDateString),
                registrationOffDateByTime: formatRegistrationDateString(registrationOffDateString),
            });

            setInitialTariffs(tariffs);
            setTariffs(tariffs);
            setIsLoading(false);
        }
    }, []);

    const loadDraftTariffList = useCallback((draftTariffs: Tariff[]) => {
        setInitialTariffs({
            ...TariffList,
            content: draftTariffs,
            size: draftTariffs.length,
        });
        setTariffs({
            ...TariffList,
            content: draftTariffs,
            size: draftTariffs.length,
        });
    }, []);

    const onChangeTariffList = useCallback((tariffs: Tariff[]) => {
        setTariffs((prev) => ({
            ...prev,
            content: tariffs,
        }));
    }, []);

    return {
        tariffs,
        initialTariffs,
        loadTariffList,
        loadDraftTariffList,
        onChangeTariffList,
        isLoadingTariffList: isLoading,
        tariffDates,
        setTariffDates,
        initialTariffDates,
        currentBasicTariff,
    };
};

export default useTariffs;
