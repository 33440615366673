import { useCallback } from "react";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { getAllTours } from "../../../api/tour";
import { modifyDraftList, packingEventsByStatus } from "./helper";
import { ListItem, ListTourDraftItem } from "./types";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import useUserInfo from "../../../hooks/useUserInfo";
import { getDrafts } from "../../../api/draft/draft";
import { ServiceCategory } from "./constants";

const useTours = () => {
    const { userFullInfo } = useUserInfo();
    const loadTours = useCallback(async () => {
        try {
            const draftTours: ListTourDraftItem[] = await getDrafts(userFullInfo?.applicationId, ServiceCategory.Tours);
            const modifiedDraftTours = await modifyDraftList(draftTours, "draftTourId");
            const responseTours: ListItem[] = await getAllTours().catch(() => {
                return [];
            });

            const {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            } = packingEventsByStatus(modifiedDraftTours.concat(responseTours));

            return {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            };
        } catch (error) {
            console.error(error);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                draftServicesArray: [],
                moderationServicesArray: [],
                publishedServicesArray: [],
                refusedServicesArray: [],
                archiveServicesArray: [],
                allServicesArray: [],
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        loadTours,
    };
};

export default useTours;
