import routes from "../constants/routes";
import { isAvailableTours, isVisibleAnalytics } from "../constants/feature-toggles";
import { useCallback } from "react";
import useUserInfo from "./useUserInfo";

const useAccessManager = () => {
    const {
        isHotel,
        isRestaurant,
        isApartment,
        isGuide,
        isDisplayObject,
        isNotGuideAttestation,
        isChecked,
        isReviewLKP,
        isValidOffer,
        isToBeAgreed,
    } = useUserInfo();

    const getIsAvailableRoute = useCallback(
        (path?: string | readonly string[]): boolean => {
            switch (path) {
                case routes.profile:
                case routes.support:
                case routes.main:
                case routes.offer:
                case routes.documents:
                    return true;

                case routes.restaurantAll:
                case routes.restaurantNew:
                case routes.restaurantView:
                case routes.bookingAll:
                    return isRestaurant && isValidOffer;

                case routes.apartments:
                case routes.apartmentsAll:
                case routes.apartmentsNew:
                case routes.apartmentsView:
                case routes.dialogues:
                case routes.apartmentsBookingAll:
                    return isApartment && isValidOffer;

                case routes.events:
                case routes.servicesAdd:
                case routes.servicesNew:
                case routes.servicesView:
                case routes.draftServicesView:
                    return isDisplayObject && isValidOffer;

                case routes.services:
                case routes.excursions:
                case routes.excursionsView:
                case routes.draftExcursionsView:
                case routes.orders:
                case routes.servicesAll:
                    return (isDisplayObject || isGuide) && isValidOffer;

                case routes.excursionsNew:
                    return (isDisplayObject || (isGuide && !isNotGuideAttestation)) && isValidOffer;

                case routes.tours:
                case routes.toursNew:
                case routes.toursView:
                    return isDisplayObject && isValidOffer && isAvailableTours;

                case routes.hotelsAll:
                case routes.hotels:
                case routes.hotelsNew:
                case routes.hotelsView:
                case routes.hotelsViewTariffs:
                case routes.hotelsViewRooms:
                case routes.hotelsViewRatings:
                case routes.hotelsViewDiscounts:
                case routes.intHotelsView:
                    return isHotel && isValidOffer;

                case routes.notifications:
                case routes.notificationsSettings:
                    return isHotel;

                case routes.analytics:
                    return isHotel && isVisibleAnalytics && isChecked;

                case routes.contracts:
                    return !isReviewLKP;

                case routes.offerSignSuccess:
                case routes.partnerInfo:
                    return true;

                case routes.feedback:
                case routes.feedbackItem:
                    return !isRestaurant && isChecked;

                case routes.reports:
                    return isChecked;

                case routes.salesReport:
                    return !isHotel && !isRestaurant && isChecked;

                default:
                    return false;
            }
        },
        [isRestaurant, isHotel, isChecked, isToBeAgreed, isReviewLKP, isGuide, isApartment]
    );

    const getMainPageRedirectPath = useCallback(() => {
        if (isReviewLKP) {
            return routes.profile;
        }
        if (!isValidOffer) {
            return routes.offer;
        }
        if (isHotel) {
            return routes.hotelsAll;
        } else if (isRestaurant) {
            return routes.restaurantAll;
        } else if (isApartment) {
            return routes.apartmentsAll;
        } else {
            return routes.servicesAll;
        }
    }, [isReviewLKP, isChecked, isValidOffer, isHotel, isRestaurant, isApartment]);

    return {
        getIsAvailableRoute,
        getMainPageRedirectPath,
    };
};

export default useAccessManager;
