import React from "react";
import { Form, Formik, FormikProps } from "formik";
import {
    InputField,
    TextareaField,
    AutoSubmit,
    SelectInputField,
    RadioGroup,
    RangeDatePickerField,
} from "@russpass/partner-front-ui";
import { maskedInputNumbers, maskedInputTimeInHoursAndMinutes } from "../../../../utils/validation";
import { ExcursionDataClient } from "../helpers/types";
import { NavbarExcursionNamesEnum } from "../helpers/constants";
import { draftExcursionInfoValidation, excursionInfoValidation } from "../helpers/validation-schema";
import Region from "../../../../components/async-selects/region";
import SelectCity from "../../../../components/async-selects/select-city";
import { TSelectValue } from "../../../../types/dictionaries";
import { PAYMENT_TYPE_OPTIONS, PERSONALIZED_TICKET_OPTIONS, SERVICE_MAX_TITLE_LENGTH } from "../../constants";
import { IAtolVat } from "../../../../types/billing";
import SelectAddress from "../../../../components/async-selects/address";
import {
    DESC_FIELD_MAX_LENGTH,
    DESC_FIELD_MAX_ROWS,
    DESC_FIELD_MIN_LENGTH,
    DESC_FIELD_PLACEHOLDER,
} from "../../../../constants/inputs";
import { LENGTH_EXCEEDS_MAXIMUM_ERROR, LENGTH_LESS_THEN_MINIMUM_ERROR } from "../../../../constants/errors";

type Props = {
    data: ExcursionDataClient;
    forms: TSelectValue[];
    excursionViewOptions: TSelectValue[];
    onChange: (values: ExcursionDataClient) => void;
    onSubmit: (path: string) => void;
    isShowErrors: boolean;
    region: TSelectValue;
    onChangeRegion: (region: TSelectValue) => void;
    city: TSelectValue;
    onChangeCity: (city: TSelectValue) => void;
    disabled: boolean;
    holdingSpaceOptions: TSelectValue[];
    optionsNdsRate: IAtolVat[];
    isDisabledExcursionView: boolean;
    isSendToModerate: boolean;
};

const ExcursionInfo = ({
    data,
    forms,
    onChange,
    onSubmit,
    isShowErrors,
    region,
    onChangeRegion,
    city,
    onChangeCity,
    disabled,
    holdingSpaceOptions,
    optionsNdsRate,
    excursionViewOptions,
    isDisabledExcursionView,
    isSendToModerate,
}: Props) => {
    return (
        <div className="content-wrapper">
            <Formik
                /* TODO так как формик не валидирует повторно, если не изменились значения,
                    то при отправке на модерацию и подсвечиванию ошибок незаполненных полей, а зетам сохранение черновика
                    форма не обновляется: поискать другое решение при рефакторинге
                    (проблема на информации события, экскурсии и программы экскурсии) */
                key={isSendToModerate ? "excursionInfo" : "draftExcursionInfo"}
                initialValues={data}
                validationSchema={isSendToModerate ? excursionInfoValidation : draftExcursionInfoValidation}
                enableReinitialize
                validateOnMount
                onSubmit={() => {
                    onSubmit(NavbarExcursionNamesEnum.Tariffs);
                }}
            >
                {({ setFieldValue, values }: FormikProps<ExcursionDataClient>) => {
                    const holdingSpaceValue = holdingSpaceOptions.find((el) => el.id === values.holdingSpace) || {
                        id: "",
                        title: "",
                    };
                    const ndsPercentValue = optionsNdsRate.find((el) => el.caption === values.ndsPercent) || {
                        code: "",
                        caption: "",
                    };

                    const excursionView = excursionViewOptions.find((v) => v.id === values?.excursionView) || {
                        id: "",
                        title: "",
                    };

                    return (
                        <Form>
                            <div className="content-wrapper__form">
                                <AutoSubmit onChange={onChange} />
                                <TextareaField
                                    name="title"
                                    disabled={disabled}
                                    maxRows={3}
                                    noHover
                                    label={"Название"}
                                    placeholder={"Быт и нравы 19 века"}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    maxLength={SERVICE_MAX_TITLE_LENGTH}
                                />
                                <SelectInputField
                                    name="paymentType"
                                    handleChange={(value) => {
                                        setFieldValue("paymentType", value.value);
                                    }}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    options={PAYMENT_TYPE_OPTIONS}
                                    value={PAYMENT_TYPE_OPTIONS.find((v) => v.value === values.paymentType)}
                                    valueField="value"
                                    labelField="label"
                                    placeholder="Выбрать"
                                    isChecked
                                    label="Способ покупки"
                                />
                                <RadioGroup
                                    options={PERSONALIZED_TICKET_OPTIONS}
                                    label="Именные билеты"
                                    disabled={disabled}
                                    hint={
                                        values.personalizedTickets
                                            ? "Запросим ФИО всех посетителей"
                                            : "Запросим только ФИО покупателя"
                                    }
                                    isAlwaysShowHint
                                    value={
                                        PERSONALIZED_TICKET_OPTIONS.find(
                                            (option) => option.value === values.personalizedTickets
                                        ) || {}
                                    }
                                    onChange={(option) => {
                                        setFieldValue("personalizedTickets", option.value);
                                    }}
                                />
                                <TextareaField
                                    label="Описание"
                                    name="description"
                                    noHover
                                    placeholder={DESC_FIELD_PLACEHOLDER(DESC_FIELD_MIN_LENGTH)}
                                    minLength={DESC_FIELD_MIN_LENGTH}
                                    maxLength={DESC_FIELD_MAX_LENGTH}
                                    minLengthErrorText={LENGTH_LESS_THEN_MINIMUM_ERROR(DESC_FIELD_MIN_LENGTH)}
                                    maxLengthErrorText={LENGTH_EXCEEDS_MAXIMUM_ERROR}
                                    maxRows={DESC_FIELD_MAX_ROWS}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />
                                <SelectInputField
                                    name="holdingSpace"
                                    value={holdingSpaceValue}
                                    options={holdingSpaceOptions}
                                    disabled={disabled}
                                    labelField="title"
                                    placeholder="Выбрать"
                                    valueField="id"
                                    label="Категория"
                                    isChecked
                                    handleChange={(value) => setFieldValue("holdingSpace", value.id)}
                                    errorSettings={{ showOnChange: isShowErrors }}
                                />
                                <SelectInputField
                                    name="excursionView"
                                    handleChange={(value) => {
                                        setFieldValue("excursionView", value.id);
                                    }}
                                    disabled={isDisabledExcursionView}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    options={excursionViewOptions}
                                    value={excursionView}
                                    valueField="id"
                                    labelField="title"
                                    placeholder="Выбрать"
                                    isChecked
                                    label="Вид"
                                />

                                <InputField
                                    label="Минимальная численность группы"
                                    disabled={disabled}
                                    name="minGroupCount"
                                    noHover
                                    placeholder="2 чел."
                                    maxLength={6}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    renderComponent={maskedInputNumbers}
                                />
                                <InputField
                                    label="Максимальная численность группы"
                                    disabled={disabled}
                                    name="maxGroupCount"
                                    noHover
                                    placeholder="35 чел."
                                    maxLength={6}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    renderComponent={maskedInputNumbers}
                                />
                                <Region
                                    name="region"
                                    value={region}
                                    disabled={disabled}
                                    placeholder="Краснодарский край"
                                    handleChange={(value) => {
                                        onChangeRegion(value);
                                        setFieldValue("region", value.id);
                                    }}
                                    isShowErrors={isShowErrors}
                                />
                                <SelectCity
                                    label="Населенный пункт"
                                    name="city"
                                    value={city}
                                    disabled={disabled}
                                    placeholder="Краснодар"
                                    handleChange={(value) => {
                                        onChangeCity(value);
                                        setFieldValue("city", value.id);
                                    }}
                                    isShowErrors={isShowErrors}
                                />
                                <SelectAddress
                                    name="startPointAddress"
                                    label="Адрес места встречи"
                                    placeholder="г. Краснодар, ул. Пришвина, дом 15"
                                    value={{ value: values.startPointAddress }} //требуется объект
                                    labelField="value"
                                    handleChange={({ value }) => {
                                        setFieldValue("startPointAddress", value);
                                    }}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    disabled={disabled}
                                />

                                <TextareaField
                                    label="Уточнение места встречи"
                                    name="startPointAdditInfo"
                                    noHover
                                    placeholder={`У фонтана в центре площади`}
                                    minLength={0}
                                    maxLength={300}
                                    maxRows={15}
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />

                                <SelectInputField
                                    name="excursionForm"
                                    handleChange={(value) => {
                                        setFieldValue("excursionForm", value.id);
                                    }}
                                    disabled={disabled}
                                    options={forms}
                                    value={forms.find((form) => form.id === data.excursionForm) || ""}
                                    valueField="id"
                                    labelField="title"
                                    placeholder="Выберите"
                                    label="Способ передвижения"
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                />
                                <InputField
                                    label="Продолжительность"
                                    noHover
                                    name="duration"
                                    placeholder="02 ч. 30 мин."
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    renderComponent={maskedInputTimeInHoursAndMinutes}
                                />

                                <RangeDatePickerField
                                    label="Экскурсионный сезон"
                                    placeholder="Выберите"
                                    nameFrom="seasonStart"
                                    nameTo="seasonEnd"
                                    disabled={disabled}
                                    errorSettings={{
                                        showOnChange: isShowErrors,
                                    }}
                                    isShowAllYearCheckbox
                                    allYearCheckboxValue={values.seasonUnlimited}
                                    allYearCheckboxChange={(val) => {
                                        setFieldValue("seasonUnlimited", val);
                                    }}
                                />

                                <SelectInputField
                                    name="ndsPercent"
                                    value={ndsPercentValue}
                                    options={optionsNdsRate}
                                    disabled={disabled}
                                    labelField="caption"
                                    placeholder="Выбрать"
                                    valueField="code"
                                    label="НДС"
                                    isChecked
                                    handleChange={(value) => {
                                        setFieldValue("ndsPercent", value.caption);
                                    }}
                                    errorSettings={{ showOnChange: isShowErrors }}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ExcursionInfo;
