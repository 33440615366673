import { Form } from "formik";
import SignatoryRole from "../fields/SignatoryRole";
import { RegistrationAddressField } from "../fields/RegistrationAddressField";
import React from "react";
import { SignatoryFioField } from "../fields/SignatoryFioField";
import { PassportField } from "../fields/PassportField";
import { InnFileField } from "../fields/InnFileField";
import { NdsField } from "../fields/NdsField";
import { DocumentTypeField } from "../fields/DocumentTypeField";
import { AuthorityFileField } from "../fields/AuthorityFileField";
import { GuideCertificate } from "../fields/GuideCertificate";
import { AccreditationArdFileField } from "../fields/AccreditationСardFileField";
import AuthorityFields from "../fields/AuthorityFields";
import BankDetails from "../fields/BankDetails";
import useUserInfo from "../../../../../../hooks/useUserInfo";

const IndividualForm = () => {
    const { isGuide, isDisplayObject } = useUserInfo();

    const isShowAdditionalFields = isDisplayObject || isGuide;

    return (
        <div className="company-info__form content-wrapper__form">
            <Form>
                <div className="company-info__title">Основная информация</div>
                <SignatoryFioField />
                <SignatoryRole />
                <AuthorityFields />
                {isShowAdditionalFields && (
                    <>
                        <NdsField />
                        <DocumentTypeField />
                    </>
                )}

                <div className="company-info__title">Паспортные данные ИП</div>
                <PassportField />
                <RegistrationAddressField />

                {isShowAdditionalFields && <BankDetails />}

                <InnFileField />
                <AuthorityFileField />
                {isGuide && (
                    <>
                        <GuideCertificate />
                        <AccreditationArdFileField />
                    </>
                )}
            </Form>
        </div>
    );
};

export default IndividualForm;
