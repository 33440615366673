import { SelectInputField } from "@russpass/partner-front-ui";
import { authority, FieldNames, IPSignatoryRoleOptions } from "../../constants";
import React from "react";
import { useFormikContext } from "formik";
import { SignatoryRoleEnum } from "../../../../../../enums/signatory-role";

const SignatoryRole = () => {
    const { setFieldValue, getFieldProps } = useFormikContext();
    const name = FieldNames.signatoryRole;
    const value = getFieldProps(name).value;
    return (
        <SelectInputField
            name={FieldNames.signatoryRole}
            handleChange={(value) => {
                if (value.code === SignatoryRoleEnum.OWNER) {
                    setFieldValue(FieldNames.authority, undefined);
                }
                if (value.code === SignatoryRoleEnum.AGENT) {
                    setFieldValue(FieldNames.authority, authority);
                }
                setFieldValue(FieldNames.signatoryRole, value.code);
            }}
            value={IPSignatoryRoleOptions.find((el) => el.code === value) || { code: "", caption: "" }}
            options={IPSignatoryRoleOptions}
            valueField="code"
            labelField="caption"
            placeholder="Выбрать"
            label="Кто подписывает"
            isChecked
        />
    );
};

export default SignatoryRole;
