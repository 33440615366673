import React from "react";
import styles from "./ActionForm.module.sass";
import routes from "../../../../../constants/routes";

export type ActionFormProps = {
    onClick: () => void;
    body?: string;
    buttonText?: string;
};

const ActionForm = ({ onClick, body, buttonText }: ActionFormProps) => {
    return (
        <>
            <div className={styles.actionForm__text}>
                {body || (
                    <>
                        Нажимая на кнопку, вы принимаете условия
                        <a
                            href={`${window.location.origin}${routes.offer}`}
                            title="публичная оферта"
                            className={styles.actionForm__link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" "}
                            публичной оферты{" "}
                        </a>
                        и даете согласие на обработку
                        <a
                            href="https://russpass.ru/personal-data"
                            title="персональные данные"
                            className={styles.actionForm__link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" "}
                            персональных данных
                        </a>
                    </>
                )}
            </div>
            <button className="button button--primary" type={"submit"} onClick={onClick}>
                {buttonText ? buttonText : "Продолжить"}
            </button>
        </>
    );
};

export default ActionForm;
