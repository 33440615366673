import { partnerApiServices, russpassApiServices } from "../../constants/api";
import { BankAccountData, Offer } from "../../containers/documents/offer-deprecated/company-info/types";
import fetchRequest from "../manager";
import {
    DocumentDataTypeRequest,
    OfferNew,
} from "../../containers/documents/OfferPage/CompanyInfo/OfferTypeForms/types";
import {
    ConfirmApplication,
    ConfirmResponse,
} from "../../containers/documents/offer-deprecated/company-info/types/simple-registration";

const partnershipServicePath = russpassApiServices.partnership;
const crmPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.crm}`;
const documentsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.documents}`;
const applicationsPartnerServiceUrl = `${partnershipServicePath}${partnerApiServices.applications}`;

export const getOffer = async (partnerId: string) => {
    const { body } = await fetchRequest.get(`${crmPartnerServiceUrl}/partners/${partnerId}/offers`, null, {
        isProtected: true,
        isNotJSONResponse: true,
    });
    return body;
};

export const savePartnerDocs = async (applicationId: string, file: File, type: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    const content = await fetchRequest.file(`${crmPartnerServiceUrl}/partners/${applicationId}/documents`, formData, {
        isProtected: true,
    });
    return content;
};

export const savePartnerInfo = async (applicationId: string, data: Offer | OfferNew) => {
    const content = await fetchRequest.patch(`${documentsPartnerServiceUrl}/contract/${applicationId}`, data, {
        isProtected: true,
    });
    return content;
};

export const signOfferRequest = async (applicationId: string) => {
    const content = await fetchRequest.patch(`${crmPartnerServiceUrl}/partners/${applicationId}/offers`, null, {
        isProtected: true,
    });
    return content;
};

export const validateBankData = async (data: BankAccountData) => {
    const content = await fetchRequest.post(`${russpassApiServices.partnership}/validation/account`, data, {
        isProtected: true,
    });
    return content;
};

// Согласование заявки
export const confirmApplication = async (applicationId: string): Promise<ConfirmApplication> => {
    const content = await fetchRequest.put(`${applicationsPartnerServiceUrl}/confirm/${applicationId}`, null, {});
    return content;
};

// Согласование заявки сотрудниками Руководства
export const confirmApplicationByLeadership = async (applicationId: string): Promise<ConfirmResponse> => {
    const content = await fetchRequest.put(
        `${applicationsPartnerServiceUrl}/confirmByLeadership/${applicationId}`,
        null,
        {}
    );
    return content;
};

// Согласование договора
export const confirmDocument = async (applicationId: string): Promise<ConfirmResponse> => {
    const content = await fetchRequest.put(`${documentsPartnerServiceUrl}/confirm/${applicationId}`, null, {});
    return content;
};

// Передача данных документов партнера в crm
export const saveDocumentData = async (
    applicationId: string,
    documentId: string,
    data: DocumentDataTypeRequest
): Promise<ConfirmResponse> => {
    const content = await fetchRequest.patch(
        `${crmPartnerServiceUrl}/partners/${applicationId}/documents/${documentId}`,
        data,
        { isProtected: true }
    );
    return content;
};
