import { RadioGroupField, StatusRequestEnum } from "@russpass/partner-front-ui";
import React, { useCallback, useState } from "react";
import { useFormikContext } from "formik";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../../../../constants/errors";
import { DocumentTypes, EdoDocumentTypeEnum } from "../../constants";
import { getDocumentTypes } from "../../../../../../api/documents";
import { EDOField } from "./EDOField";

export type EDOType = {
    id: string;
    name: string;
};

export const DocumentTypeField = () => {
    const { setFieldValue, getFieldProps } = useFormikContext();
    const name = "documentType";

    const [isLoadedEDO, setIsLoadedEDO] = useState(false);
    const [EDOTypes, setEDOTypes] = useState<EDOType[]>([]);

    const loadData = useCallback(async () => {
        if (isLoadedEDO) return;
        try {
            const content = await getDocumentTypes();
            setEDOTypes(content);
            setIsLoadedEDO(true);
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: UNEXPECTED_ERROR,
            });
        }
    }, [isLoadedEDO]);

    const value = getFieldProps(name).value;

    return (
        <>
            <RadioGroupField
                name={name}
                options={DocumentTypes}
                label="Тип документооборота"
                value={DocumentTypes.find((option) => option.value === value) || ""}
                onChange={(option) => {
                    if (option.value === EdoDocumentTypeEnum.ELECTRONIC) {
                        loadData();
                    }
                    setFieldValue(name, option.value);
                }}
            />
            {value === EdoDocumentTypeEnum.ELECTRONIC && <EDOField options={EDOTypes} />}
        </>
    );
};
