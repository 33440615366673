import { Divider, ModalCenter } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";
import React from "react";
import DescriptionPreview from "./DescriptionPreview";
import CheckList from "./CheckList/CheckList";
import MapPreview from "./MapPreview";
import HeadlinePreview from "./HeadlinePreview";
import GalleryPreviewNew from "./GalleryPreviewNew";
import { EventPreviewNewModalProps } from "./types";

export const EventPreviewModalNew = ({ data, isOpened, onClose }: EventPreviewNewModalProps) => {
    const {
        title,
        eventType,
        workingDate,
        address,
        facilityPlaceTitle,
        images,
        description,
        coordinates,
        ageRestriction,
        workingTime,
        checkList,
    } = data;

    return (
        <ModalCenter isOpened={isOpened} onClose={onClose} modalTitle="Режим предпросмотра">
            <div className={styles.event}>
                <HeadlinePreview
                    title={title}
                    eventType={eventType}
                    workingDate={workingDate}
                    address={address}
                    facilityPlaceTitle={facilityPlaceTitle}
                    ageRestriction={ageRestriction}
                    workingTime={workingTime}
                />
                <GalleryPreviewNew images={images} />
                <Divider className={styles.divider} fullWidth />
                <DescriptionPreview description={description} />
                {checkList && <CheckList {...checkList} />}
                {facilityPlaceTitle && address && coordinates && coordinates.length > 0 && (
                    <MapPreview facilityPlaceTitle={facilityPlaceTitle} address={address} coordinates={coordinates} />
                )}
            </div>
        </ModalCenter>
    );
};
