import React from "react";
import StepOneForm from "./components/stepOne/form";
import useCompanyInfoForm from "./hooks/useCompanyInfoForm";
import FormManagerProvider from "./context/form-manager";

const CompanyInfo = () => {
    const {
        statusSigningOffer,
        handleSignOffer,
        isLoadingForm,
        onCloseOffer,
        objectData,
        handleValidateBankAccount,
        bankErrors,
    } = useCompanyInfoForm();

    return (
        <FormManagerProvider
            statusSigningOffer={statusSigningOffer}
            isLoadingForm={isLoadingForm}
            onCloseOffer={onCloseOffer}
        >
            <StepOneForm
                initialValues={objectData}
                handleSignOffer={handleSignOffer}
                onValidateBankAccount={handleValidateBankAccount}
                bankErrors={bankErrors}
            />
        </FormManagerProvider>
    );
};
export default CompanyInfo;
