import { Form } from "formik";
import { RegistrationAddressField } from "../fields/RegistrationAddressField";
import React from "react";
import { SignatoryFioField } from "../fields/SignatoryFioField";
import { PassportField } from "../fields/PassportField";
import { InnFileField } from "../fields/InnFileField";
import { DocumentTypeField } from "../fields/DocumentTypeField";
import { AuthorityFileField } from "../fields/AuthorityFileField";
import { GuideCertificate } from "../fields/GuideCertificate";
import { AccreditationArdFileField } from "../fields/AccreditationСardFileField";
import { SESertificateFileField } from "../fields/SESertificateFileField";
import BankDetails from "../fields/BankDetails";
import SignatoryRole from "../fields/SignatoryRole";
import AuthorityFields from "../fields/AuthorityFields";

const SEForm = () => {
    return (
        <div className="company-info__form content-wrapper__form">
            <Form>
                <div className="company-info__title">Основная информация</div>
                <SignatoryFioField />
                <SignatoryRole />
                <AuthorityFields />
                <DocumentTypeField />

                <div className="company-info__title">Паспортные данные</div>
                <PassportField />
                <RegistrationAddressField />

                <BankDetails />

                <InnFileField />
                <SESertificateFileField />

                <AuthorityFileField />
                <GuideCertificate />
                <AccreditationArdFileField />
            </Form>
        </div>
    );
};

export default SEForm;
