import { ACCEPT_FILES, FileTypeEnum, HINT_FILES } from "../../constants";
import { UploadFileField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { ReactComponent as IconAlertCircle } from "../../../../../../assets/images/icons/ic_alert_circle.svg";
import ValidityPeriodField from "./ValidityPeriodField";
import classNames from "classnames";

export const AccreditationArdFileField = () => {
    const { getFieldProps, setFieldValue, errors } = useFormikContext();

    const name = `filesData.${FileTypeEnum.GUIDE_ACCREDITATION}`;
    const value = getFieldProps(name).value;

    // @ts-ignore
    const isValidFile = !!value?.length && !errors?.filesData?.[FileTypeEnum.GUIDE_ACCREDITATION];

    return (
        <>
            {!!value?.length && <div className="company-info__alignment-wrap title mb-24">Аккредитация</div>}
            <div className={classNames("company-info__alignment-wrap mb-24", value && "fields-grid")}>
                <div>
                    <UploadFileField
                        name={name}
                        buttonTitle="Карточка аккредитации"
                        accept={ACCEPT_FILES}
                        files={value}
                        onDelete={() => {
                            setFieldValue(name, undefined);
                        }}
                        hint={HINT_FILES}
                    />
                    <p className="company-info__field-alert">
                        <IconAlertCircle /> <span>При наличии</span>
                    </p>
                </div>
                {isValidFile && (
                    <div className={"fields-grid__mt"}>
                        <ValidityPeriodField name={"guideAccreditationData.periodOfValidity"} />
                    </div>
                )}
            </div>
        </>
    );
};
