import React, { useMemo, useState, useCallback } from "react";
import history from "../../../history";
import { CommonLayout, StatusRequestEnum, UploadPhotosItemNew, useModalAlertContext } from "@russpass/partner-front-ui";
import {
    ExcursionHoldingSpace,
    navbarExcursionComment,
    NavbarExcursionNamesEnum,
    navbarExcursionPlace,
    navbarExcursionRatings,
} from "./helpers/constants";
import ExcursionInfo from "./components/excursion-info";
import useExcursion from "./hooks/useExcursion";
import ExcursionProgram from "./components/excursion-program";
import { useExursionActions } from "./hooks/useExursionActions";
import { useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { navbarServicesNames, navbarTypeEventNames, ServiceCategory } from "../all/constants";
import { Ratings } from "../components/ratings/ratings";
import ServiceComment from "../components/moderation-comment/service-comment";
import Tariffs from "../components/tariffs/tariffs";
import { ServiceTariffsContext } from "../components/tariffs/tariffs-context";
import useTariffs from "../components/tariffs/useTariffs";
import ActionsContainer from "../components/actions-container";
import PhotosNew from "../components/photosNew";
import ServicePlace from "../components/service-place";
import useFacility from "../components/service-place/useFacility";
import { NotificationProps } from "../../../components/notification";
import eventDispatcher from "../../../lib/event-dispatcher";
import { OnlineSaleSwitch } from "../components/OnlineSaleSwitch";
import { getExcursionEventPreviewData } from "./helpers/utils";
import { StatusNamesServiceItem } from "../all/types";
import { alertConfirmSaveText, alertNoBasicTariffText, backButtonText } from "../service/helpers/modalTexts";
import useUserInfo from "../../../hooks/useUserInfo";
import { ReactComponent as IcExternalLink } from "../../../assets/images/icons/ic_external_link.svg";
import { deleteDraft } from "../../../api/draft/draft";
import { EventPreviewModalNew } from "../components/EventPreviewModalNew";
import RouteLeavingGuard from "../../../components/RouteLeavingGuard/RouteLeavingGuard";
import { isEqualObjects } from "../../../utils/common";
import { deleteDraftImage } from "../components/photosNew/utils";
import { Tariff, TariffDates } from "../components/tariffs/types";
import _omit from "lodash/omit";

// TODO провести рефакторинг услуг
const Excursion = () => {
    const { isGuide, isConcluded, isNotGuideAttestation } = useUserInfo();

    const isNotValidGuide = isGuide && isNotGuideAttestation;

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    // TODO перенести в модалку превью
    const [isOpenPreviewWindow, setIsOpenPreviewWindow] = useState(false);
    const openExcursionPreviewWindow = useCallback(() => setIsOpenPreviewWindow(true), [setIsOpenPreviewWindow]);
    const closeExcursionPreviewWindow = useCallback(() => setIsOpenPreviewWindow(false), [setIsOpenPreviewWindow]);

    const [isEditMode, setEditMode] = useState(false);
    const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(true);
    const { openModalAlert } = useModalAlertContext();

    const {
        tariffs,
        initialTariffs,
        loadTariffList,
        onChangeTariffList,
        isLoadingTariffList,
        tariffDates,
        setTariffDates,
        initialTariffDates,
        currentBasicTariff,
        loadDraftTariffList,
    } = useTariffs();

    const {
        initialLoading,
        step,
        changeTab,
        excursionData,
        compareData,
        excursionForms,
        excursionViewOptions,
        onChangeData,
        reloadExcursionData,
        showcases,
        region,
        changeRegion,
        city,
        changeCity,
        isViewExcursion,
        holdingSpaceOptions,
        optionsNdsRate,
        hasChangesExceptBilling,
        hasBillingChanges,
        draftExcursionData,
        draftExcursionId,
        isArchive,
    } = useExcursion({ loadTariffList, loadDraftTariffList });

    const {
        facility,
        city: facilityCity,
        region: facilityRegion,
        selectedPartnerFacility,
        optionsPlaceTypes,
        optionsFacilityPlaces,
        isExistingPartnerFacility,
        onChangeFacility,
        onChangeCity,
        onChangeRegion,
        changePartnerFacility,
        reloadFacilityData,
        hasChangesFacility,
        isLoadingFacility,
    } = useFacility({
        callNotification,
        facilityId: excursionData?.facility,
        draftFacilityData: draftExcursionData?.facilityObject,
    });

    //TODO MOVE IN HOOK РАЗОБРАТЬСЯ
    const isShowServicePlace = useMemo(() => {
        const holdingSpace = holdingSpaceOptions.reduce(
            (space, currentSpace) =>
                !space && currentSpace.id === excursionData.holdingSpace && currentSpace.slug
                    ? currentSpace.slug
                    : space,
            ""
        );

        return holdingSpace === ExcursionHoldingSpace.mixed || holdingSpace === ExcursionHoldingSpace.indoor;
    }, [holdingSpaceOptions, excursionData.holdingSpace]);

    const reloadData = () => {
        reloadExcursionData();
        reloadFacilityData();
    };

    const isAllowPublic = useMemo(() => {
        return isArchive && excursionData.comment === "ранее опубликован, модерация не требуется";
    }, [isArchive, excursionData]);

    const {
        navbar,
        isSubmittingForm,
        sendExursionToModerate,
        sendExursionUpdate,
        isFormSending,
        setIsFormSending,
        isValidFacilityWorkingTime,
        addTariffForExistingExcursion,
        deleteTariffForExistingExcursion,
        updateTariffForExistingExcursion,
        addTariffForDraftExcursion,
        deleteTariffForDraftExcursion,
        updateTariffForDraftExcursion,
        updateExcursionFields,
        isDraftSave,
        sendToDraft,
    } = useExursionActions({
        data: excursionData,
        compareData,
        tariffs: tariffs.content,
        showcases,
        onChangeTariff: onChangeTariffList,
        facility,
        isExistingPartnerFacility,
        selectedPartnerFacility,
        isShowServicePlace,
        excursionViewDefaultId: excursionViewOptions[0]?.id,
        setEditMode,
        onChangeData,
        reloadData,
        isArchive,
    });

    const isShowValiditySwitcher = useMemo(
        () => isValidFacilityWorkingTime && facility.workingTime.length > 0 && isShowServicePlace,
        [facility.workingTime.length, isShowServicePlace, isValidFacilityWorkingTime]
    );

    const location = useLocation<{ status: string }>();

    const localNavbar = useMemo(() => {
        let _navbar = navbar;

        if (isViewExcursion) {
            _navbar = _navbar.concat(navbarExcursionRatings);
        }

        if (excursionData.isRejected) {
            _navbar = navbarExcursionComment.concat(_navbar);
        }

        const placeIndex = navbar.reduce(
            (index, navbarItem, currentIndex) =>
                navbarItem.name === NavbarExcursionNamesEnum.Place ? currentIndex : index,
            -1
        );

        if (isShowServicePlace) {
            if (placeIndex === -1) {
                const tariffsIndex = navbar.reduce(
                    (index, navbarItem, currentIndex) =>
                        navbarItem.name === NavbarExcursionNamesEnum.Tariffs ? currentIndex : index,
                    0
                );
                _navbar.splice(tariffsIndex + 1, 0, navbarExcursionPlace);
            }
        } else if (placeIndex !== -1) {
            _navbar.splice(placeIndex, 1);
        }

        return _navbar;
    }, [navbar, isViewExcursion, excursionData.isRejected, isShowServicePlace]);

    const back = () => {
        history.push({
            pathname: routes.servicesAll,
            state: { ...location?.state, type: navbarTypeEventNames.excursions },
        });
    };

    const isCreateMode = excursionData.status === StatusNamesServiceItem.Creating;
    const isViewMode = excursionData.status !== StatusNamesServiceItem.Creating;

    const hasBasicTariff = tariffs.content.length > 0 && tariffs.content.find((i) => i.isBasic);

    const isChangedForm = useMemo(() => {
        return (
            !isEqualObjects(_omit(excursionData, "price"), _omit(compareData, "price")) ||
            !isEqualObjects(tariffs, initialTariffs) ||
            hasChangesFacility
        );
    }, [excursionData, compareData, tariffs, initialTariffs, hasChangesFacility]);

    const submitTariffRegistrationDates = useCallback(
        async (tariffDates: TariffDates) => {
            if (!isFormSending) {
                const tariffRequests = tariffs.content
                    .filter((tariff: Tariff) => {
                        const activeSinceTime = new Date(tariff.activeSince);
                        if (tariff.openedSince) {
                            activeSinceTime.setHours(Number(tariff.openedSince.split(":")[0]));
                            activeSinceTime.setMinutes(Number(tariff.openedSince.split(":")[1]));
                            activeSinceTime.setSeconds(Number(tariff.openedSince.split(":")[2]));
                        }
                        const currentTime = new Date().getTime();
                        return activeSinceTime.getTime() > currentTime;
                    })
                    .map((tariff: Tariff) => {
                        updateTariffForExistingExcursion({ ...tariff, ...tariffDates }, false);
                    });

                await Promise.all(tariffRequests);
                setIsFormSending(false);
            }
        },
        [hasBasicTariff, isFormSending, tariffs, draftExcursionId]
    );

    const backButton = {
        title: (isChangedForm && (isCreateMode || draftExcursionId) && "Сохранить и выйти") || "Выйти",
        onClick: () => {
            if (!isChangedForm) {
                setIsVisibleConfirmModal(false);
                back();
                return;
            }
            if (isCreateMode || draftExcursionId) {
                sendToDraft(tariffDates, draftExcursionId)
                    .then(() => {
                        setIsVisibleConfirmModal(false);
                        history.push({
                            pathname: routes.servicesAll,
                            state: { status: navbarServicesNames.draft },
                        });
                    })
                    .catch(() => {});
            } else {
                back();
            }
        },
    };

    const tariffsProviderValue = useMemo(
        () => ({
            tariffs,
            loadTariffList: draftExcursionId ? loadDraftTariffList : loadTariffList,
            billingProductId: excursionData.billingProductId,
            onChangeTariffList,
        }),
        [
            excursionData.billingProductId,
            loadTariffList,
            loadDraftTariffList,
            draftExcursionId,
            onChangeTariffList,
            tariffs,
        ]
    );

    const onChangeImage = useCallback(
        (values: UploadPhotosItemNew[]) => onChangeData({ ...excursionData, imagesFiles: values }),
        [excursionData, onChangeData]
    );

    const alertNoBasicTariff = () => {
        changeTab(NavbarExcursionNamesEnum.Tariffs);
        openModalAlert(alertNoBasicTariffText, () => {});
    };

    const alertConfirmSave = () => {
        openModalAlert(
            alertConfirmSaveText,
            () => sendExursionUpdate(hasBillingChanges, hasChangesExceptBilling),
            () => {}
        );
    };

    const currentTabIndex = localNavbar.findIndex((tab) => tab.name === step);
    const isLastTab = step === localNavbar[localNavbar.length - 1].name;
    const isTariffsTab = useMemo(() => step === NavbarExcursionNamesEnum.Tariffs, [step]);

    const actionsObj = {
        notAction: {
            text: "",
            action: undefined,
        },
        setEdit: {
            text: "Редактировать",
            action: () => setEditMode(true),
        },
        sendModerate: {
            text: "Отправить на модерацию",
            action: async () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                if (!isFormSending) {
                    setIsVisibleConfirmModal(false);
                    sendExursionToModerate(tariffDates, draftExcursionId)
                        .then(async () => {
                            if (draftExcursionId) {
                                try {
                                    await deleteDraft(draftExcursionId, ServiceCategory.Excursions);
                                    deleteDraftImage(excursionData.imagesFiles);
                                    excursionData.route?.map(async (routeItem) => {
                                        routeItem.events.map(async (event) => {
                                            deleteDraftImage(event.imageFiles || []);
                                        });
                                    });
                                } catch (err) {
                                    console.error(err);
                                    callNotification({
                                        status: StatusRequestEnum.Error,
                                        title: `Не удалось удалить черновик. Обратитесь в службу поддержки`,
                                    });
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setIsVisibleConfirmModal(true);
                        });
                }
            },
        },
        next: {
            text: "Далее",
            action: () => {
                if (step === NavbarExcursionNamesEnum.Tariffs && !hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                changeTab(localNavbar[currentTabIndex + 1].name);
            },
        },
        save: {
            text: "Сохранить",
            action: () => (hasBillingChanges ? alertConfirmSave() : setEditMode(false)),
        },
        sendExursionUpdate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                if (!isFormSending) {
                    setIsVisibleConfirmModal(false);
                    sendExursionUpdate(hasBillingChanges, hasChangesExceptBilling, undefined, isArchive);
                }
            },
        },
        sendPublish: {
            text: "Опубликовать",
            action: () => {
                setIsVisibleConfirmModal(false);
                sendExursionUpdate(false, true, false);
            },
        },
        saveTariffs: {
            text: "Сохранить",
            action: () => submitTariffRegistrationDates(tariffDates),
        },
    };

    const isUnchangedRegistrationDates = useMemo(
        () =>
            isTariffsTab &&
            initialTariffDates.registrationOnDateByTime === tariffDates.registrationOnDateByTime &&
            initialTariffDates.registrationOffDateByTime === tariffDates.registrationOffDateByTime,
        [tariffDates, initialTariffDates, isTariffsTab]
    );

    const nextButton = () => {
        // если сохраняем поля продаж для тарифов
        if (isTariffsTab && !isCreateMode) {
            return actionsObj["saveTariffs"];
        }
        // при просмотре архива
        if (isArchive && !isEditMode && isAllowPublic && isConcluded) {
            return actionsObj["sendPublish"];
        }
        // при обычном просмотре
        if (isViewMode && !isEditMode) {
            return actionsObj["setEdit"];
        }
        //при создании
        if (!isViewMode && !isEditMode && isConcluded) {
            return isLastTab ? actionsObj["sendModerate"] : actionsObj["next"];
        }
        //при редактировании
        if (isEditMode && isConcluded) {
            if (draftExcursionId) {
                return isLastTab ? actionsObj["sendModerate"] : actionsObj["next"];
            }
            // если изменили все остальные любые поля
            if (hasChangesExceptBilling || hasChangesFacility) {
                return actionsObj["sendExursionUpdate"];
            }
            // если изменили поля билинга
            if (!hasChangesExceptBilling) {
                return actionsObj["save"];
            }
            // по стандарту
            return actionsObj["save"];
        }

        if (!isConcluded && !isLastTab && ((isEditMode && draftExcursionId) || (!isViewMode && !isEditMode))) {
            return actionsObj["next"];
        }

        return actionsObj["notAction"];
    };

    const handleToggleIsCanBuySwitch = async () => {
        if (isViewExcursion) {
            await updateExcursionFields({
                isCanBuy: !excursionData.isCanBuy,
            });
        } else {
            onChangeData({ ...excursionData, isCanBuy: !excursionData.isCanBuy });
            callNotification({
                status: StatusRequestEnum.Success,
                body: excursionData.isCanBuy
                    ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                    : "Продажа на RUSSPASS включена",
            });
        }
    };

    const hasTariffs = tariffs.content.length > 0;

    const isVisibleActions = useMemo(() => {
        return isEditMode ? true : !initialLoading && !isOpenPreviewWindow && !isUnchangedRegistrationDates;
    }, [initialLoading, isEditMode, isOpenPreviewWindow, isUnchangedRegistrationDates]);

    const disabledFields = isViewMode && !isEditMode;

    const isDisabledFacilityForm = useMemo(() => {
        if (draftExcursionId && isEditMode) {
            return false;
        }
        if (isCreateMode) {
            return facility.cmsFacilityId && facility.cmsFacilityId === selectedPartnerFacility?.cmsFacilityId;
        }
        if (isViewMode) {
            if (isEditMode) {
                if (selectedPartnerFacility?.cmsFacilityId && isGuide) {
                    return !selectedPartnerFacility?.owner;
                }
                return false;
            }
            return true;
        }
    }, [isCreateMode, isViewMode, isEditMode, isGuide, facility, selectedPartnerFacility]);

    const topRightButton = useMemo(() => {
        if (isNotValidGuide) {
            return undefined;
        }
        if (excursionData.status === StatusNamesServiceItem.Active) {
            return {
                title: (
                    <>
                        Открыть на RUSSPASS <IcExternalLink className="button__icon button__icon--right" />
                    </>
                ),
                onClick: () => {
                    window.open(`${excursionData?.url}`);
                },
                iconMobile: <IcExternalLink className="button__icon button__icon--right" />,
            };
        }
    }, [excursionData, isNotValidGuide]);

    const isShowError = useMemo(() => isSubmittingForm || isDraftSave, [isSubmittingForm, isDraftSave]);

    return (
        <ServiceTariffsContext.Provider value={tariffsProviderValue}>
            <CommonLayout
                pageTitle={excursionData.title || "Новая экскурсия"}
                backButton={backButton}
                activeTabName={step}
                handlerChangeActiveTab={changeTab}
                isLoadingWithoutContent={initialLoading}
                isLoadingWithContent={isFormSending || isLoadingTariffList || isLoadingFacility}
                navbar={localNavbar}
                fullHeight={step === NavbarExcursionNamesEnum.Ratings}
                isHideControlPanel
                topRightButton={topRightButton}
            >
                <div data-name={NavbarExcursionNamesEnum.Excursion}>
                    <ExcursionInfo
                        data={excursionData}
                        forms={excursionForms}
                        excursionViewOptions={excursionViewOptions}
                        onChange={onChangeData}
                        region={region}
                        onChangeRegion={changeRegion}
                        city={city}
                        onChangeCity={changeCity}
                        onSubmit={changeTab}
                        isSendToModerate={isSubmittingForm}
                        isShowErrors={isShowError}
                        disabled={disabledFields}
                        holdingSpaceOptions={holdingSpaceOptions}
                        optionsNdsRate={optionsNdsRate}
                        isDisabledExcursionView={draftExcursionId ? !isEditMode : !isCreateMode}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Photos}>
                    <PhotosNew
                        items={excursionData.imagesFiles}
                        disabled={disabledFields}
                        onChange={onChangeImage}
                        isShowError={isSubmittingForm}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Place}>
                    <ServicePlace
                        facility={facility}
                        isDraft={draftExcursionId}
                        isDisabledChooserFacility={disabledFields}
                        isDisabledFacilityForm={isDisabledFacilityForm || undefined}
                        optionsPlaceTypes={optionsPlaceTypes}
                        onChangeFacility={onChangeFacility}
                        isSendToModerate={isSubmittingForm}
                        isShowErrors={isShowError}
                        region={facilityRegion}
                        onChangeRegion={onChangeRegion}
                        city={facilityCity}
                        isShowChooser={draftExcursionId ? isEditMode : true}
                        onChangeCity={onChangeCity}
                        optionsFacilityPlaces={optionsFacilityPlaces}
                        selectedPartnerFacility={selectedPartnerFacility}
                        changePartnerFacility={changePartnerFacility}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Program}>
                    <ExcursionProgram
                        data={excursionData}
                        onChange={onChangeData}
                        onSubmit={changeTab}
                        isSendToModerate={isSubmittingForm}
                        isShowErrors={isShowError}
                        disabled={disabledFields}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Tariffs}>
                    {hasTariffs && (
                        <OnlineSaleSwitch
                            onToggle={handleToggleIsCanBuySwitch}
                            value={excursionData.isCanBuy}
                            disabled={isNotValidGuide}
                        />
                    )}

                    <Tariffs
                        isExistingService={!isCreateMode && !draftExcursionId}
                        addTariffForExistingService={
                            draftExcursionId ? addTariffForDraftExcursion : addTariffForExistingExcursion
                        }
                        deleteTariffForExistingService={
                            draftExcursionId ? deleteTariffForDraftExcursion : deleteTariffForExistingExcursion
                        }
                        updateTariffForExistingService={
                            draftExcursionId ? updateTariffForDraftExcursion : updateTariffForExistingExcursion
                        }
                        isShowValiditySwitcher={isShowValiditySwitcher}
                        facilityWorkingTime={facility.workingTime}
                        updateDataField={(val) => updateExcursionFields({ price: val })}
                        isDisplayRegistration={hasTariffs}
                        currentBasicTariff={currentBasicTariff}
                        tariffDates={tariffDates}
                        setTariffDates={setTariffDates}
                        disabled={isNotValidGuide}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Ratings}>
                    <Ratings id={excursionData.cmsExcursionId} />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Comment} data-hide={!excursionData.isRejected}>
                    <ServiceComment reasons={[excursionData.rejectionReason]} />
                </div>
            </CommonLayout>

            <>
                {isVisibleActions && !isNotValidGuide && (
                    <ActionsContainer
                        onClickNextButton={nextButton().action}
                        nextButtonText={nextButton().text}
                        onClickPreviewButton={openExcursionPreviewWindow}
                        showPreviwButton={isCreateMode || isEditMode || (isViewMode && isTariffsTab)}
                        showSecondButton={!isEditMode && isArchive && isAllowPublic}
                        secondButtonText={actionsObj["setEdit"].text}
                        onClickSecondButton={actionsObj["setEdit"].action}
                    />
                )}
            </>
            <RouteLeavingGuard
                when={isVisibleConfirmModal && (isCreateMode || draftExcursionId)}
                navigate={(path) => history.push(path)}
                details={{
                    title: "Сохранить услугу в Черновике?",
                    OKButton: "Сохранить",
                    cancelButton: "Выйти без сохранения",
                }}
                shouldBlockNavigation={(nextLocation) => {
                    return isChangedForm && nextLocation.pathname !== location.pathname;
                }}
                onOKButtonClickAction={async () => {
                    try {
                        await sendToDraft(tariffDates, draftExcursionId);
                    } catch (err) {
                        return Promise.reject(new Error(err));
                    }
                }}
                isNavigateOnclickCancel={true}
            />

            <RouteLeavingGuard
                when={isVisibleConfirmModal && !isCreateMode && !draftExcursionId}
                navigate={(path) => history.push(path)}
                details={{
                    title: "При выходе внесенные изменения не сохранятся",
                    description: "Чтобы сохранить их, вернитесь к редактированию и отправьте данные на модерацию",
                    OKButton: "Вернуться к редактированию",
                    cancelButton: "Выйти без сохранения",
                }}
                shouldBlockNavigation={(nextLocation) => {
                    return isChangedForm && nextLocation.pathname !== location.pathname;
                }}
                onOKButtonClickAction={async () => {
                    return Promise.reject();
                }}
                isNavigateOnclickCancel={true}
            />

            <EventPreviewModalNew
                isOpened={isOpenPreviewWindow}
                onClose={closeExcursionPreviewWindow}
                data={getExcursionEventPreviewData(excursionData, tariffs.content)}
            />
        </ServiceTariffsContext.Provider>
    );
};

export default Excursion;
