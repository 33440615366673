import { array as arrayYup, object as objectYup, string as stringYup } from "yup";
import {
    FORM_FIELD_ERROR,
    FORM_FIELD_REQUIRED_ERROR,
    FORM_MIN_ERROR,
    FORMAT_ERROR,
} from "../../../../../constants/errors";
import { emailRegexp, phoneRegexp, timeRegexp } from "../../../../../utils/validation";
import { DESC_FIELD_MIN_LENGTH } from "../../../../../constants/inputs";

export const hotelInfoValidation = (isTravelLineHotel?: boolean) => {
    return objectYup().shape({
        title: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        housingType: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        description: stringYup().required(FORM_FIELD_REQUIRED_ERROR).min(DESC_FIELD_MIN_LENGTH, FORM_MIN_ERROR),
        address: objectYup({
            unrestricted_value: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        }),
        region: objectYup({
            id: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        }),
        hotelType: objectYup({
            id: stringYup(),
        })
            .nullable(true)
            .test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
                return isTravelLineHotel ? !!value : true;
            }),
        timeZone: stringYup()
            .test("test", FORM_FIELD_REQUIRED_ERROR, function () {
                let { region, timeZone } = this.parent;
                return region.id && region.timeZone.length === 0 ? true : !!timeZone;
            })
            .test("test", "Не получена временная зона региона. Просьба обратиться в Поддержку", function () {
                let { region } = this.parent;
                return region.id && region.timeZone.length !== 0;
            }),
        city: objectYup({
            id: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
        }),
        email: stringYup()
            .matches(emailRegexp, {
                message: FORM_FIELD_ERROR,
            })
            .required(FORM_FIELD_REQUIRED_ERROR),
        stars: stringYup().nullable(true).required(FORM_FIELD_REQUIRED_ERROR),
        phones: arrayYup()
            .of(
                objectYup().shape({
                    name: stringYup().required(FORM_FIELD_REQUIRED_ERROR),
                    number: stringYup().required(FORM_FIELD_REQUIRED_ERROR).matches(phoneRegexp, {
                        message: FORMAT_ERROR,
                    }),
                })
            )
            .required(FORM_FIELD_REQUIRED_ERROR)
            .min(1, FORM_FIELD_REQUIRED_ERROR),
        services: arrayYup().test("test", FORM_FIELD_REQUIRED_ERROR, function (value) {
            let { commonServices, beachServices, entertainmentServices, fitnessServices, facilityServices } =
                this.parent;
            return (
                commonServices.length ||
                beachServices.length ||
                entertainmentServices.length ||
                fitnessServices.length ||
                facilityServices.length
            );
        }),
        imageExplorePreview: arrayYup().min(1, FORM_FIELD_REQUIRED_ERROR),
        arrivalTime: stringYup()
            .required(FORM_FIELD_REQUIRED_ERROR)
            .matches(timeRegexp, {
                message: FORMAT_ERROR,
            })
            .nullable(true),
        departureTime: stringYup()
            .required(FORM_FIELD_REQUIRED_ERROR)
            .matches(timeRegexp, {
                message: FORMAT_ERROR,
            })
            .nullable(true),
    });
};
