import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import { maskedInputNumbers } from "../../../../../../utils/validation";

export const BankTreasuryAccountField = () => (
    <InputField
        label="Казначейский счет"
        name={"bank.treasuryAccount"}
        noHover
        placeholder="30101810400000000225"
        hint="При наличии"
        errorSettings={{ showCustomError: true }}
        renderComponent={maskedInputNumbers}
        maxLength={20}
    />
);
