import { StatusNamesServiceItem } from "../../all/types";
import { PaymentTypeEnum } from "../../enums";
import { NewServiceData } from "./types";

export const INITIAL_SERVICE_DATA: NewServiceData = {
    id: "",
    title: "",
    facility: "",
    status: StatusNamesServiceItem.Creating,
    eventType: "",
    description: "",
    region: "",
    city: "",
    ticketPrice: "",
    duration: "",
    ageRestriction: "",
    ndsPercent: "",
    images: [],
    imageExplorePreview: [],
    imageDetailedPageMain: [],
    imagesFiles: [],
    paymentType: PaymentTypeEnum.SingleStage,
    personalizedTickets: false,
    isCanBuy: true,
    seasonUnlimited: false,
    seasonStart: "",
    seasonEnd: "",
    cmsEventId: "",
    partner: "",
    isRejected: false,
};

export let optionsNDS = [
    {
        id: "0",
        mdmId: "",
        name: "Включен",
    },
    {
        id: "1",
        mdmId: "",
        name: "Не включен",
    },
];

export let optionsAge = [
    {
        id: "1",
        mdmId: "1",
        name: "6+",
        serviceId: "1",
    },
    {
        id: "2",
        mdmId: "2",
        name: "12+",
        serviceId: "2",
    },
];

export const eventTypes = [
    {
        id: "1",
        mdmId: "1",
        name: "Арт",
        serviceId: "1",
    },
    {
        id: "2",
        mdmId: "",
        name: "Вечеринки",
        serviceId: "",
    },
    {
        id: "3",
        mdmId: "",
        name: "Встречи",
        serviceId: "",
    },
    {
        id: "4",
        mdmId: "",
        name: "Выставки",
        serviceId: "",
    },
    {
        id: "5",
        mdmId: "",
        name: "Достопримечательности",
        serviceId: "",
    },
    {
        id: "6",
        mdmId: "",
        name: "Еда",
        serviceId: "",
    },
    {
        id: "7",
        mdmId: "",
        name: "Квест",
        serviceId: "",
    },
    {
        id: "8",
        mdmId: "",
        name: "Кино",
        serviceId: "",
    },
    {
        id: "9",
        mdmId: "",
        name: "Концерты",
        serviceId: "",
    },
    {
        id: "10",
        mdmId: "",
        name: "Лекции",
        serviceId: "",
    },
    {
        id: "11",
        mdmId: "",
        name: "Мастер-классы",
        serviceId: "",
    },
    {
        id: "12",
        mdmId: "",
        name: "Музеи",
        serviceId: "",
    },
    {
        id: "13",
        mdmId: "",
        name: "Парки",
        serviceId: "",
    },
];

export let newTariffData = {
    id: null,
    tariffCaption: [
        {
            content: "",
            deleted: 0,
            language: "ru",
        },
    ],
    tariffNote: [
        {
            content: "",
            deleted: 0,
            language: "ru",
        },
    ],
    itemType: [
        {
            id: null,
        },
    ],
    caption: [],
    description: [],
    promos: [],
    itemPrices: [
        {
            currency: {
                id: "110",
                symbol: "RUB",
                code: "RUB",
                countryCode: "RU",
            },
            price: "",
            agentPrice: null,
        },
    ],
    isChild: false,
    discounts: [],
    exclusiveCalendars: [],
    dependencies: [],
    itemBenefitType: {
        id: 1,
        caption: [],
        partnerInfo: null,
        _caption: "Взрослый",
    },
    workingDays: [],
    activeSince: "",
    activeUntil: "",
    openedSince: "",
    openedUntil: "",
    deleted: 0,
};
