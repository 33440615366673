import routes from "../../../constants/routes";

export const emptyListDescriptionActivity = "У вас пока нет добавленных услуг";
export const emptyListDescriptionGuide = "В этом разделе пока нет экскурсий";
export const emptyListSubtitleGuide = (
    <>
        Для их добавления заполните сначала{" "}
        <a className="link" href={`${routes.profile}`} style={{ display: "inline-block" }}>
            Профиль
        </a>
    </>
);
export const emptyCategoryTextActivity = "В этом разделе пока нет услуг";
export const textOnClickAddActivity = "Добавить услугу";
export const textOnClickAddExcursion = "Добавить экскурсию";

export const navbarServicesNames = {
    all: "ALL",
    draft: "DRAFT",
    moderation: "MODERATION",
    published: "PUBLISHED",
    refused: "DECLINED",
    archive: "ARCHIVED",
};

export const navbarServices = [
    {
        label: "Все",
        name: navbarServicesNames.all,
    },
    {
        label: "Черновик",
        name: navbarServicesNames.draft,
    },
    {
        label: "На модерации",
        name: navbarServicesNames.moderation,
    },
    {
        label: "Опубликовано",
        name: navbarServicesNames.published,
    },
    {
        label: "Отказано в публикации",
        name: navbarServicesNames.refused,
    },
    {
        label: "Архив",
        name: navbarServicesNames.archive,
    },
];

export const navbarTypeEventNames = {
    all: 0,
    events: 1,
    excursions: 2,
    tours: 3,
};

export const categoryLabel = {
    all: "Все услуги",
    events: "Событие",
    excursions: "Экскурсия",
    tours: "Тур",
};

export const navbarTypeTour = {
    label: categoryLabel.tours,
    name: navbarTypeEventNames.tours,
};

export const navbarTypeEvent = [
    {
        label: categoryLabel.all,
        name: navbarTypeEventNames.all,
    },
    {
        label: categoryLabel.events,
        name: navbarTypeEventNames.events,
    },
    {
        label: categoryLabel.excursions,
        name: navbarTypeEventNames.excursions,
    },
];

export const statusLabelsServices = {
    draft: "Черновик",
    moderation: "На модерации",
    published: null,
    refused: "Отказано",
    archive: "В архиве",
};

export const statusIdsServiceItem = {
    draft: 1,
    create: 2,
    moderation: 3,
    published: 4,
    refused: 5,
};

export const creationDt = "creationDt";

export enum ServiceCategory {
    Services = "services",
    Excursions = "excursions",
    Tours = "tours",
}
