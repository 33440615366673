import useUserInfo from "../../../../hooks/useUserInfo";
import CompanyInfo from "../../offer-deprecated/company-info";
import CompanyInfoNew from "./index";

const OfferFormWrap = () => {
    const { isHotel, isApartment } = useUserInfo();

    if (isHotel || isApartment) {
        return <CompanyInfo />;
    }
    return <CompanyInfoNew />;
};

export default OfferFormWrap;
