import { FORM_FIELD_REQUIRED_ERROR, FORM_MIN_ERROR } from "../../../../../constants/errors";
import { DESC_FIELD_MIN_LENGTH } from "../../../../../constants/inputs";
import { yup } from "../../../../../yup";

export const objectStepSchema = yup.object().shape({
    name: yup.string().required().max(100),
    description: yup.string().required(FORM_FIELD_REQUIRED_ERROR).min(DESC_FIELD_MIN_LENGTH, FORM_MIN_ERROR),
    type: yup.string().required(),
    area: yup.number().required().min(1).max(9999),
    amenities: yup.array().required(),
    region: yup.string().required(),
    city: yup.string().required(),
    timeZone: yup.string().required(),
    address: yup.object().shape({
        unrestricted_value: yup.string().required(),
    }),
});

export const objectStepDraftSchema = yup.object().shape({
    name: yup.string().required().max(100),
});
