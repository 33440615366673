import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import { maskedInputNumbers } from "../../../../../../utils/validation";
import { useBankDetailsContext } from "../context/BankDetailsContext";
import { useFormikContext } from "formik";

export const BankCorrAccountField = () => {
    const { handleValidateBankAccount, bankErrors } = useBankDetailsContext();
    const { getFieldProps } = useFormikContext();

    const bank = getFieldProps("bank").value;

    return (
        <InputField
            label="Корреспондентский счет"
            name={"bank.corrAccount"}
            noHover
            placeholder="30101810400000000225"
            onValidate={(e) => {
                handleValidateBankAccount(
                    {
                        ...bank,
                        checkingAccount: e.target.value,
                    },
                    "checkingAccount"
                );
            }}
            errorSettings={{ showCustomError: true }}
            error={bankErrors?.corrAccount}
            renderComponent={maskedInputNumbers}
            maxLength={20}
        />
    );
};
