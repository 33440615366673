import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FeedbackNavbar, FeedbackStatus } from "./constants";
import { FeedbackFilters } from "./components/filters/FeedbackFilters";
import { FeedbackFiltersModel } from "./types/FeedbackFiltersModel";
import { FeedbackModal } from "./components/modal/FeedbackModal";
import { FeedbackFormAction } from "./constants/FeedbackFormAction";
import eventDispatcher from "../../lib/event-dispatcher";
import { FeedbackSort } from "./constants/FeedbackSort";
import { StatusRequestEnum, DataGrid, CommonLayout } from "@russpass/partner-front-ui";
import routes from "../../constants/routes";

import "./styles.sass";
import { useRequest } from "../../hooks/useRequest";
import { getFeedbackList } from "../../api/feedback";
import getDataGridFeedBackColumns from "./utils/getDataGridFeedBackColumns";
import { declensionOfNum } from "../../utils/declension-number";
import getCustomErrorMessage from "../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../constants/errors";
import useUserInfo from "../../hooks/useUserInfo";
import AttestationMessage from "../../components/AttestationMessage";

type FeedbackItemRouteParams = {
    id: string;
};

const Feedback = () => {
    const history = useHistory();
    const itemRouteMatch = useRouteMatch<FeedbackItemRouteParams>(routes.feedbackItem);
    const selectedItemId = useMemo(() => parseInt(itemRouteMatch?.params.id || "", 10) || undefined, [itemRouteMatch]);

    const [listKey, setListKey] = useState(Date.now());
    const [filters, setFilters] = useState<FeedbackFiltersModel>({
        status: FeedbackStatus.New,
        sort: FeedbackSort.CreateDateDesc,
    });

    const [page, setPage] = useState(1);

    const handlePageChange = useCallback((page: number) => setPage(page + 1), []);

    const handleChangeFilters = useCallback(
        (filters: FeedbackFiltersModel) => {
            setPage(1);
            setFilters(filters);
        },
        [setFilters, setPage]
    );

    const handleTabChange = useCallback(
        (status?: string | number) => handleChangeFilters({ ...filters, status: status as FeedbackStatus }),
        [handleChangeFilters, filters]
    );

    const handleItemClick = useCallback(
        (id: number) => history.push(routes.feedbackItem.replace(":id", `${id}`)),
        [history]
    );

    const handleModalClose = useCallback(
        (action?: FeedbackFormAction, publishAtPortal?: boolean) => {
            history.push(routes.feedback);

            if (!action) return;

            setListKey(Date.now());

            eventDispatcher.updateCounter({ route: routes.feedback });
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Success,
                body: getSuccessFormMessage(action, publishAtPortal),
            });
        },
        [history]
    );

    const {
        result: response,
        loading,
        error,
        refresh,
    } = useRequest(async () => (await getFeedbackList({ ...filters, page }))?.data, [page, filters]);

    const isEmpty = useMemo(
        () => !response?.items?.length && !filters.externalEventId,
        [filters.externalEventId, response?.items?.length]
    );

    const total = useMemo(() => response?.total || 0, [response]);

    const totalPages = useMemo(() => (response ? Math.ceil(response.total / response.pageSize) : 0), [response]);

    useEffect(() => {
        if (!error) return;

        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: getCustomErrorMessage(error, COMMON_RELOAD_ERROR),
        });
    }, [error]);

    const { isHotel } = useUserInfo();

    const getSuccessFormMessage = useCallback(
        (action: FeedbackFormAction, publishAtPortal?: boolean) => {
            const pageView = isHotel ? "объекта" : "услуги";

            switch (action) {
                case FeedbackFormAction.Publish:
                    return `Ответ опубликован на странице ${pageView}`;

                case FeedbackFormAction.Unpublish:
                    return `Вопрос убран со страницы ${pageView}`;

                case FeedbackFormAction.Submit:
                    return `Вопрос перемещен в раздел «Отвеченные»${
                        publishAtPortal ? ` и опубликован на странице ${pageView}` : ""
                    }`;
            }
        },
        [isHotel]
    );

    return (
        <CommonLayout pageTitle="Вопросы туристов" navbar={FeedbackNavbar} handlerChangeActiveTab={handleTabChange}>
            <>{filters.status === FeedbackStatus.New && <AttestationMessage />}</>
            <DataGrid
                key={listKey}
                isLoading={loading}
                isEmpty={isEmpty}
                dataGridSetting={{
                    data: response?.items || [],
                    columns: getDataGridFeedBackColumns(),
                    total: `Всего ${total} ${declensionOfNum(total, ["вопрос", "вопроса", "вопросов"])}`,
                    onSelect: (value) => handleItemClick(value.id as number),
                }}
                emptySettings={{ title: "Нет вопросов" }}
                paginate={{
                    page: page - 1,
                    totalPages,
                    onPageChange: handlePageChange,
                }}
                filters={<FeedbackFilters value={filters} onChange={handleChangeFilters} />}
            />
            <FeedbackModal id={selectedItemId} onClose={handleModalClose} refresh={refresh} />
        </CommonLayout>
    );
};

export default Feedback;
