import { OfferNew, OfferNewForm } from "./OfferTypeForms/types";
import { SignatoryRoleEnum } from "../../../../enums/signatory-role";
import { FORMAT_DROPZONE_FOR_IMAGE } from "@russpass/partner-front-ui";

export enum FileTypeEnum {
    VICARIOUS_AUTHORITY = "VICARIOUS_AUTHORITY", // (доверенность)
    INN = "INN", //(ИНН)
    OGRN = "OGRN", // (ОГРН)
    EGRN = "EGRN", // (ЕГРН)
    SELF_EMPLOYED_STATEMENT = "SELF_EMPLOYED_STATEMENT", // (Справка о самозанятости)
    GUIDE_CERTIFICATE = "GUIDE_CERTIFICATE", // (Аттестат экскурсовода)
    GUIDE_ACCREDITATION = "GUIDE_ACCREDITATION", // (Аккредитация экскурсовода)
}

export enum EdoDocumentTypeEnum {
    ELECTRONIC = "electronic",
    PAPER = "paper",
}

export enum SignLegalBasisEnum {
    ORGANIZATION_CHART = "Устав",
    VICARIOUS_AUTHORITY = "Доверенность",
}

export const IPSignatoryRoleOptions = [
    {
        code: SignatoryRoleEnum.OWNER,
        caption: "Собственник бизнеса",
    },
    {
        code: SignatoryRoleEnum.AGENT,
        caption: "Доверенное лицо",
        hint: "Обязательна нотариально заверенная доверенность",
    },
];

export const SignLegalBasisOptions = [
    {
        label: SignLegalBasisEnum.ORGANIZATION_CHART,
        value: SignLegalBasisEnum.ORGANIZATION_CHART,
    },
    {
        label: SignLegalBasisEnum.VICARIOUS_AUTHORITY,
        value: SignLegalBasisEnum.VICARIOUS_AUTHORITY,
    },
];

export const ACCEPT_FILES = {
    [FORMAT_DROPZONE_FOR_IMAGE]: [".jpeg", ".pdf", ".jpg"],
};

export const HINT_FILES = "до 5 мб в формате jpg, pdf";

export const FieldNames = {
    signatoryRole: "signatoryRole",
    signLegalBasis: "signLegalBasis",
    authority: "authority",
};

export const DocumentTypes = [
    {
        label: "Бумажный",
        value: EdoDocumentTypeEnum.PAPER,
    },
    {
        label: "ЭДО",
        value: EdoDocumentTypeEnum.ELECTRONIC,
    },
];

export const authority = {
    number: "",
    term: "",
    dateIssue: "",
};

export const OfferNewData: OfferNew = {
    signatory: "",
    identityNumber: "",
    registrationAddress: {},
    signatoryRole: undefined,
    position: "",
    signLegalBasis: undefined,
    documentType: undefined,
    electronicType: undefined,
    ndsRate: "",
    bank: {
        bic: null,
        name: null,
        corrAccount: null,
        treasuryAccount: null,
        checkingAccount: null,
    },
};

export const offerFormInitialData: OfferNewForm = {
    ...OfferNewData,
    filesData: {
        INN: [],
    },
};
