import { ACCEPT_FILES, FileTypeEnum, HINT_FILES } from "../../constants";
import { UploadFileField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { ReactComponent as IconAlertCircle } from "../../../../../../assets/images/icons/ic_alert_circle.svg";

export const SESertificateFileField = () => {
    const { getFieldProps, setFieldValue } = useFormikContext();
    const name = `filesData.${FileTypeEnum.SELF_EMPLOYED_STATEMENT}`;
    return (
        <div className="company-info__alignment-wrap mb-24">
            <UploadFileField
                name={name}
                buttonTitle="Справка о самозанятости"
                accept={ACCEPT_FILES}
                files={getFieldProps(name).value}
                onDelete={() => {
                    setFieldValue(name, []);
                }}
                hint={HINT_FILES}
            />
            <p className="company-info__field-alert">
                <IconAlertCircle /> <span>Документ можно сформировать на сайте или в приложении «Мой налог»</span>
            </p>
        </div>
    );
};
