import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { IBank, IBankField } from "../../../../../../types/profile";
import { validateBankAccount } from "../../../../offer-deprecated/company-info/utils";
import useUserInfo from "../../../../../../hooks/useUserInfo";

export type BankErrors = {
    corrAccount?: string;
    checkingAccount?: string;
};

const useCheckBank = () => {
    const { userFullInfo } = useUserInfo();

    const [bankErrors, setBankErrors] = useState<BankErrors>({});

    const handleValidateBankAccount = useCallback(
        debounce(async (bank: IBank, fieldType: IBankField) => {
            if (userFullInfo && bank) {
                setBankErrors({ ...bankErrors, [fieldType]: undefined });
                const errorData = await validateBankAccount({
                    partnerId: userFullInfo.applicationId,
                    bank,
                    fieldType,
                });
                if (errorData) {
                    setBankErrors(errorData);
                }
            }
        }, 300),
        [userFullInfo]
    );

    return {
        handleValidateBankAccount,
        bankErrors,
    };
};

export default useCheckBank;
