import { Form } from "formik";
import { SignatoryFioField } from "../fields/SignatoryFioField";
import { NdsField } from "../fields/NdsField";
import { DocumentTypeField } from "../fields/DocumentTypeField";
import { InnFileField } from "../fields/InnFileField";
import { AuthorityFileField } from "../fields/AuthorityFileField";
import React from "react";
import { SignatoryPositionField } from "../fields/SignatoryPositionField";
import { SignLegalBasisField } from "../fields/SignLegalBasisField";
import AuthorityFields from "../fields/AuthorityFields";
import { BankTreasuryAccountField } from "../fields/BankTreasuryAccountField";
import { BudgetOrganizationCodeField } from "../fields/BudgetOrganizationCodeField";
import { OKTMOField } from "../fields/OKTMOField";
import BankDetails from "../fields/BankDetails";
import useUserInfo from "../../../../../../hooks/useUserInfo";

const ULForm = () => {
    const { isDisplayObject } = useUserInfo();
    return (
        <div className="company-info__form content-wrapper__form">
            <Form>
                <div className="company-info__title">Основная информация</div>
                <SignatoryFioField />
                <SignatoryPositionField />
                <SignLegalBasisField />
                <AuthorityFields />
                {isDisplayObject && <NdsField />}
                {isDisplayObject && <DocumentTypeField />}

                {isDisplayObject && (
                    <>
                        <BankDetails />
                        <BankTreasuryAccountField />
                        <BudgetOrganizationCodeField />
                        <OKTMOField />
                    </>
                )}

                <InnFileField />
                <AuthorityFileField />
            </Form>
        </div>
    );
};

export default ULForm;
