import { createContext, useContext } from "react";

type ServiceContextType = {
    draftServiceId?: string;
    isSubmittingForm: boolean;
};
export const ServiceContext = createContext<ServiceContextType>({
    draftServiceId: undefined,
    isSubmittingForm: false,
});

export const useServiceContext = () => {
    const context = useContext(ServiceContext);

    if (!context) {
        throw new Error("error useServiceContext");
    }

    return context;
};
