import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ModalWindowRight,
    InputField,
    AutoSubmit,
    Checkbox,
    QuestionTooltip,
    useModalAlertContext,
    StatusRequestEnum,
} from "@russpass/partner-front-ui";
import { Tariff } from "./types";
import { serviceTariffValidation } from "./validation-schema";
import { Formik, Form, FormikProps } from "formik";

import { cloneDeep, noop } from "lodash";
import { maskedInputPrice } from "../../../../utils/validation";
import uuid from "react-native-uuid";
import { newPlaceTimetableData } from "../../../../components/timetable/place-timetable/constants";
import { PlaceTimetableItemType, PlaceTimetableOptions } from "../../../../components/timetable/place-timetable/types";
import PlaceTimetable from "../../../../components/timetable/place-timetable/place-timetable";
import { convertFacilityToTariffWorkingTime, preparedTariffData, preparedViewTariffWorkingTime } from "./utils";
import cardPreviewImg from "../../../../assets/images/services/tariff/card-preview2.png";
import { NotificationProps } from "../../../../components/notification";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { POST_DATA_ERROR2 } from "../../../../constants/errors";
import TariffHint from "./TariffHint/TariffHint";

type Props = {
    isOpen: boolean;
    onClose: (status: boolean) => void;
    tariffData: Tariff;
    onSave: (tariffs: Tariff[]) => Promise<void>;
    onUpdateTariff: (tariff: Tariff) => Promise<void>;
    isExistingService: boolean;
    currentBasicTariffName: string | null;
    facilityWorkingTime: PlaceTimetableItemType[];
    disabled?: boolean;
} & PlaceTimetableOptions;

const TariffModal = ({
    isOpen,
    onClose,
    tariffData,
    onSave,
    onUpdateTariff,
    isExistingService,
    isVisiblePlaceTimetable,
    label = "Действие тарифа",
    btnAddItemTitle = "Добавить рабочие дни",
    validateWithoutTime,
    currentBasicTariffName,
    facilityWorkingTime,
    isShowValiditySwitcher,
    disabled,
    ...restOptions
}: Props) => {
    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const [tariffLocalData, setTariffLocalData] = useState<Tariff>(tariffData);

    const [tempWorkingTime, setTempWorkingTime] = useState<PlaceTimetableItemType[]>([]);

    const [isUseFacilityWorkingTime, setIsUseFacilityWorkingTime] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState(false);

    const isViewTariff = useMemo(() => !!tariffData.id, [tariffData]);

    const { openModalAlert } = useModalAlertContext();

    const isBasicTariff = tariffData.isBasic;

    useEffect(() => {
        if (isOpen) {
            const getWorkingTime = () => {
                if (tariffData?.id) {
                    if (isShowValiditySwitcher) {
                        setIsUseFacilityWorkingTime(false); // При просмотре ранее созданного тарифа делаем активной "Задать свой период действия"
                    }
                    return preparedViewTariffWorkingTime(tariffData);
                } else {
                    if (isShowValiditySwitcher) {
                        setIsUseFacilityWorkingTime(true); // При создании тарифа (при заполненном "Режим работы") делаем активной "По режиму работы места проведения"
                        return convertFacilityToTariffWorkingTime(facilityWorkingTime);
                    }
                    return cloneDeep([
                        {
                            ...newPlaceTimetableData,
                            uuid: uuid.v4() as string,
                            ...{},
                        },
                    ]);
                }
            };

            setTariffLocalData({
                ...cloneDeep(tariffData),
                itemPrices: [{ ...tariffData.itemPrices[0], price: tariffData.itemPrices[0].price.toString() }],
                workingTime: getWorkingTime(),
            });
        } else {
            if (isShowValiditySwitcher) {
                // Очищаем временный стейт "Действие тарифа"
                setTempWorkingTime(
                    cloneDeep([
                        {
                            ...newPlaceTimetableData,
                            uuid: uuid.v4() as string,
                            ...{},
                        },
                    ])
                );
            }
        }
    }, [tariffData, isOpen, isShowValiditySwitcher, facilityWorkingTime]);

    const handleValiditySwitcherChange = useCallback(
        (value: boolean) => {
            if (value === true) {
                setTempWorkingTime(tariffLocalData.workingTime);
                setTariffLocalData((prev) => ({
                    ...prev,
                    workingTime: convertFacilityToTariffWorkingTime(facilityWorkingTime),
                }));
            } else if (value === false) {
                setTariffLocalData((prev) => ({ ...prev, workingTime: tempWorkingTime }));
            }
            setIsUseFacilityWorkingTime(value);
        },
        [facilityWorkingTime, tariffLocalData.workingTime, tempWorkingTime]
    );

    const saveTariff = useCallback(
        async (values: Tariff, resetForm: () => void) => {
            try {
                setIsSaving(true);
                if (isViewTariff && isExistingService) {
                    await onUpdateTariff(preparedTariffData(values, isVisiblePlaceTimetable)[0]);
                } else {
                    await onSave(preparedTariffData(values, isVisiblePlaceTimetable));
                    resetForm();
                }
            } catch (error) {
                callNotification({
                    status: StatusRequestEnum.Error,
                    body: POST_DATA_ERROR2,
                });
            } finally {
                setIsSaving(false);
            }
        },
        [isVisiblePlaceTimetable, isViewTariff, isExistingService, onUpdateTariff, onSave]
    );

    const onChange = useCallback((values: Tariff) => {
        setTariffLocalData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const handleBasicTariffCheckbox = (checked: boolean, setFieldValue: any) => {
        if (checked && !isBasicTariff && currentBasicTariffName) {
            return openModalAlert(
                {
                    description: `Ранее базовым тарифом был «${currentBasicTariffName}». При замене на кнопке в каталоге отобразится стоимость нового тарифа`,
                    title: "Заменить базовый тариф?",
                    OKButton: "Заменить",
                    cancelButton: "Нет, оставить прежний",
                },
                () => {
                    setFieldValue("isBasic", checked);
                }
            );
        }
        if (!checked && isBasicTariff && isViewTariff) {
            return openModalAlert(
                {
                    title: "Выберите базовый тариф для цены в каталоге",
                    description:
                        "Нельзя убрать базовый тариф, не выбрав новый. Откройте нужный тариф и поставьте галочку в соответствующем поле для замены",
                    OKButton: "Хорошо",
                },
                noop
            );
        }
        return setFieldValue("isBasic", checked);
    };

    const onBasicTariffCheckboxLabelClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault();

    const basicTariffCheckboxLabel = (
        <div onClick={onBasicTariffCheckboxLabelClick}>
            <div className="basic__title">
                Базовый тариф{" "}
                <QuestionTooltip
                    body={<img alt="" src={cardPreviewImg} className="basic__img" />}
                    settings={{ offsetX: 210, offsetY: -5 }}
                    tooltipStyle={{ border: "none", background: "none" }}
                />
            </div>
            <div className="basic__desc">
                Стоимость тарифа будет указана на карточке услуги в кнопке «Купить». Обычно к базовому относят
                взрослый/стандартный тариф
            </div>
        </div>
    );

    return (
        <>
            <Formik
                initialValues={tariffLocalData}
                validationSchema={serviceTariffValidation(validateWithoutTime)}
                enableReinitialize
                validateOnMount
                onSubmit={noop}
            >
                {({ submitCount, values, setFieldValue, isValid, submitForm, resetForm }: FormikProps<Tariff>) => {
                    return (
                        <ModalWindowRight
                            onClose={() => {
                                onClose(false);
                            }}
                            isLoadingWithContent={isSaving}
                            isOpened={isOpen}
                            title="Информация о тарифе"
                            ComponentFooter={
                                !disabled ? (
                                    <button
                                        className="button button--primary button--full-width"
                                        onClick={() => {
                                            submitForm().then(() => {
                                                if (!isValid) return;
                                                saveTariff(values, resetForm);
                                            });
                                        }}
                                        data-qa-name="save-button"
                                    >
                                        Сохранить
                                    </button>
                                ) : undefined
                            }
                        >
                            <Form>
                                <AutoSubmit onChange={onChange} />
                                <div className="content-wrapper">
                                    <div className="content-wrapper__form">
                                        <InputField
                                            label="Название"
                                            noHover
                                            name="tariffCaption[0].content"
                                            placeholder="Взрослый/детский/льготный билет и т.д."
                                            hint="Будет отображаться на сайте"
                                            disabled={disabled}
                                        />
                                        <InputField
                                            label="Описание"
                                            noHover
                                            name="tariffNote[0].content"
                                            placeholder="Условия тарифа"
                                            hint="Необязательно"
                                            disabled={disabled}
                                        />
                                        <InputField
                                            label="Стоимость"
                                            noHover
                                            name="itemPrices[0].price"
                                            placeholder="0 ₽"
                                            renderComponent={maskedInputPrice}
                                            maxLength={6}
                                            disabled={disabled}
                                        />
                                        <Checkbox
                                            checked={values.isChild}
                                            id="isChild"
                                            fieldName="isChild"
                                            handleChange={(checked) => setFieldValue("isChild", checked)}
                                            label="Для детей от 0 до 14 лет включительно"
                                            disabled={disabled}
                                        />

                                        {!disabled && values.isChild && (
                                            <TariffHint
                                                text="На сайте RUSSPASS детский билет нельзя купить отдельно от взрослого"
                                                textStyle="hint_child"
                                            />
                                        )}

                                        <Checkbox
                                            checked={values.isBasic}
                                            id="checkbox-basic"
                                            fieldName="checkbox-basic"
                                            handleChange={(checked) =>
                                                handleBasicTariffCheckbox(checked, setFieldValue)
                                            }
                                            label={basicTariffCheckboxLabel}
                                            disabled={disabled}
                                        />

                                        {!disabled && isBasicTariff && isViewTariff && (
                                            <TariffHint
                                                text="Чтобы поменять базовый тариф, откройте нужный тариф и поставьте галочку в соответствующем поле для замены"
                                                textStyle="hint_basic"
                                            />
                                        )}

                                        <PlaceTimetable
                                            data={values.workingTime}
                                            handleChange={(timetableValues) => {
                                                setFieldValue("workingTime", timetableValues);
                                            }}
                                            errorSettings={{
                                                showOnChange: submitCount > 0,
                                            }}
                                            isExistingTariff={isViewTariff}
                                            label={label}
                                            btnAddItemTitle={btnAddItemTitle}
                                            isVisiblePlaceTimetable={isVisiblePlaceTimetable}
                                            isUseFacilityWorkingTime={isUseFacilityWorkingTime}
                                            disabled={disabled}
                                            isShowValiditySwitcher={isShowValiditySwitcher}
                                            {...{
                                                ...restOptions,
                                                changeValiditySwitcherValue: handleValiditySwitcherChange,
                                                validitySwitcherValue: isUseFacilityWorkingTime,
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </ModalWindowRight>
                    );
                }}
            </Formik>
        </>
    );
};

export default TariffModal;
