import { yup } from "../../../../../../yup";
import {
    authorityFileSchema,
    authoritySchema,
    bankSchema,
    documentTypeSchema,
    guideAccreditationDataSchema,
    guideCertificateDataSchema,
    notRequiredFileSchema,
    passportSchema,
    requiredFileSchema,
} from "./validation-schema";
import { FileTypeEnum } from "../../constants";

export const GuideSESchemaValidation = yup.object().shape({
    signatory: yup.string().required(),
    signatoryRole: yup.string().required(),
    authority: authoritySchema,
    ...passportSchema,
    ...documentTypeSchema,
    bank: bankSchema,
    filesData: yup.object().shape({
        [FileTypeEnum.INN]: requiredFileSchema,
        [FileTypeEnum.VICARIOUS_AUTHORITY]: authorityFileSchema,
        [FileTypeEnum.SELF_EMPLOYED_STATEMENT]: requiredFileSchema,
        [FileTypeEnum.GUIDE_CERTIFICATE]: notRequiredFileSchema,
        [FileTypeEnum.GUIDE_ACCREDITATION]: notRequiredFileSchema,
    }),
    guideCertificateData: guideCertificateDataSchema,
    guideAccreditationData: guideAccreditationDataSchema,
});
