import React from "react";
import styles from "./styles.module.sass";
import { ReactComponent as Chevron } from "../../../../../assets/images/icons/chevron_link-right.svg";
import { EventPreviewDataType } from "../../EventPreviewModal";

const DescriptionPreview = ({ description }: Pick<EventPreviewDataType, "description">) => {
    return (
        <div className={styles.container}>
            <div className={styles.description}>{description || "Здесь будет описание"}</div>
            {description && (
                <div className={styles.showMore}>
                    <div className={styles.showMore__text}>{"Развернуть"}</div>
                    <div className={styles.showMore__icon}>
                        <Chevron />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DescriptionPreview;
