import React from "react";
import styles from "./styles.module.sass";
import { ReactComponent as BookOpen } from "../../../../../assets/images/icons/excursion-preview/book-open-01.svg";
import { ReactComponent as Calendar } from "../../../../../assets/images/icons/excursion-preview/calendar.svg";
import { ReactComponent as Navigation } from "../../../../../assets/images/icons/excursion-preview/navigation-pointer-01.svg";
import { ReactComponent as Wallet } from "../../../../../assets/images/icons/excursion-preview/wallet-03.svg";
import { ReactComponent as ClipboardCheck } from "../../../../../assets/images/icons/excursion-preview/clipboard-check.svg";
import { CheckListType } from "../../EventPreviewModal";

type ListProps = {
    items: string[];
};

const List = ({ items }: ListProps) => (
    <>
        {items.length > 0 && items[0] && (
            <div className={styles.section__list}>
                {items.map((item) => (
                    <>
                        {item && (
                            <div key={item[0]} className={styles.section__list__item}>
                                <span key={item} className={styles.section__list__item__point} />
                                <span className={styles.section__list__item__text}>{item}</span>
                            </div>
                        )}
                    </>
                ))}
            </div>
        )}
    </>
);

const CheckList = ({
    program,
    schedule,
    startPointAddress,
    startPointAdditInfo,
    included,
    paidSeparately,
}: CheckListType) => {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.section__title}>
                    <div className={styles.section__title__icon}>
                        <BookOpen />
                    </div>
                    <div className={styles.section__title__text}>{"Программа"}</div>
                </div>
                <List items={program} />
            </div>

            <div className={styles.section}>
                <div className={styles.section__title}>
                    <div className={styles.section__title__icon}>
                        <Calendar />
                    </div>
                    <div className={styles.section__title__text}>{"Расписание экскурсий"}</div>
                </div>
                <List items={schedule} />
            </div>

            <div className={styles.section}>
                <div className={styles.section__title}>
                    <div className={styles.section__title__icon}>
                        <Navigation />
                    </div>
                    <div className={styles.section__title__text}>"Место встречи"</div>
                </div>

                <div className={styles.section__list__item}>{startPointAddress}</div>
                <div className={styles.section__list__item__desc}>{startPointAdditInfo}</div>
            </div>

            <div className={styles.section}>
                <div className={styles.section__title}>
                    <div className={styles.section__title__icon}>
                        <Wallet />
                    </div>
                    <div className={styles.section__title__text}>{"Оплачивается отдельно"}</div>
                </div>
                <List items={paidSeparately} />
            </div>

            <div className={styles.section}>
                <div className={styles.section__title}>
                    <div className={styles.section__title__icon}>
                        <ClipboardCheck />
                    </div>
                    <div className={styles.section__title__text}>{"Входит в стоимость"}</div>
                </div>
                <List items={included} />
            </div>
        </div>
    );
};

export default CheckList;
