import { DOTTED_DATE_FORMAT_FNS, EXTENDED_DATE_TIME_FORMAT_FNS } from "../../../../../../constants/dates-format";
import { DatePickerField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";

type Props = {
    name: string;
};

const ValidityPeriodField = ({ name }: Props) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    return (
        <DatePickerField
            label="Срок действия до"
            name={name}
            placeholder="15.08.2024"
            handleChange={(date) => {
                setFieldValue(name, date);
            }}
            emptyValue=""
            valueFormat={EXTENDED_DATE_TIME_FORMAT_FNS}
            inputFormat={DOTTED_DATE_FORMAT_FNS}
            noHover
            numberOfMonths={1}
            isClearable={false}
            value={getFieldProps(name).value}
            disabledDays={{ before: new Date() }}
        />
    );
};

export default ValidityPeriodField;
