import { BaseFieldProps } from "./types";
import { DOTTED_DATE_FORMAT_FNS } from "../../../../../../constants/dates-format";
import { DatePickerField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";

export const AuthorityDateIssueField = ({ name }: BaseFieldProps) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    return (
        <DatePickerField
            label="Дата выдачи"
            name={name}
            placeholder="15.03.2021"
            handleChange={(date) => {
                setFieldValue(name, date);
            }}
            emptyValue=""
            inputFormat={DOTTED_DATE_FORMAT_FNS}
            noHover
            numberOfMonths={1}
            isClearable={false}
            value={getFieldProps(name).value}
            disabledDays={{ after: new Date() }}
        />
    );
};
