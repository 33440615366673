import React, { useCallback } from "react";
import { RadioButton } from "@russpass/partner-front-ui";
import styles from "./styles.module.sass";
import classNames from "classnames";

type ValiditySwitcherProps = {
    value?: boolean;
    onChange?: (newValue: boolean) => void;
    disabled?: boolean;
};

const ValiditySwitcher = ({ value, onChange, disabled }: ValiditySwitcherProps) => {
    const handleChange = useCallback(
        (newValue: number) => {
            onChange && onChange(!!newValue);
        },
        [onChange]
    );
    return (
        <div
            className={classNames(styles.container, {
                [styles.notAction]: disabled,
            })}
        >
            <RadioButton
                isActive={!!value}
                value={1}
                label={"По режиму работы места проведения"}
                handleClick={handleChange}
            />
            <RadioButton isActive={!value} value={0} label={"Задать свой период действия"} handleClick={handleChange} />
        </div>
    );
};

export default ValiditySwitcher;
