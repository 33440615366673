import useUserInfo from "../../../../../../hooks/useUserInfo";
import {
    GuideSteps,
    RestaurantSteps,
    ServiceSteps,
    ProgressStepsNamesEnum,
    ProgressSteps,
} from "@russpass/partner-front-ui";
import routes from "../../../../../../constants/routes";
import history from "../../../../../../history";
import React from "react";

const useSuccessSettings = () => {
    const { isGuide, isDisplayObject, isRestaurant } = useUserInfo();

    const getSteps = () => {
        if (isGuide) return GuideSteps;
        if (isDisplayObject) return ServiceSteps;
        if (isRestaurant) return RestaurantSteps;
        return [];
    };

    const isSuccessStepsData = {
        [ProgressStepsNamesEnum.RegistrationStep]: true,
        [ProgressStepsNamesEnum.SignOfferStep]: true,
    };

    const getButton = () => {
        let title = "";
        let newPath = "";
        if (isGuide) {
            title = "Заполнить профиль";
            newPath = routes.profile;
        }
        if (isDisplayObject) {
            title = "Добавить услугу";
            newPath = routes.servicesAll;
        }
        if (isRestaurant) {
            title = "Добавить объект";
            newPath = routes.restaurantAll;
        }
        return {
            title,
            action: () => {
                history.push(newPath);
            },
        };
    };

    return {
        getSteps,
        isSuccessStepsData,
        buttonSettings: getButton(),
    };
};

export default useSuccessSettings;
