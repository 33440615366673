import { ACCEPT_FILES, FileTypeEnum, HINT_FILES } from "../../constants";
import { UploadFileField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";

export const InnFileField = () => {
    const { getFieldProps, setFieldValue } = useFormikContext();
    const name = `filesData.${FileTypeEnum.INN}`;
    return (
        <div className="company-info__alignment-wrap mb-24">
            <UploadFileField
                name={name}
                buttonTitle="Загрузить ИНН"
                accept={ACCEPT_FILES}
                files={getFieldProps(name).value}
                onDelete={() => {
                    setFieldValue(name, []);
                }}
                hint={HINT_FILES}
                onDropEvent={() => sendEvent(AnalyticEvent.load_inn)}
            />
        </div>
    );
};
