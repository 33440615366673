const routes = {
    main: "/",
    services: "/services",
    events: "/services/events",
    servicesAdd: "/services/add",
    servicesNew: "/services/events/new",
    servicesAll: "/services/all",
    servicesView: "/services/events/view/:serviceId",
    draftServicesView: "/services/events/draft/:draftServiceId",
    excursions: "/services/excursions",
    excursionsNew: "/services/excursions/new",
    excursionsView: "/services/excursions/view/:excursionId",
    draftExcursionsView: "/services/excursions/draft/:draftExcursionId",
    tours: "/services/tours",
    toursNew: "/services/tours/events/new",
    toursView: "/services/tours/view/:tourId",
    draftToursView: "/services/tours/draft/:draftTourId",
    reports: "/documents/reports",
    documents: "/documents",
    offer: "/documents/offer",
    offerSignSuccess: "/documents/offer/success",
    contracts: "/documents/contracts",
    partnerInfo: "/documents/offer/info",
    profile: "/profile",
    support: "/support",
    hotels: "/hotels",
    hotelsNew: "/hotels/new",
    hotelsAll: "/hotels/all",
    orders: "/orders",
    feedback: "/feedback",
    feedbackItem: "/feedback/:id",
    analytics: "/analytics",
    salesReport: "/salesReport",

    // отели из CMS и BNOVO
    hotelsView: "/hotels/view/:hotelId",
    hotelsViewRooms: "/hotels/view/:hotelId/rooms",
    hotelsViewTariffs: "/hotels/view/:hotelId/tariffs",
    hotelsViewRatings: "/hotels/view/:hotelId/ratings",
    hotelsViewDiscounts: "/hotels/view/:hotelId/discounts",

    // отели из TL
    intHotelsView: "/hotels/view/:partnerSystemId/:hotelId",

    // рестораны
    restaurants: "/restaurants",
    restaurantAll: "/restaurants/all",
    bookingAll: "/booking/all",
    restaurantNew: "/restaurants/new",
    restaurantView: "/restaurants/view/:restaurantId",

    // уведомления
    notifications: "/notifications",
    notificationsSettings: "/notifications/settings",

    // сдача жилья в аренду
    apartments: "/apartments",
    apartmentsAll: "/apartments/all",
    apartmentsNew: "/apartments/new",
    apartmentsView: "/apartments/view/:apartmentId",
    apartmentsBookingAll: "/apartmentBooking/all",

    dialogues: "/dialogues",
    dialoguesItem: "/dialogues/:id",
};

export default routes;
