import React from "react";
import { UserFullInfo } from "../../../../types/user";
import SupportLink from "../support-link";
import AccessPersonalAccount from "../AccessPersonalAccount";
import { TextField, useViewportContext, formatPhone } from "@russpass/partner-front-ui";
import { useLegalStateStatus } from "../../../documents/offer-deprecated/company-info/hooks/useLegalStateStatus";
import styles from "./contact.module.sass";
import classNames from "classnames";
import EnterOtpModal from "./components/EnterOtpModal";
import useModal from "../../../../hooks/useModal";
import { ReactComponent as CheckCircle } from "../../../../assets/images/icons/ic_check_circle.svg";

type Props = {
    data: UserFullInfo | null;
    refreshData: () => void;
};

const Contact = ({ data, refreshData }: Props) => {
    const { isUL, isIP, isFL, isSE } = useLegalStateStatus(data?.legalState);
    const { isMobile } = useViewportContext();

    const { isOpen: isOpenOtp, open: openOtp, close: closeOtp } = useModal(false);

    const getRenderContent = () => {
        const personalPhone = (
            <div
                className={classNames(styles.PhoneWrapper, {
                    [styles.Compact]: isMobile && !data?.mobileNumberVerified,
                })}
            >
                <TextField value={formatPhone(data?.mobileNumber)} name="personalPhone" label="Личный телефон" />
                {!!data?.mobileNumber && (
                    <div className={styles.ButtonWrapper}>
                        {data?.mobileNumberVerified ? (
                            <CheckCircle />
                        ) : (
                            <button type="button" className="button button--primary" onClick={openOtp}>
                                {isMobile ? "Подтвердить телефон" : "Подтвердить"}
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
        const fio = <TextField value={data?.contactPerson} name="fullName" label="ФИО" />;
        const site = data?.webSite ? <TextField value={data?.webSite} name="webSite" label="Сайт" /> : null;
        const email = <TextField value={data?.email} name="email" label="E-mail" />;
        const companyPhone = (
            <TextField value={formatPhone(data?.phoneNumber)} name="companyPhone" label="Телефон компании" />
        );

        if (isFL || isSE)
            return (
                <>
                    <AccessPersonalAccount />
                    {fio}
                    {personalPhone}
                    {email}
                    {site}
                    <SupportLink />
                </>
            );
        if (isIP)
            return (
                <>
                    <AccessPersonalAccount />
                    {fio}
                    {personalPhone}
                    {companyPhone}
                    {email}
                    {site}
                    <SupportLink />
                </>
            );
        if (isUL)
            return (
                <>
                    <AccessPersonalAccount />
                    {fio}
                    {personalPhone}
                    {email}
                    <TextField value={data?.brandName} name="brandName" label="Название компании" />
                    {companyPhone}
                    {site}
                    <SupportLink />
                </>
            );
    };

    return (
        <div className="content-wrapper">
            <div className="content-wrapper__form">{getRenderContent()}</div>
            <EnterOtpModal
                isOpen={isOpenOtp}
                onClose={closeOtp}
                phone={data?.mobileNumber || ""}
                email={data?.email || ""}
                refreshData={refreshData}
            />
        </div>
    );
};

export default Contact;
