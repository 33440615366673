import { navbarServicesNames } from "./all/constants";
import moment from "moment";
import { ISO_DATE_FORMAT } from "../../constants/dates-format";
import { Tariff } from "./components/tariffs/types";
import { ListItem, StatusNamesServiceItem } from "./all/types";
import { padStart } from "lodash";

export const getIsDraftService = (statusName: string) => {
    return statusName === StatusNamesServiceItem.Draft;
};

export const getIsModerationService = (statusName: string) => {
    return statusName === StatusNamesServiceItem.Import;
};

export const getIsPublishedService = (statusName: string) => {
    return statusName === StatusNamesServiceItem.Active;
};

export const getIsArchivedService = (statusName: string | number) => {
    return statusName === StatusNamesServiceItem.Deleted || statusName === StatusNamesServiceItem.Inactive;
};

export const getTariffFormatString = ({
    registrationOnDateByTime,
    registrationOffDateByTime,
}: {
    registrationOnDateByTime?: string | null;
    registrationOffDateByTime?: string | null;
}) => {
    let formatRegistrationOnDate = registrationOnDateByTime
        ? registrationOnDateByTime.replace(" ч ", ":").replaceAll("_", "").split(" мин")[0]
        : "";

    let formatRegistrationOffDate = registrationOffDateByTime
        ? registrationOffDateByTime.replace(" ч ", ":").replaceAll("_", "").split(" мин")[0]
        : "";

    if (formatRegistrationOnDate && formatRegistrationOnDate[0] === ":") {
        formatRegistrationOnDate = "00" + formatRegistrationOnDate;
    }
    if (formatRegistrationOnDate && formatRegistrationOnDate[formatRegistrationOnDate.length - 1] === ":") {
        formatRegistrationOnDate += "00";
    }
    if (formatRegistrationOffDate && formatRegistrationOffDate[0] === ":") {
        formatRegistrationOffDate = "00" + formatRegistrationOffDate;
    }
    if (formatRegistrationOffDate && formatRegistrationOffDate[formatRegistrationOffDate.length - 1] === ":") {
        formatRegistrationOffDate += "00";
    }

    return { formatRegistrationOnDate, formatRegistrationOffDate };
};

export const processingForUpdateTariff = ({
    tariff,
    isDelete,
    isSendRegistration = true,
}: {
    tariff: Tariff;
    isDelete: boolean;
    isSendRegistration?: boolean;
}) => {
    const { formatRegistrationOnDate, formatRegistrationOffDate } = getTariffFormatString({
        registrationOnDateByTime: tariff?.registrationOnDateByTime,
        registrationOffDateByTime: tariff?.registrationOffDateByTime,
    });

    const updatedTariff: Tariff = {
        deleted: isDelete ? 1 : 0,
        tariffCaption: tariff.tariffCaption,
        tariffNote: tariff.tariffNote,
        itemType: tariff.itemType,
        itemPrices: tariff.itemPrices,
        discounts: tariff.discounts,
        workingDays: tariff.workingDays,
        itemBenefitType: {
            id: 1,
            caption: "Взрослый",
        },
        activeSince: moment(tariff.activeSince).format(ISO_DATE_FORMAT),
        activeUntil: moment(tariff.activeUntil).format(ISO_DATE_FORMAT),
        openedSince: tariff.openedSince,
        openedUntil: tariff.openedUntil,
        isChild: tariff.isChild,
        isBasic: tariff.isBasic,
        workingTime: tariff.workingTime,
        registrationOnDateByTime: formatRegistrationOnDate || null,
        registrationOffDateByTime: formatRegistrationOffDate || null,
    };

    if (!isSendRegistration) {
        delete updatedTariff.registrationOnDateByTime;
        delete updatedTariff.registrationOffDateByTime;
    }

    return updatedTariff;
};

export const getNavNameStatus = (serviceItem: ListItem) => {
    if (serviceItem.isRejected) return navbarServicesNames.refused;

    switch (serviceItem.status) {
        case StatusNamesServiceItem.Draft:
            return navbarServicesNames.draft;
        case StatusNamesServiceItem.Import:
            return navbarServicesNames.moderation;
        case StatusNamesServiceItem.Active:
            return navbarServicesNames.published;
        case StatusNamesServiceItem.Deleted:
        case StatusNamesServiceItem.Inactive:
            return navbarServicesNames.archive;
        default:
            return navbarServicesNames.all;
    }
};

export const getHoursAndMinutes = (value: string) => value.split(/[^\d]+/).filter(Boolean);

//TODO подумть и убрать вероятно сделать на уровне компонента
export const getStringFromHoursMinutes = (hours: number | undefined, minutes: number | undefined): string => {
    if (typeof hours !== "number") {
        return "";
    }
    if (typeof minutes !== "number") {
        return `${padStart(String(hours), 2, "0")} ч. 00 мин.`;
    }
    return `${padStart(String(hours), 2, "0")} ч. ${padStart(String(minutes), 2, "0")} мин.`;
};

export const checkIsStrIsTime = (value: string | undefined) => {
    if (typeof value !== "string") return true;
    const [hours, minutes] = getHoursAndMinutes(value);
    if (
        hours &&
        minutes &&
        typeof Number(hours) === "number" &&
        typeof Number(minutes) === "number" &&
        hours.length === 2 &&
        minutes.length === 2
    ) {
        return true;
    } else {
        return false;
    }
};
