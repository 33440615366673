import { UploadPhotosItemNew } from "@russpass/partner-front-ui";
import { attachDraftFile, deleteDraftFile, uploadPhotoToCms } from "../../../../api/files";
import { onError } from "../../../../utils/images";

export const saveImagesToCMS = async (imagesFiles: UploadPhotosItemNew[], isDraft?: boolean) => {
    const promises = imagesFiles.map(async (imagesInfo, index): Promise<string> => {
        if (imagesInfo.file) {
            if (isDraft || imagesInfo.isNew) {
                const { id } = await uploadPhotoToCms(imagesInfo.file);
                return id;
            }
        }
        return imagesInfo.id as string;
    });

    try {
        const result = await Promise.all(promises);
        return result;
    } catch (error) {
        onError();
    }
};

export const saveImagesForDraft = async (imagesFiles: UploadPhotosItemNew[], partnerId: string) => {
    const promises: Promise<string>[] = imagesFiles.map(async (imagesInfo, index) => {
        if (imagesInfo.isNew && imagesInfo.file) {
            const { link } = await attachDraftFile(imagesInfo.file, partnerId);
            return link;
        }
        return imagesInfo.url as string;
    });

    try {
        const result: string[] = await Promise.all(promises);
        return result;
    } catch (error) {
        onError();
    }
};

export const deleteDraftImage = (imagesFiles: UploadPhotosItemNew[]) => {
    imagesFiles.map(async (imagesInfo) => {
        if (imagesInfo.url && !imagesInfo.isNew) {
            try {
                await deleteDraftFile(imagesInfo.url);
            } catch (error) {
                console.error(error);
            }
        }
    });
};
