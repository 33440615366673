import React, { useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import PlaceTimetableItem from "./place-timetable-item";
import Timetable from "../timetable/timetable";
import { DayTimeTable, PlaceTimetableItemType, PlaceTimetableOptions } from "./types";
import { getDaysOffRow, preparedDisabledOptions } from "./utils";
import { diffArrays } from "../../../utils/common";
import { newPlaceTimetableData, timetableOptionsFull } from "./constants";
import uuid from "react-native-uuid";
import "./styles.sass";

type Props = {
    data: PlaceTimetableItemType[];
    disabled?: boolean;
    handleChange: (placeTimetable: PlaceTimetableItemType[]) => void;
    errorSettings?: {
        showOnChange: boolean;
    };
    isExistingTariff?: boolean;
    isWorkingMode?: boolean;
    isShowBreakTime?: boolean;
    modalTitle?: string;
    isUseFacilityWorkingTime?: boolean;
} & PlaceTimetableOptions;

const PlaceTimetable = ({
    data,
    disabled,
    handleChange,
    errorSettings,
    isWorkingMode,
    label,
    btnAddItemTitle,
    isShowValiditySwitcher,
    validitySwitcherValue,
    isExistingTariff,
    changeValiditySwitcherValue,
    isUseFacilityWorkingTime,
    ...restOptions
}: Props) => {
    const disabledOptions = useMemo(() => preparedDisabledOptions(data, isWorkingMode), [data, isWorkingMode]);

    const weekends: DayTimeTable[] = useMemo(
        () => diffArrays(disabledOptions, timetableOptionsFull),
        [disabledOptions]
    );

    const daysOffRow = useMemo(() => getDaysOffRow(weekends), [weekends]);

    const isShowAdd = useMemo(() => {
        const isAllDaysSelected = weekends.length < 2;
        const isWorkingDaysFilled = !!data[data.length - 1]?.workingDays?.length;
        const isTimeFilled = !!data[data.length - 1]?.time?.[0];

        if (isExistingTariff) {
            return !data.length;
        } else {
            return !data.length || (!isAllDaysSelected && isWorkingDaysFilled && isTimeFilled);
        }
    }, [data, isExistingTariff, weekends.length]);

    const isShowDivider = useCallback(
        (indx) => !(indx === data.length - 1 && (disabled || disabledOptions?.length >= 7)),
        [data.length, disabled, disabledOptions]
    );

    const addTimetable = useCallback(
        () => handleChange([...data, { ...newPlaceTimetableData, uuid: uuid.v4() as string }]),
        [data, handleChange]
    );

    const deletePlaceTimetable = useCallback(
        (uuid: string) => handleChange(data.filter((item) => item.uuid !== uuid)),
        [data, handleChange]
    );

    const changeTimetable = (placeTimetableItem: PlaceTimetableItemType) => {
        handleChange(
            data.map((item) => {
                if (item.uuid === placeTimetableItem.uuid) {
                    return placeTimetableItem;
                }
                return item;
            })
        );
    };

    return (
        <>
            <Timetable
                label={label}
                btnAddItemTitle={btnAddItemTitle}
                disabled={disabled}
                isUseFacilityWorkingTime={isUseFacilityWorkingTime}
                isShowAdd={isShowAdd}
                handleAdd={addTimetable}
                handleDelete={deletePlaceTimetable}
                error={errorSettings?.showOnChange && isEmpty(data) ? "Добавьте режим работы" : ""}
                isShowValiditySwitcher={isShowValiditySwitcher}
                validitySwitcherValue={validitySwitcherValue}
                changeValiditySwitcherValue={changeValiditySwitcherValue}
            >
                {data.map((PlaceTimetableItemItem: PlaceTimetableItemType, index) => (
                    <PlaceTimetableItem
                        key={PlaceTimetableItemItem.uuid}
                        disabledOptions={disabledOptions}
                        data-key={PlaceTimetableItemItem.uuid}
                        data={PlaceTimetableItemItem}
                        handleChange={changeTimetable}
                        errorSettings={errorSettings}
                        // isExistingService={disabled}
                        isWorkingMode={isWorkingMode}
                        disabled={isUseFacilityWorkingTime || disabled}
                        index={index}
                        divider={isShowDivider(index)}
                        isExistingTariff={isExistingTariff}
                        {...restOptions}
                    />
                ))}
            </Timetable>

            {isWorkingMode && (
                <Timetable label="Выходные">
                    <div className="weekends" data-qa-name="weekends">
                        {daysOffRow}
                    </div>
                </Timetable>
            )}
        </>
    );
};

export default PlaceTimetable;
