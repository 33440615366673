import { ACCEPT_FILES, FileTypeEnum, HINT_FILES } from "../../constants";
import { UploadFileField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { ReactComponent as IconAlertCircle } from "../../../../../../assets/images/icons/ic_alert_circle.svg";
import { RegionField } from "./RegionField";
import ValidityPeriodField from "./ValidityPeriodField";

export const GuideCertificate = () => {
    const { getFieldProps, setFieldValue, errors } = useFormikContext();

    const name = `filesData.${FileTypeEnum.GUIDE_CERTIFICATE}`;
    const value = getFieldProps(name).value;

    // @ts-ignore
    const isValidFile = !!value?.length && !errors?.filesData?.[FileTypeEnum.GUIDE_CERTIFICATE];

    return (
        <div className="company-info__alignment-wrap mb-24">
            {!!value?.length && <div className="title mb-24">Аттестаты</div>}
            <UploadFileField
                name={name}
                buttonTitle="Аттестат экскурсовода"
                accept={ACCEPT_FILES}
                files={value}
                onDelete={() => {
                    setFieldValue(name, undefined);
                }}
                hint={HINT_FILES}
            />
            <p className="company-info__field-alert">
                <IconAlertCircle />{" "}
                <span>Добавьте несколько аттестатов сразу либо один сейчас, а остальные позже в личном кабинете</span>
            </p>
            {isValidFile && (
                <div className={"fields-grid"}>
                    <RegionField />
                    <ValidityPeriodField name={"guideCertificateData.periodOfValidity"} />
                </div>
            )}
        </div>
    );
};
