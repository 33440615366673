import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import Region from "../../../../../../components/async-selects/region";

export const RegionField = () => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    const name = "guideCertificateData.regionOfValidity";
    const value = getFieldProps(name).value;

    const onChangeAddress = useCallback(
        (value) => {
            setFieldValue(name, value);
        },
        [name, setFieldValue]
    );

    return (
        <Region
            name={`${name}`}
            placeholder="Московская область"
            label="Регион действия"
            value={value || ""}
            handleChange={onChangeAddress}
        />
    );
};
