/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { AgeRestriction, NewServiceData, ServiceDataClient } from "../helpers/types";
import { INITIAL_SERVICE_DATA } from "../helpers/mock";
import { getAgeRestriction } from "../../../../api/refbook";
import { NotificationProps } from "../../../../components/notification";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import { useLocation, useParams } from "react-router-dom";
import { matchPath } from "react-router";
import routes from "../../../../constants/routes";
import { getService } from "../../../../api/service";
import { getVatList } from "../../../../api/billing";
import { getImageById } from "../../../../api/files";
import { StatusRequestEnum, UploadPhotosItemNew } from "@russpass/partner-front-ui";
import { getEventTypes } from "../../../../api/dictionaries";
import { sortingAscByField } from "../../../../utils/sorting";
import { IAtolVat } from "../../../../types/billing";
import { EventType } from "../../types";
import { navbarService, NavbarServiceNamesEnum } from "../helpers/constants";
import _isEqual from "lodash/isEqual";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import { getStringFromHoursMinutes } from "../../utils";
import history from "../../../../history";
import { Params } from "@sentry/react/types/types";
import { StatusNamesServiceItem } from "../../all/types";
import uuid from "react-native-uuid";
import { Tariff } from "../../components/tariffs/types";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { getServiceDraft } from "../../../../api/draft/service";
import { formatDraftServiceFromGet } from "../helpers/format";

type Props = {
    callNotification: (body: NotificationProps) => void;
    loadTariffList: (billingProductId: string, page?: number, size?: number) => void;
    loadDraftTariffList: (draftTariffs: Tariff[]) => void;
};

const useService = (params: Props) => {
    const { callNotification, loadTariffList, loadDraftTariffList } = params;
    const location = useLocation();

    const match = matchPath(location.pathname, {
        path: routes.servicesView,
        exact: true,
        strict: false,
    });

    const { serviceId, draftServiceId }: Params = useParams();

    const [initialData, setInitialData] = useState<NewServiceData>(INITIAL_SERVICE_DATA);

    // TODO ОСТАВИТЬ ОДИН СТЕЙТ тут храним изначальное состояние что бы сравнивать были ли изменения
    const [compareData, setCompareData] = useState<NewServiceData>(INITIAL_SERVICE_DATA);
    const [data, setData] = useState<NewServiceData>(INITIAL_SERVICE_DATA); // тут данные из формы

    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const [step, setStep] = useState<NavbarServiceNamesEnum>(navbarService[0].name);

    const [optionsEventTypes, setOptionsEventTypes] = useState<EventType[]>([]);
    const [optionsAgeRestriction, setOptionsAgeRestriction] = useState<AgeRestriction[]>([]);
    const [optionsNdsRate, setOptionsNdsRate] = useState<IAtolVat[]>([]);

    const isArchive = useMemo(() => {
        return data.status === StatusNamesServiceItem.Inactive || data.status === StatusNamesServiceItem.Deleted;
    }, [data]);

    const getServiceDataRequest = useCallback(
        () =>
            new Promise(async (resolve) => {
                try {
                    if (!serviceId) return;

                    const service = await getService(serviceId);

                    const imagesFiles: UploadPhotosItemNew[] = [...service.imageExplorePreview, ...service.images].map(
                        (img) => {
                            return {
                                preview: getImageById(img),
                                url: img,
                                id: img,
                            };
                        }
                    );

                    const formattedServiceData = {
                        ...service,
                        imagesFiles,
                        duration: getStringFromHoursMinutes(
                            Number(service.duration.hours),
                            Number(service.duration.minutes)
                        ),
                    };

                    setInitialData(formattedServiceData);
                    setData(formattedServiceData);
                    setCompareData(formattedServiceData);

                    if (service.billingProductId) {
                        await loadTariffList(service.billingProductId);
                    }

                    return resolve(service);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        []
    );

    const getDraftServiceDataRequest = useCallback(
        () =>
            new Promise(async (resolve) => {
                try {
                    if (!draftServiceId) return;
                    const service = await getServiceDraft(draftServiceId);

                    const formattedService = await formatDraftServiceFromGet(initialData, service.draft);

                    formattedService.status = service.businessStatus as StatusNamesServiceItem;
                    setData(formattedService);
                    setCompareData(formattedService);
                    setInitialData(formattedService);

                    if (service.draft.rateObject?.length > 0) {
                        loadDraftTariffList(
                            service.draft?.rateObject?.map(
                                (rate) =>
                                    ({
                                        ...rate,
                                        id: uuid.v4(),
                                        registrationOnDate: rate?.registrationOnDateByTime,
                                        registrationOffDate: rate?.registrationOffDateByTime,
                                    } as Tariff)
                            ) || []
                        );
                    }

                    return resolve(service);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        [draftServiceId]
    );

    const reloadServiceData = () => {
        if (serviceId) {
            setInitialLoading(true);
            getServiceDataRequest().then(() => setInitialLoading(false));
        }
    };

    const loadData = () => {
        setInitialLoading(true);

        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const data = await getEventTypes();
                    const eventTypes: EventType[] = [...data.rows]
                        .filter((eventType) => eventType.status === "active")
                        .map((eventType) => {
                            return { id: eventType.id, title: eventType.title };
                        })
                        .sort(sortingAscByField("title"));
                    setOptionsEventTypes(eventTypes);
                    return resolve(eventTypes);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const ageRestrictions = await getAgeRestriction();
                    setOptionsAgeRestriction(ageRestrictions);
                    return resolve(ageRestrictions);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const response = await getVatList();
                    setOptionsNdsRate(response);
                    return resolve(response);
                } catch (err) {
                    console.error(err);
                    callNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];
        if (draftServiceId) {
            requestArray.push(getDraftServiceDataRequest());
        }
        if (serviceId) {
            requestArray.push(getServiceDataRequest());
        }
        Promise.all(requestArray).then(() => {
            setInitialLoading(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const onChangeData = useCallback(
        (values: NewServiceData) =>
            setData((prevState) => ({
                ...prevState,
                ...values,
            })),
        []
    );

    const changeTab = (newStep: NavbarServiceNamesEnum) => {
        if (newStep !== step) {
            setStep(newStep);
            const searchParams = newStep === NavbarServiceNamesEnum["Service"] ? "" : `?tab=${newStep}`;

            history.push({
                pathname: location.pathname,
                search: searchParams,
                state: location?.state,
            });
        }
    };

    const BILLING_FIELDS = ["personalizedTickets", "paymentType", "ndsPercent"] as Array<keyof ServiceDataClient>;

    const hasChangesExceptBilling = !_isEqual(_omit(data, BILLING_FIELDS), _omit(compareData, BILLING_FIELDS));
    const hasBillingChanges = !_isEqual(_pick(data, BILLING_FIELDS), _pick(compareData, BILLING_FIELDS));

    return {
        data,
        initialData,
        isArchive,
        step,
        optionsEventTypes,
        optionsAgeRestriction,
        optionsNdsRate,
        onChangeData,
        reloadServiceData,
        changeTab,
        initialLoading,
        hasChangesExceptBilling,
        hasBillingChanges,
        draftServiceId,
    };
};

export default useService;
