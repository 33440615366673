import { useState } from "react";
import { OfferNewForm } from "../types";

import { sendEvent } from "../../../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../../../product_analytics/constants";
import useUserInfo from "../../../../../../hooks/useUserInfo";
import { getPartnerInfo, saveDocumentsData, saveFiles } from "../../utils";
import {
    confirmApplication,
    confirmApplicationByLeadership,
    confirmDocument,
    savePartnerInfo,
    signOfferRequest,
} from "../../../../../../api/offer";
import eventDispatcher from "../../../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { COMMON_ERROR } from "../../../../../../constants/errors";
import useLoadUserInfo from "../../../../../../hooks/useLoadUserInfo";
import history from "../../../../../../history";
import routes from "../../../../../../constants/routes";

const useSubmitOffer = () => {
    const [isSendingForm, setIsSendingForm] = useState(false);
    const { userFullInfo } = useUserInfo();
    const { loadUserInfo } = useLoadUserInfo();
    const handlerSubmitOffer = async (values: OfferNewForm) => {
        if (!userFullInfo) return;
        setIsSendingForm(true);

        const { applicationId, email } = userFullInfo;
        try {
            const files = await saveFiles(applicationId, values.filesData);
            await savePartnerInfo(applicationId, getPartnerInfo(values));
            await saveDocumentsData(applicationId, values, files);
            await confirmApplication(applicationId);
            await confirmApplicationByLeadership(applicationId);
            await confirmDocument(applicationId);
            await signOfferRequest(applicationId);
            await loadUserInfo(email);
            sendEvent(AnalyticEvent.sign_success);
            history.replace({
                pathname: routes.offerSignSuccess,
                state: {
                    background: {
                        pathname: routes.offer,
                    },
                },
            });
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: COMMON_ERROR,
            });
            sendEvent(AnalyticEvent.sign_fail);
        } finally {
            setIsSendingForm(false);
        }
    };

    return {
        isSendingForm,
        handlerSubmitOffer,
    };
};

export default useSubmitOffer;
