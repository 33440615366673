import { DOTTED_DATE_FORMAT_FNS, EXTENDED_DATE_TIME_FORMAT_FNS } from "../../../../../../constants/dates-format";
import { DatePickerField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";

export const IssueDateField = () => {
    const { setFieldValue, getFieldProps } = useFormikContext();
    const name = "authority.dateIssue";
    return (
        <DatePickerField
            label="Дата выдачи"
            name={name}
            placeholder="15.08.2024"
            handleChange={(date) => {
                setFieldValue(name, date);
            }}
            emptyValue=""
            valueFormat={EXTENDED_DATE_TIME_FORMAT_FNS}
            inputFormat={DOTTED_DATE_FORMAT_FNS}
            noHover
            numberOfMonths={1}
            isClearable={false}
            value={getFieldProps(name).value}
            disabledDays={{ after: new Date() }}
        />
    );
};
