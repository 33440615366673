import { BankBicField } from "./BankBicField";
import { BankNameField } from "./BankNameField";
import { BankCheckingAccountField } from "./BankCheckingAccountField";
import { BankCorrAccountField } from "./BankCorrAccountField";
import React from "react";

const BankDetails = () => {
    return (
        <>
            <div className="company-info__title">Банковские реквизиты</div>
            <BankBicField />
            <BankNameField />
            <BankCheckingAccountField />
            <BankCorrAccountField />
        </>
    );
};

export default BankDetails;
