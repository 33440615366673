import { PhotoGuideModalProps } from "@russpass/partner-front-ui";
import Photo1_01 from "../../assets/images/photoGuide/service/photo-guide-row-1_01.png";
import Photo1_02 from "../../assets/images/photoGuide/service/photo-guide-row-1_02.png";
import Photo2_01 from "../../assets/images/photoGuide/service/photo-guide-row-2_01.png";
import Photo2_02 from "../../assets/images/photoGuide/service/photo-guide-row-2_02.png";
import Photo3_01 from "../../assets/images/photoGuide/service/photo-guide-row-3_01.png";
import Photo3_02 from "../../assets/images/photoGuide/service/photo-guide-row-3_02.png";
import Photo4_01 from "../../assets/images/photoGuide/service/photo-guide-row-4_01.png";
import Photo4_02 from "../../assets/images/photoGuide/service/photo-guide-row-4_02.png";
import Photo5_01 from "../../assets/images/photoGuide/service/photo-guide-row-5_01.png";
import Photo5_02 from "../../assets/images/photoGuide/service/photo-guide-row-5_02.png";

export const photoGuideServiceData: PhotoGuideModalProps = {
    disclaimer: [
        {
            text: "Вес одной фотографии — до 15 Мб, формат — png, jpeg",
            isCorrectly: true,
        },
        {
            text: "В кадре не должно быть алкоголя, сигарет, кальяна, наркотиков, логотипов, водяных знаков, детей и посторонних людей",
            isCorrectly: false,
        },
    ],
    blocks: [
        {
            images: [Photo1_01, Photo1_02],
            tip: {
                text: "Добавьте общие кадры места, где проходит мероприятие",
                isCorrectly: true,
            },
        },
        {
            images: [Photo2_01, Photo2_02],
            tip: {
                text: "Покажите детали процесса и того, что увидят люди",
                isCorrectly: true,
            },
        },
        {
            images: [Photo3_01, Photo3_02],
            tip: {
                text: "Меняйте ракурсы и планы на фотографиях: чем интереснее контент, тем больше привлекает само мероприятие",
                isCorrectly: true,
            },
        },
        {
            images: [Photo4_01, Photo4_02],
            tip: {
                text: "Не загружайте снимки, где важные объекты расположены по краям: в некоторых разделах фотографии обрезаются по вертикали и используется их центральная часть",
                isCorrectly: false,
            },
        },
        {
            images: [Photo5_01, Photo5_02],
            tip: {
                text: "Не загружайте темные, смазанные снимки",
                isCorrectly: false,
            },
        },
    ],
};
