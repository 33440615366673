import { maskedInputNumbers } from "../../../../../../utils/validation";
import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { useBankDetailsContext } from "../context/BankDetailsContext";

export const BankBicField = () => {
    const { getFieldProps } = useFormikContext();

    const { handleValidateBankAccount } = useBankDetailsContext();

    const bank = getFieldProps("bank").value;

    return (
        <InputField
            label="БИК банка"
            name={"bank.bic"}
            noHover
            placeholder="040349736"
            maxLength={9}
            errorSettings={{ showCustomError: true }}
            onValidate={(e) => {
                handleValidateBankAccount({ ...bank, bic: e.target.value }, "bic");
            }}
            renderComponent={maskedInputNumbers}
        />
    );
};
