import { useCallback, useEffect, useMemo, useState } from "react";
import { ITourData, ITourDataPost, TourDataPatch } from "./types";
import eventDispatcher from "../../../lib/event-dispatcher";
import { initialTour, navbarTourNames } from "./constants";
import { useLocation, useParams } from "react-router-dom";
import { getRegion } from "../../../components/async-selects/utils";
import { getAgeRestriction } from "../../../api/refbook";
import { AgeRestriction } from "../service/helpers/types";
import { getTourById, updateTour } from "../../../api/tour";
import { COMMON_RELOAD_ERROR, StatusRequestCardSubtitleError } from "../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { TSelectValue } from "../../../types/dictionaries";
import history from "../../../history";
import { StatusNamesServiceItem } from "../all/types";
import { CreateEventResultStatusEnum } from "../components/CreateEventResultModal/create-event-modal-enum";
import routes from "../../../constants/routes";
import { useCreateEventModalContext } from "../components/contexts/createEventStatusModal";
import useUserInfo from "../../../hooks/useUserInfo";

type Params = {
    tourId: string;
};

type Props = {
    loadTariffList: (billingProductId: string, page?: number, size?: number) => void;
};

const useTour = ({ loadTariffList }: Props) => {
    const { tourId }: Params = useParams();
    const location = useLocation();
    const [isViewComment, setIsViewComment] = useState<boolean>(false);
    const [tourData, setTourData] = useState<ITourData>(initialTour);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [optionsAgeRestriction, setOptionsAgeRestriction] = useState<AgeRestriction[]>([]);
    const [step, setStep] = useState<string | number | undefined>(
        new URLSearchParams(location.search).get("tab") || undefined
    );
    const [region, setRegion] = useState<TSelectValue>({
        id: "",
        title: "",
    });
    const [city, setCity] = useState<TSelectValue>({
        id: "",
        title: "",
    });

    const isArchive = useMemo(() => {
        return (
            tourData.status === StatusNamesServiceItem.Inactive || tourData.status === StatusNamesServiceItem.Deleted
        );
    }, [tourData]);

    const goToTabTour = useCallback(() => {
        setStep(navbarTourNames.tour);
    }, []);

    const onChangeData = useCallback((values: ITourDataPost) => {
        setTourData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const changeTab = useCallback(
        (newStep: string | undefined | number) => {
            console.log(newStep);
            if (newStep !== step) {
                setStep(newStep);
                const searchParams = newStep === "tour" ? "" : `?tab=${newStep}`;
                history.push({
                    pathname: location.pathname,
                    search: searchParams,
                    state: location?.state,
                });
            }
        },
        [step, location.pathname, location?.state]
    );

    const changeRegion = useCallback((region: TSelectValue) => {
        setRegion(region);
    }, []);

    const changeCity = useCallback((city: TSelectValue) => {
        setCity(city);
    }, []);

    const callErrorReloadNotification = () => {
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: COMMON_RELOAD_ERROR,
        });
    };

    const loadData = useCallback(() => {
        setIsLoadingForm(true);
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const ageRestrictions = await getAgeRestriction();
                    setOptionsAgeRestriction(ageRestrictions);
                    return resolve(ageRestrictions);
                } catch (err) {
                    console.error(err);
                    callErrorReloadNotification();
                }
            }),
        ];
        if (tourId) {
            requestArray.push(
                new Promise(async (resolve) => {
                    try {
                        const tour: ITourData = await getTourById(tourId);

                        setRegion(await getRegion(tour.region));
                        setCity(await getRegion(tour.city));

                        const images = [...tour.imageExplorePreview, ...tour.images];

                        setTourData({
                            ...initialTour,
                            ...tour,
                            images,
                            nights: String(tour.nights),
                        });

                        if (tour?.billingProductId) {
                            await loadTariffList(tour.billingProductId);
                        }
                        const _isViewComment = Boolean(tour?.isRejected);
                        setIsViewComment(_isViewComment);
                        return resolve(tour);
                    } catch (err) {
                        console.error(err);
                        callErrorReloadNotification();
                    }
                })
            );
        }
        Promise.all(requestArray).then(() => {
            setIsLoadingForm(false);
        });
    }, [tourId, loadTariffList]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { userFullInfo } = useUserInfo();
    const { openEventStatusModal } = useCreateEventModalContext();

    const sendArchiveToModerate = useCallback(async () => {
        if (!tourData.id) return;
        const data: TourDataPatch = {
            id: tourData.id,
            sendToBilling: false,
            sendToCms: true,
            paymentType: "",
            patchMode: false,
        };
        try {
            await updateTour(data);
            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                title: `Услуга «${tourData.title}» опубликована.`,
                desc: (
                    <>
                        В течение 5 рабочих дней вы получите ответ на{" "}
                        <span className="footnote">{userFullInfo.email}</span>
                    </>
                ),
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } catch (err) {
            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                title: `Не удалось опубликовать «${tourData.title}».`,
                desc: StatusRequestCardSubtitleError,
                errorBtnAction: () => updateTour(data),
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        }
    }, [tourData]);

    return {
        optionsAgeRestriction,
        isLoadingForm,
        tourData,
        onChangeData,
        step,
        changeTab,
        region,
        changeRegion,
        city,
        changeCity,
        isViewTour: Boolean(tourId),
        goToTabTour,
        isViewComment,
        isArchive,
        sendArchiveToModerate,
    };
};

export default useTour;
