import { useRentHousesDictionary } from "../../../api/hooks/rentHouses";
import { useEffect, useMemo, useState } from "react";
import eventDispatcher from "../../../lib/event-dispatcher";
import { UNEXPECTED_ERROR } from "../../../constants/errors";
import SearchSelectField, { SearchSelectFieldProps } from "../search-select-field";
import { BaseFieldProps } from "../../../containers/documents/offer-deprecated/company-info/components/fields/types";
import { useFormikContext } from "formik";
import { useViewportContext, StatusRequestEnum } from "@russpass/partner-front-ui";
import { RentHousesDictionaryItem } from "../../../types/rentHouses";

type Props = BaseFieldProps &
    Pick<SearchSelectFieldProps, "selectPlaceholder" | "searchPlaceholder" | "label" | "hideArrow" | "onChange"> & {
        dictionary: string;
    };
const RentHousesDictionaryField = ({ name, disabled, dictionary, onChange, ...searchSelectProps }: Props) => {
    const { getFieldProps } = useFormikContext();

    const [term, setTerm] = useState("");

    const id = getFieldProps(name).value;
    const { data: selectedOptionData } = useRentHousesDictionary(
        { dictionary: dictionary, pageSize: 1, sort: "dictionary_data.title", id },
        { revalidateOnFocus: false, isDisabledRequest: !id }
    );

    const { data, error } = useRentHousesDictionary(
        { dictionary: dictionary, pageSize: 999, sort: "dictionary_data.title", term: term || undefined },
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (!error) return;
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: UNEXPECTED_ERROR,
        });
    }, [error]);

    const options = useMemo(() => {
        const selectedOptionItem = selectedOptionData?.data?.items?.[0];

        const mapItemToOption = (el: RentHousesDictionaryItem) => ({
            value: el.id,
            label: el.dictionary_data?.title || "",
            utc: el.dictionary_data?.utc,
        });

        const selectedOption = selectedOptionItem ? mapItemToOption(selectedOptionItem) : undefined;

        const options = data?.data.items.map(mapItemToOption) || [];

        if (selectedOption && !options.find((el) => el.value === selectedOption.value)) {
            return [...options, selectedOption];
        }
        return options;
    }, [data, selectedOptionData]);

    const { isMobile } = useViewportContext();

    return (
        <SearchSelectField
            name={name}
            options={options}
            disabled={disabled}
            {...searchSelectProps}
            onChange={onChange}
            saveOnClick={isMobile}
            onSearchTextChange={setTerm}
        />
    );
};

export default RentHousesDictionaryField;
