import { createContext, useContext } from "react";
import { BankErrors } from "../hooks/useCheckBank";
import { IBank, IBankField } from "../../../../../../types/profile";
import { DebouncedFunc, debounce } from "lodash";

type BankDetailsProps = {
    bankErrors: BankErrors;
    handleValidateBankAccount: DebouncedFunc<(bank: IBank, fieldType: IBankField) => Promise<void>>;
};
export const BankDetailsContext = createContext<BankDetailsProps>({
    bankErrors: {},
    handleValidateBankAccount: debounce(async () => {}, 300),
});

export const useBankDetailsContext = () => {
    const context = useContext(BankDetailsContext);

    if (!context) {
        throw new Error("error BankDetailsContext");
    }

    return context;
};
