import { InputField } from "@russpass/partner-front-ui";
import { FieldNames, SignLegalBasisEnum } from "../../constants";
import React from "react";
import { useFormikContext } from "formik";
import { SignatoryRoleEnum } from "../../../../../../enums/signatory-role";
import ValidityPeriodField from "./ValidityPeriodField";
import { IssueDateField } from "./IssueDateField";

const SignatoryRole = () => {
    const { getFieldProps } = useFormikContext();

    const isShowAuthority =
        getFieldProps(FieldNames.signatoryRole).value === SignatoryRoleEnum.AGENT ||
        getFieldProps(FieldNames.signLegalBasis).value === SignLegalBasisEnum.VICARIOUS_AUTHORITY;

    if (!isShowAuthority) return <></>;

    return (
        <>
            <InputField
                label="Данные доверенности"
                name={"authority.number"}
                noHover
                placeholder="77 АА 1291193"
                maxLength={50}
            />
            <div className={"fields-grid__authority"}>
                <IssueDateField />
                <ValidityPeriodField name={"authority.term"} />
            </div>
        </>
    );
};

export default SignatoryRole;
