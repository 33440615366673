import React from "react";
import { InputField } from "@russpass/partner-front-ui";
import { maskedInputPassport } from "../../../../../../utils/validation";

export const PassportField = () => (
    <InputField
        label="Серия, номер"
        name="identityNumber"
        noHover
        placeholder="2986 786790"
        renderComponent={maskedInputPassport}
    />
);
