import { saveDocumentData, savePartnerDocs } from "../../../../api/offer";
import { Files, OfferNew, OfferNewForm, SavedFile } from "./OfferTypeForms/types";
import { FileTypeEnum } from "./constants";

export const saveFiles = (applicationId: string, filesData: Files) => {
    const requestArray: Promise<SavedFile>[] = [];
    Object.keys(filesData).forEach((key) => {
        requestArray.push(
            new Promise(async (resolve, reject) => {
                try {
                    const content = await savePartnerDocs(applicationId, filesData[key][0], key);
                    resolve(content);
                } catch (err) {
                    reject(err);
                }
            })
        );
    });
    return Promise.all(requestArray);
};

export const getPartnerInfo = (values: OfferNewForm) => {
    const data: OfferNew = {
        signatory: values.signatory,
        identityNumber: values.identityNumber,
        registrationAddress: values.registrationAddress,
        signatoryRole: values.signatoryRole,
        position: values.position,
        signLegalBasis: values.signLegalBasis,
        documentType: values.documentType,
        electronicType: values.electronicType,
        ndsRate: values.ndsRate,
        bank: values.bank.bic || values.bank.treasuryAccount ? values.bank : undefined,
        authority: values.authority,
    };
    return data;
};

export const saveDocumentsData = async (applicationId: string, values: OfferNewForm, files: SavedFile[]) => {
    if (values.filesData.GUIDE_CERTIFICATE) {
        const savedDocumentData = files.find((file) => file.type === FileTypeEnum.GUIDE_CERTIFICATE);
        if (savedDocumentData && values.guideCertificateData) {
            await saveDocumentData(applicationId, savedDocumentData.documentId, {
                type: savedDocumentData.type,
                regionOfValidity: values.guideCertificateData.regionOfValidity?.id,
                periodOfValidity: values.guideCertificateData.periodOfValidity,
            });
        }
    }
    if (values.filesData.GUIDE_ACCREDITATION) {
        const savedDocumentData = files.find((file) => file.type === FileTypeEnum.GUIDE_ACCREDITATION);
        if (savedDocumentData && values.guideAccreditationData) {
            await saveDocumentData(applicationId, savedDocumentData.documentId, {
                type: savedDocumentData.type,
                periodOfValidity: values.guideAccreditationData.periodOfValidity,
            });
        }
    }
};
