import { useCallback, useState } from "react";
import useUserInfo from "../../../../hooks/useUserInfo";
import { getOffer } from "../../../../api/offer";
import mammoth from "mammoth";
import { ConvertedOfferResult } from "./types";
const useOfferNew = () => {
    const [isLoadingOffer, setIsLoadingOffer] = useState(true);
    const [isErrorViewOffer, setIsErrorViewOffer] = useState(false);
    const [offerHTML, setOfferHTML] = useState("");
    const [offerFile, setOfferFile] = useState<ArrayBuffer | null>(null);

    const { userFullInfo } = useUserInfo();

    const loadOffer = async () => {
        setIsLoadingOffer(true);
        setIsErrorViewOffer(false);
        if (!userFullInfo) return;
        getOffer(userFullInfo.applicationId)
            .then((response: ReadableStream) => {
                new Response(response).arrayBuffer().then((buffer) => {
                    setOfferFile(buffer);
                    mammoth.convertToHtml({ arrayBuffer: buffer }).then((result: ConvertedOfferResult) => {
                        setOfferHTML(result.value);
                        setIsLoadingOffer(false);
                    });
                });
            })
            .catch((error) => {
                console.error(error);
                setIsErrorViewOffer(true);
                setIsLoadingOffer(false);
            });
    };

    const loadOfferFile = useCallback(() => {
        if (!offerFile) return;
        let url = URL.createObjectURL(new Blob([offerFile]));
        let a = document.createElement("a");
        a.href = url;
        a.download = "Оферта.docx";
        a.click();
    }, [offerFile]);

    return {
        isLoadingOffer,
        loadOffer,
        isErrorViewOffer,
        offerHTML,
        loadOfferFile,
    };
};

export default useOfferNew;
