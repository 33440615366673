import { yup } from "../../../../../../yup";
import {
    authorityFileSchema,
    authoritySchema,
    bankSchema,
    documentTypeSchema,
    ndsRateSchema,
    requiredFileSchema,
} from "./validation-schema";
import { FileTypeEnum } from "../../constants";

const CommonFilesSchema = yup.object().shape({
    [FileTypeEnum.INN]: requiredFileSchema,
    [FileTypeEnum.VICARIOUS_AUTHORITY]: authorityFileSchema,
});

const CommonSchema = {
    signatory: yup.string().required(),
    position: yup.string().required().max(200),
    signLegalBasis: yup.string().required(),
    filesData: CommonFilesSchema,
};

export const ServiceULSchemaValidation = yup.object().shape({
    ...CommonSchema,
    authority: authoritySchema,
    ndsRate: ndsRateSchema,
    bank: bankSchema,
    ...documentTypeSchema,
});

export const RestaurantULSchemaValidation = yup.object().shape({
    ...CommonSchema,
});
