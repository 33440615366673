import React, { useMemo, useRef, useState } from "react";
import { CommonLayout } from "@russpass/partner-front-ui";
import OrderList from "./components/order-list";
import { navbarOrders, navbarOrdersNames, orderStatuses } from "./constants";
import { OrderFiltersProvider } from "./context/OrderFiltersProvider";
import { useOrderFiltersContext } from "./context/useOrderFiltersContext";
import { exportOrdersInExcel } from "../../api/orders";
import AttestationMessage from "../../components/AttestationMessage";

const getStatus = (tabName: string) => {
    switch (tabName) {
        case navbarOrdersNames.confirmed:
            return orderStatuses.finished;
        case navbarOrdersNames.rejected:
            return orderStatuses.canceled;
        default:
            return orderStatuses.hold;
    }
};

const Orders = () => {
    const status = useRef<string>(orderStatuses.hold);
    const { filters, sort, isEmpty } = useOrderFiltersContext();
    const [exporting, setExporting] = useState<boolean>(false);

    const topRightButton = useMemo(
        () =>
            !isEmpty
                ? {
                      title: "Выгрузить в Excel",
                      onClick: async () => {
                          try {
                              setExporting(true);
                              await exportOrdersInExcel(
                                  { ...filters, status: status.current },
                                  { field: sort?.field, order: sort?.order }
                              );
                              setExporting(false);
                          } catch (err) {
                              console.error(err);
                          }
                      },
                      isDisabled: exporting,
                      isPrimary: true,
                  }
                : undefined,
        [isEmpty, filters, sort, exporting]
    );

    return (
        <>
            <CommonLayout
                pageTitle="Заявки"
                navbar={navbarOrders}
                topRightButton={topRightButton}
                handlerChangeActiveTab={(name: string | number | undefined) =>
                    (status.current = getStatus(String(name)))
                }
            >
                <div data-name={navbarOrdersNames.new}>
                    <AttestationMessage />
                    <OrderList status={orderStatuses.hold} />
                </div>
                <div data-name={navbarOrdersNames.confirmed}>
                    <OrderList status={orderStatuses.finished} />
                </div>
                <div data-name={navbarOrdersNames.rejected}>
                    <OrderList status={orderStatuses.canceled} />
                </div>
            </CommonLayout>
        </>
    );
};

const OrdersWrapper = () => (
    <OrderFiltersProvider>
        <Orders />
    </OrderFiltersProvider>
);

export default OrdersWrapper;
