import { useCallback, useEffect, useMemo, useState } from "react";
import { getExcursionForms, getExcursionViewOptions, getShowcases } from "../../../../api/dictionaries";
import { DraftExcursionDataPost, ExcursionDataClient } from "../helpers/types";
import { GET_DATA_ERROR } from "../../../../constants/errors";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { ExcursionHoldingSpace, initialExcursion, NavbarExcursionNamesEnum } from "../helpers/constants";
import { showcaseMobileTitle, showcaseRusspassTitle } from "../../../../constants/services";
import { useLocation, useParams } from "react-router-dom";
import { getExcursion } from "../../../../api/excursion";
import { formatDraftExcursionFromGet, formatExcursionFromGet, getHoldingSpaceOptions } from "../helpers/utils";
import { getCity, getRegion } from "../../../../components/async-selects/utils";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { TSelectValue } from "../../../../types/dictionaries";
import { IAtolVat } from "../../../../types/billing";
import { getVatList } from "../../../../api/billing";
import _isEqual from "lodash/isEqual";
import history from "../../../../history";
import { getDraftExcursion } from "../../../../api/draft/draft";
import { ListExcursionDraftItem, StatusNamesServiceItem } from "../../all/types";
import { Tariff } from "../../components/tariffs/types";
import uuid from "react-native-uuid";

type Params = {
    excursionId: string;
};

type Props = {
    loadTariffList: (billingProductId: number, page?: number, size?: number, draftTariffs?: Tariff[]) => void;
    loadDraftTariffList: (draftTariffs: Tariff[]) => void;
};

const useExcursion = ({ loadTariffList, loadDraftTariffList }: Props) => {
    const { excursionId, draftExcursionId }: Params = useParams();
    const location = useLocation();
    const [compareData, setCompareData] = useState<ExcursionDataClient>(initialExcursion);
    const [excursionData, setExcursionData] = useState<ExcursionDataClient>(initialExcursion);
    const [draftExcursionData, setDraftExcursionData] = useState<DraftExcursionDataPost | null>(null);

    const [region, setRegion] = useState<TSelectValue>({
        id: "",
        title: "",
    });
    const [city, setCity] = useState<TSelectValue>({
        id: "",
        title: "",
    });
    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const [excursionForms, setExcursionForms] = useState<TSelectValue[]>([]);
    const [excursionViewOptions, setExcursionViewOptions] = useState<TSelectValue[]>([]);
    const [showcases, setShowcases] = useState<string[]>([]);
    const [holdingSpaceOptions, setHoldingSpaceOptions] = useState<(TSelectValue & { slug?: ExcursionHoldingSpace })[]>(
        []
    );
    const [optionsNdsRate, setOptionsNdsRate] = useState<IAtolVat[]>([]);
    const [step, setStep] = useState<string | number | undefined>(
        new URLSearchParams(location.search).get("tab") || undefined
    );

    const onChangeData = useCallback((values: ExcursionDataClient) => {
        setExcursionData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }, []);

    const isArchive = useMemo(() => {
        return (
            excursionData.status === StatusNamesServiceItem.Inactive ||
            excursionData.status === StatusNamesServiceItem.Deleted
        );
    }, [excursionData]);

    const changeTab = (newStep: string | undefined | number) => {
        if (newStep !== step) {
            setStep(newStep);
            const searchParams = newStep === NavbarExcursionNamesEnum["Excursion"] ? "" : `?tab=${newStep}`;
            history.push({
                pathname: location.pathname,
                search: searchParams,
                state: location?.state,
            });
        }
    };

    const changeRegion = useCallback((region: TSelectValue) => {
        setRegion(region);
    }, []);

    const changeCity = useCallback((city: TSelectValue) => {
        setCity(city);
    }, []);

    const getExcursionDataRequest = useCallback(
        () =>
            new Promise(async (resolve) => {
                try {
                    const excursion = await getExcursion(excursionId);
                    const formatedExcursion = formatExcursionFromGet(initialExcursion, excursion);
                    setExcursionData(formatedExcursion);
                    setCompareData(formatedExcursion);

                    setRegion(await getRegion(excursion.region));
                    setCity(await getCity(excursion.city || ""));

                    if (excursion.billingProductId) {
                        loadTariffList(excursion.billingProductId);
                    }

                    return resolve(excursion);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        [excursionId, loadTariffList]
    );

    const getDraftExcursionDataRequest = useCallback(
        () =>
            new Promise(async (resolve) => {
                try {
                    const excursion: ListExcursionDraftItem = await getDraftExcursion(draftExcursionId);
                    const formattedExcursion = await formatDraftExcursionFromGet(initialExcursion, excursion.draft);
                    formattedExcursion.status = excursion.businessStatus as StatusNamesServiceItem;
                    setExcursionData(formattedExcursion);
                    setCompareData(formattedExcursion);
                    setDraftExcursionData(excursion.draft);

                    if (excursion.draft.region) {
                        setRegion(await getRegion(excursion.draft.region));
                    }
                    if (excursion.draft.city) {
                        setCity(await getCity(excursion.draft.city || ""));
                    }

                    if (excursion.draft.rateObject?.length > 0) {
                        loadDraftTariffList(
                            excursion.draft?.rateObject?.map(
                                (rate) =>
                                    ({
                                        ...rate,
                                        id: uuid.v4(),
                                        registrationOnDate: rate?.registrationOnDateByTime,
                                        registrationOffDate: rate?.registrationOffDateByTime,
                                    } as Tariff)
                            ) || []
                        );
                    }

                    return resolve(excursion);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        [draftExcursionId]
    );

    const reloadExcursionData = () => {
        if (excursionId) {
            setInitialLoading(true);
            getExcursionDataRequest().then(() => setInitialLoading(false));
        }
    };

    const loadData = useCallback(() => {
        setInitialLoading(true);
        let requestArray = [
            new Promise(async (resolve) => {
                try {
                    const { rows } = await getExcursionForms();
                    setExcursionForms(rows);
                    return resolve(rows);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const { rows } = await getShowcases();
                    const _showcases = rows.reduce((prev: string[], current: TSelectValue) => {
                        if (current.title === showcaseRusspassTitle || current.title === showcaseMobileTitle) {
                            return [...prev, current.id];
                        }
                        return prev;
                    }, []);
                    setShowcases(_showcases);
                    return resolve(_showcases);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const { rows } = await getExcursionViewOptions();
                    setExcursionViewOptions(rows);
                    resolve(rows);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const options = await getHoldingSpaceOptions();
                    setHoldingSpaceOptions(options);
                    resolve(options);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
            new Promise(async (resolve) => {
                try {
                    const response = await getVatList();
                    setOptionsNdsRate(response);
                    resolve(response);
                } catch (err) {
                    console.error(err);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: GET_DATA_ERROR,
                    });
                }
            }),
        ];
        if (draftExcursionId) {
            requestArray.push(getDraftExcursionDataRequest());
        }
        if (excursionId) {
            requestArray.push(getExcursionDataRequest());
        }

        Promise.all(requestArray).then(() => {
            setInitialLoading(false);
        });
    }, [excursionId, draftExcursionId, getExcursionDataRequest]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const BILLING_FIELDS = ["personalizedTickets", "paymentType", "ndsPercent"] as Array<keyof ExcursionDataClient>;
    const hasChangesExceptBilling = !_isEqual(
        {
            ...excursionData,
            [BILLING_FIELDS[0]]: undefined,
            [BILLING_FIELDS[1]]: undefined,
            [BILLING_FIELDS[2]]: undefined,
        },
        {
            ...compareData,
            [BILLING_FIELDS[0]]: undefined,
            [BILLING_FIELDS[1]]: undefined,
            [BILLING_FIELDS[2]]: undefined,
        }
    );
    const hasBillingChanges = !_isEqual(
        {
            val1: excursionData[BILLING_FIELDS[0]],
            val2: excursionData[BILLING_FIELDS[1]],
            val3: excursionData[BILLING_FIELDS[2]],
        },
        {
            val1: compareData[BILLING_FIELDS[0]],
            val2: compareData[BILLING_FIELDS[1]],
            val3: compareData[BILLING_FIELDS[2]],
        }
    );

    return {
        initialLoading,
        excursionData,
        compareData,
        excursionForms,
        excursionViewOptions,
        onChangeData,
        reloadExcursionData,
        showcases,
        step,
        changeTab,
        region,
        changeRegion,
        city,
        changeCity,
        isViewExcursion: Boolean(excursionId),
        holdingSpaceOptions,
        optionsNdsRate,
        hasChangesExceptBilling,
        hasBillingChanges,
        draftExcursionId,
        draftExcursionData,
        isArchive,
    };
};

export default useExcursion;
