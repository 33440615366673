import { ModalWindowRight, ProgressSteps } from "@russpass/partner-front-ui";
import React from "react";
import useSuccessSettings from "../OfferTypeForms/hooks/useSuccessSettings";
import history from "../../../../../history";
import routes from "../../../../../constants/routes";

const OfferSuccessModal = () => {
    const { getSteps, isSuccessStepsData, buttonSettings } = useSuccessSettings();
    return (
        <ModalWindowRight
            isOpened={true}
            onClose={() => {
                history.push(routes.offer);
            }}
        >
            <div className="success-steps-message">
                <ProgressSteps
                    steps={getSteps()}
                    title={"Договор отправлен на проверку"}
                    isSuccessData={isSuccessStepsData}
                />
                <button className="button button--primary" onClick={buttonSettings.action}>
                    {buttonSettings.title}
                </button>
            </div>
        </ModalWindowRight>
    );
};

export default OfferSuccessModal;
