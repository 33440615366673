import { SelectInputField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { EDOType } from "./DocumentTypeField";

type Props = {
    options: EDOType[];
};

export const EDOField = ({ options }: Props) => {
    const { setFieldValue, getFieldProps } = useFormikContext();

    const name = "electronicType";

    const value = getFieldProps(name).value;

    return (
        <SelectInputField
            name={name}
            value={options.find((el) => el.id === value) || { id: "", name: "" }}
            handleChange={(value) => {
                setFieldValue(name, value.id);
            }}
            options={options}
            valueField="name"
            labelField="name"
            placeholder="Выбрать"
            label="Наименование системы ЭДО"
        />
    );
};
