import { servicePlaceValidation } from "../../components/service-place/validation-schema";
import { Tariff } from "../../components/tariffs/types";
import { Facility } from "../../types";
import { NewServiceData } from "./types";
import { draftServiceInfoValidation, serviceInfoValidation } from "./validation-schema";

type Props = {
    data: NewServiceData;
    facility: Facility;
    tariffs: Tariff[];
    isSubmittingForm: boolean;
};

export const checkValidServiceFields = ({ data, facility, tariffs, isSubmittingForm }: Props) => {
    const isValidInfo = isSubmittingForm
        ? serviceInfoValidation.isValidSync(data)
        : draftServiceInfoValidation.isValidSync(data);
    const isValidPlace = isSubmittingForm ? servicePlaceValidation.isValidSync(facility) : true;
    const isValidPhoto = isSubmittingForm ? !!data.imagesFiles?.length : true;
    const isValidTariffs = isSubmittingForm ? !!tariffs && tariffs.length > 0 : true;
    const isValidAll = isValidInfo && isValidPlace && isValidPhoto && isValidTariffs;

    return {
        isValidInfo,
        isValidPlace,
        isValidPhoto,
        isValidTariffs,
        isValidAll,
    };
};
