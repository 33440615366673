import { CommonLayout } from "@russpass/partner-front-ui";
import React, { useEffect } from "react";
import useOfferNew from "./useOfferNew";
import styles from "./OfferNew.module.sass";
import { ReactComponent as DownloadIcon } from "../../../../assets/images/icons/download.svg";
import useUserInfo from "../../../../hooks/useUserInfo";
import history from "../../../../history";
import routes from "../../../../constants/routes";
import { sendEvent } from "../../../../product_analytics/analytics";
import { AnalyticEvent } from "../../../../product_analytics/constants";
import "./styles.sass";
import ActionFormWrap from "./ActionForm/ActionFormWrap";

const OfferNew = () => {
    const { offerHTML, isLoadingOffer, isErrorViewOffer, loadOffer, loadOfferFile } = useOfferNew();

    useEffect(() => {
        loadOffer();
    }, []);

    const { isConcluded, isToBeAgreed, isSignedSimpleRegistration, isReview } = useUserInfo();

    const topRightButton =
        isLoadingOffer && !isErrorViewOffer
            ? undefined
            : {
                  title: (
                      <>
                          <DownloadIcon /> &nbsp; Скачать
                      </>
                  ),
                  onClick: loadOfferFile,
              };

    const getStatus = () => {
        let status = "";
        if (isLoadingOffer) return status;
        if (isConcluded) {
            status = "Подписана";
        }
        if (isSignedSimpleRegistration || isReview) {
            status = "На рассмотрении";
        }
        return <div className={styles.status}>{status}</div>;
    };

    const backButton =
        !isLoadingOffer && isConcluded
            ? {
                  title: "Назад",
                  onClick: () =>
                      history.push({
                          pathname: routes.contracts,
                      }),
              }
            : undefined;

    return (
        <CommonLayout
            pageTitle="Оферта"
            isLoadingWithoutContent={isLoadingOffer}
            topRightButton={topRightButton}
            description={getStatus()}
            backButton={backButton}
        >
            <>
                {!isErrorViewOffer && <div className={"offerText"} dangerouslySetInnerHTML={{ __html: offerHTML }} />}
                {isErrorViewOffer && (
                    <div className={styles.error}>
                        <div className={styles.error__title}>Страница не найдена</div>
                        <div className={styles.error__description}>Попробуйте перезагрузить страницу</div>
                        <button className="button button--second" onClick={loadOffer}>
                            Перезагрузить
                        </button>
                    </div>
                )}
                {!isLoadingOffer && isToBeAgreed && (
                    <ActionFormWrap
                        onClick={() => {
                            history.push({
                                pathname: routes.partnerInfo,
                                state: {
                                    background: {
                                        pathname: routes.offer,
                                    },
                                },
                            });
                            sendEvent(AnalyticEvent.proceed_click);
                        }}
                        body="Нажимая на кнопку, вы принимаете условия оферты "
                    />
                )}
            </>
        </CommonLayout>
    );
};

export default OfferNew;
