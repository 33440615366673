import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import { maskedInputNumbers } from "../../../../../../utils/validation";

export const BudgetOrganizationCodeField = () => (
    <InputField
        label="Код бюджетной классификации"
        name={"bank.budgetClassificationCode"}
        noHover
        placeholder="83450189234710041238"
        hint="При наличии"
        errorSettings={{ showCustomError: true }}
        renderComponent={maskedInputNumbers}
        maxLength={20}
    />
);
