import { FILE_TOO_LARGE, FORM_FIELD_REQUIRED_ERROR, FORMAT_ERROR } from "../../../../../../constants/errors";
import { LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS } from "../../../../../../constants/images";
import { yup } from "../../../../../../yup";
import { EdoDocumentTypeEnum, FileTypeEnum, SignLegalBasisEnum } from "../../constants";
import { SignatoryRoleEnum } from "../../../../../../enums/signatory-role";

const checkIsRequired = {
    is: (value: string | null) => !value,
    then: (schema: yup.StringSchema<string | null | undefined>) => schema.required(),
};

const checkFileIsNotTooLarge = (value: File[] | undefined): boolean => {
    if (!value || !value.length) {
        return true;
    } else {
        return value[0].size < LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS * 1024 * 1024;
    }
};

export const requiredFileSchema = yup
    .array()
    .ensure()
    .min(1, FORM_FIELD_REQUIRED_ERROR)
    .test("is-file-too-large", FILE_TOO_LARGE(LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS), checkFileIsNotTooLarge);

export const notRequiredFileSchema = yup
    .array()
    .test("is-file-too-large", FILE_TOO_LARGE(LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS), checkFileIsNotTooLarge);

function checkAuthority(value: string | File[] | undefined) {
    if (value && value.length) return true;
    // @ts-ignore
    const { from } = this;
    return (
        from[1].value.signLegalBasis !== SignLegalBasisEnum.VICARIOUS_AUTHORITY &&
        from[1].value.signatoryRole !== SignatoryRoleEnum.AGENT
    );
}

function checkElectronicType(value: string | undefined) {
    if (value?.length) return true;
    // @ts-ignore
    const { from } = this;
    return from[0].value.documentType !== EdoDocumentTypeEnum.ELECTRONIC;
}

function checkGuideCertificateRegion(value: { id: string } | undefined) {
    if (value?.id?.length) {
        return true;
    }
    // @ts-ignore
    const { from } = this;
    return !from[2]?.value?.filesData[FileTypeEnum.GUIDE_CERTIFICATE];
}

function checkGuideCertificateData(value: string | undefined) {
    if (value?.length) return true;
    // @ts-ignore
    const { from } = this;
    return !from[1]?.value?.filesData[FileTypeEnum.GUIDE_CERTIFICATE];
}

function checkGuideAccreditationData(value: string | undefined) {
    if (value?.length) return true;
    // @ts-ignore
    const { from } = this;
    return !from[1]?.value?.filesData[FileTypeEnum.GUIDE_ACCREDITATION];
}

const electronicTypeSchema = yup.string().test("electronicType", FORM_FIELD_REQUIRED_ERROR, checkElectronicType);

export const guideCertificateDataSchema = yup.object({
    regionOfValidity: yup
        .object()
        .shape({
            id: yup.string().ensure(),
        })
        .test("guideCertificateData.regionOfValidity", FORM_FIELD_REQUIRED_ERROR, checkGuideCertificateRegion),
    periodOfValidity: yup
        .string()
        .test("guideCertificateData.periodOfValidity", FORM_FIELD_REQUIRED_ERROR, checkGuideCertificateData),
});

export const guideAccreditationDataSchema = yup.object({
    periodOfValidity: yup
        .string()
        .test("guideAccreditationData.periodOfValidity", FORM_FIELD_REQUIRED_ERROR, checkGuideAccreditationData),
});

export const authoritySchema = yup.object({
    // Номер доверенности
    number: yup.string().max(50).test("authority.number", FORM_FIELD_REQUIRED_ERROR, checkAuthority),
    // Дата выдачи
    dateIssue: yup.string().test("authority.dateIssue", FORM_FIELD_REQUIRED_ERROR, checkAuthority),
    // Срок действия
    term: yup.string().max(100).test("authority.term", FORM_FIELD_REQUIRED_ERROR, checkAuthority),
});

// Доверенность
export const authorityFileSchema = yup
    .array()
    .test("authority", FORM_FIELD_REQUIRED_ERROR, checkAuthority)
    .test("is-file-too-large", FILE_TOO_LARGE(LIMIT_SIZE_UPLOADED_FILE_OFFER_DOCS), checkFileIsNotTooLarge);

export const bankSchema = yup.object({
    // БИК банка
    bic: yup
        .string()
        .nullable()
        .onlyNumbers()
        .length(9, "В БИК должно быть 9 цифр")
        .when("treasuryAccount", checkIsRequired),
    // Наименование банка
    name: yup.string().nullable().when("treasuryAccount", checkIsRequired).max(300),
    // Кор. счет
    corrAccount: yup
        .string()
        .nullable()
        .onlyNumbers()
        .length(20, "В счете должно быть 20 цифр")
        .when("treasuryAccount", checkIsRequired),
    // Расчетный счет
    checkingAccount: yup
        .string()
        .nullable()
        .onlyNumbers()
        .length(20, "В счете должно быть 20 цифр")
        .when("treasuryAccount", checkIsRequired),
    // Казначейский счет
    treasuryAccount: yup.string().onlyNumbers().nullable().length(20, "В счете должно быть 20 цифр"),
});

export const passportSchema = {
    identityNumber: yup.string().required().length(11, FORMAT_ERROR),
    registrationAddress: yup.object().shape({
        unrestrictedValue: yup.string().required(),
    }),
};

export const documentTypeSchema = {
    documentType: yup.string().required(),
    electronicType: electronicTypeSchema,
};

export const ndsRateSchema = yup.string().required();
