import { authority, FieldNames, SignLegalBasisOptions } from "../../constants";
import { RadioGroupField } from "@russpass/partner-front-ui";
import React from "react";
import { useFormikContext } from "formik";
import { ORGANIZATION_CHART } from "../../../../offer-deprecated/company-info/constants";

export const SignLegalBasisField = () => {
    const { setFieldValue, getFieldProps } = useFormikContext();
    const name = FieldNames.signLegalBasis;
    return (
        <RadioGroupField
            name={name}
            options={SignLegalBasisOptions}
            label="Юр. основание права подписи"
            value={SignLegalBasisOptions.find((option) => option.value === getFieldProps(name).value) || ""}
            onChange={(option) => {
                if (option.value === ORGANIZATION_CHART) {
                    setFieldValue(FieldNames.authority, undefined);
                } else {
                    setFieldValue(FieldNames.authority, authority);
                }
                setFieldValue(name, option.value);
            }}
        />
    );
};
