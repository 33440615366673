import styles from "./ActionFormWrap.module.sass";
import React from "react";
import ActionForm, { ActionFormProps } from "./index";

const ActionFormWrap = (props: ActionFormProps) => {
    return (
        <div className={styles.actionFormWrap}>
            <ActionForm {...props} />
        </div>
    );
};

export default ActionFormWrap;
