import React from "react";
import { ReactComponent as IconAlert } from "../../../../../assets/images/icons/ic_alert_circle.svg";
import styles from "./styles.module.sass";

const TariffHint = ({ text, textStyle }: { text: string; textStyle: string }) => (
    <div className={styles.container}>
        <div>
            <IconAlert />
        </div>
        <div className={textStyle}>{text}</div>
    </div>
);

export default TariffHint;
