import moment from "moment";
import { DayTimeTable, PlaceTimetableItemType } from "../../../components/timetable/place-timetable/types";
import { EventType, Facility, WorkingDay, WorkingTime } from "../types";
import { NewServiceData } from "./helpers/types";
import { MIN_DAY_LONG_MONTH_DATE_FORMAT } from "../../../constants/dates-format";
import { EventPreviewNewDataType } from "../components/EventPreviewModalNew/types";

export const getWorkTimes = (data: PlaceTimetableItemType) => {
    const timesData = {} as WorkingDay;
    if (data.time[0]) {
        const [startTime, endTime] = data.time[0].split(" - ");
        timesData.startTime = startTime;
        timesData.endTime = endTime;
    }
    if (data.breakTime[0]) {
        const [startTime, endTime] = data.breakTime[0].split(" - ");
        timesData.breaks = [
            {
                startTime,
                endTime,
            },
        ];
    }
    return timesData;
};

export const getTimetableData = (values: PlaceTimetableItemType[]) => {
    let newData = {} as any;

    newData.days = {};
    ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"].forEach((day) => {
        newData.days[day] = {
            closed: true,
        };
    });

    values.forEach((item) => {
        const itemDays = getDayTimeTableArray(item.workingDays);
        if (!itemDays.length) {
            return;
        }
        const timesData = getWorkTimes(item);
        if (itemDays[0]?.value === "allDays") {
            delete newData.days;
            newData.all = { ...timesData };
            return;
        }

        itemDays.forEach((day) => {
            newData.days[day.value.toUpperCase()] = { ...timesData, closed: false };
        });
    });
    return newData as WorkingTime;
};

export const getDayTimeTableArray = (data: any[] | undefined) =>
    data ? data.filter((item): item is DayTimeTable => typeof item === "object") : [];

export const getServiceEventPreviewData = (
    data: NewServiceData,
    facility: Facility,
    optionsEventTypes: EventType[]
): EventPreviewNewDataType => ({
    title: data.title || "Название события",
    eventType: optionsEventTypes.find((v) => v.id === data.eventType)?.title || "",
    address: facility.addressDetail?.unrestricted_value || "",
    facilityPlaceTitle: facility.title || "",
    images: data.imagesFiles || [],
    workingDate: data.seasonUnlimited
        ? "Круглогодично"
        : data.seasonStart && data.seasonEnd
        ? `${moment(data.seasonStart).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)} 
            - ${moment(data.seasonEnd).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)}`
        : "",
    description: data.description,
    coordinates: facility.coordinates?.split(", ").map((el) => +el) || [],
    ageRestriction: data.ageRestriction,
    workingTime: {
        emptyValue: "Время работы",
        value:
            facility.workingTime.length > 0 && facility.workingTime[0]?.time[0]
                ? `Сегодня: ${facility.workingTime[0]?.time[0]}`
                : "",
        isShowArrow: true,
    },
});
