import { useCallback } from "react";
import { ListExcursionDraftItem, ListItem } from "./types";
import { getExcursionsAll } from "../../../api/excursion";
import { modifyDraftList, packingEventsByStatus } from "./helper";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { getDrafts } from "../../../api/draft/draft";
import useUserInfo from "../../../hooks/useUserInfo";
import { ServiceCategory } from "./constants";

const useExcursions = () => {
    const { userFullInfo, isGuide, isNotGuideAttestation } = useUserInfo();
    const loadExcursions = useCallback(async () => {
        try {
            const draftExcursions: ListExcursionDraftItem[] = await getDrafts(
                userFullInfo?.applicationId,
                ServiceCategory.Excursions
            );
            const modifiedDraftExcursions = await modifyDraftList(draftExcursions, "draftExcursionId");
            const responseExcursions: ListItem[] = await getExcursionsAll().catch(() => {
                return [];
            });

            const {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            } = packingEventsByStatus(
                modifiedDraftExcursions.concat(responseExcursions),
                isGuide && isNotGuideAttestation
            );

            return {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            };
        } catch (error) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                draftServicesArray: [],
                moderationServicesArray: [],
                publishedServicesArray: [],
                refusedServicesArray: [],
                archiveServicesArray: [],
                allServicesArray: [],
            };
        }
    }, []);

    return {
        loadExcursions,
    };
};

export default useExcursions;
