import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CommonLayout } from "@russpass/partner-front-ui";
import { navbarProfileName } from "./constants";
import Company from "./components/company";
import BankDetails from "./components/bank-details";
import signOut from "../../lib/sign-out";
import { getUserContactInfo } from "../../api/profile";
import { IUserContactInfo } from "../../types/profile";
import { isNotFound } from "../../utils/errors";
import useLoadUserInfo from "../../hooks/useLoadUserInfo";
import { successTitleText } from "../../constants/layout";
import User from "./components/user";
import Contact from "./components/contact";
import { useNavbar } from "./hooks/useNavbar";
import { useRentHousesOwnerProfile } from "../../api/hooks/rentHouses";
import { RentHousesOwnerStatus } from "../../types/rentHouses";
import ProfileStatus from "./components/profile-status";
import { useViewportContext } from "@russpass/partner-front-ui";
import { ReactComponent as IcLogout } from "../../assets/images/icons/ic_log_out.svg";
import styles from "./styles.module.sass";
import classNames from "classnames";
import { ReactComponent as IcExternalLink } from "../../assets/images/icons/ic_external_link.svg";
import useUserInfo from "../../hooks/useUserInfo";
import UserGuide from "./components/user-guide";
import AttestationMessage from "../../components/AttestationMessage";

const Profile = () => {
    const { userFullInfo } = useUserInfo();

    const [contactInfo, setContactInfo] = useState<IUserContactInfo | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { loadUserInfo } = useLoadUserInfo();

    const load = useCallback(() => {
        setIsLoading(true);
        if (!userFullInfo) return;
        loadUserInfo(userFullInfo.email)
            .then((userFullInfo) => {
                setIsLoading(false);
                if (userFullInfo?.applicationId) {
                    getUserContactInfo(userFullInfo?.applicationId)
                        .then((data) => {
                            setContactInfo(data);
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            })
            .catch((error) => {
                if (isNotFound(error)) {
                    signOut();
                    console.error(error);
                }
            });
    }, [loadUserInfo, userFullInfo]);

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFullInfo?.applicationId]);

    const navbar = useNavbar();

    const { isSignedApartment, isGuide, isNotGuideAttestation } = useUserInfo();

    const { data: ownerData } = useRentHousesOwnerProfile(undefined, !isSignedApartment && !isGuide);

    const { isLaptop } = useViewportContext();

    const profileUrl = useMemo(() => {
        if (isGuide) {
            return !isNotGuideAttestation &&
                ownerData?.data &&
                userFullInfo?.cmsPartnerId &&
                ownerData?.data?.status !== RentHousesOwnerStatus.PENDING_UPDATE &&
                ownerData?.data?.status !== RentHousesOwnerStatus.PENDING
                ? `${process.env.REACT_APP_RUSSPASS_URL}/provider-profile/${userFullInfo.cmsPartnerId}`
                : undefined;
        } else {
            return ownerData?.data && userFullInfo?.cmsPartnerId
                ? `${process.env.REACT_APP_RUSSPASS_URL}/renthouse-owner/${userFullInfo.cmsPartnerId}`
                : undefined;
        }
    }, [ownerData?.data, userFullInfo.cmsPartnerId, isGuide, isNotGuideAttestation]);

    const openExternalProfile = useCallback(() => {
        if (!profileUrl) return;
        window.open(profileUrl);
    }, [profileUrl]);

    const description = [
        RentHousesOwnerStatus.PENDING,
        RentHousesOwnerStatus.PENDING_UPDATE,
        RentHousesOwnerStatus.DECLINED,
    ].includes(ownerData?.data?.status as RentHousesOwnerStatus) ? (
        <ProfileStatus />
    ) : undefined;

    const CustomStart =
        isLaptop || !profileUrl ? undefined : (
            <button className={classNames("button button--accent", styles.Start)} onClick={openExternalProfile}>
                {successTitleText.externalLink.text} {successTitleText.externalLink.icon}
            </button>
        );

    const CustomEnd = (
        <div
            className={classNames(styles.End, {
                [styles.End__description]: description,
            })}
        >
            {isLaptop ? (
                <>
                    {profileUrl && (
                        <button className="button button--accent" onClick={openExternalProfile}>
                            <IcExternalLink />
                        </button>
                    )}
                    <button className="button button--accent" onClick={() => signOut()}>
                        <IcLogout />
                    </button>
                </>
            ) : (
                <button className="button button--accent" onClick={() => signOut()}>
                    Выйти
                </button>
            )}
        </div>
    );

    return (
        <CommonLayout
            pageTitle="Профиль"
            navbar={navbar}
            isLoadingWithContent={isLoading}
            description={description}
            customStart={CustomStart}
            customEnd={CustomEnd}
        >
            <AttestationMessage />
            <div data-name={navbarProfileName.user}>
                <User />
            </div>
            <div data-name={navbarProfileName.userGuide}>
                <UserGuide />
            </div>
            <div data-name={navbarProfileName.contact}>
                <Contact data={userFullInfo} refreshData={load} />
            </div>
            <div data-name={navbarProfileName.company}>
                <Company data={userFullInfo} />
            </div>
            <div data-name={navbarProfileName.bankDetails}>
                <BankDetails data={contactInfo} />
            </div>
        </CommonLayout>
    );
};

export default Profile;
