import fetchRequest from "./manager";
import { partnerApiServices, russpassApiServices } from "../constants/api";
import { ITourDataPost, TourDataPatch } from "../containers/services/tour/types";

const tourPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.tours}`;

export const getAllTours = async () => {
    const data = await fetchRequest.get(tourPartnerServiceUrl, null, {
        isProtected: true,
    });
    return data;
};

export const createTour = async (data: ITourDataPost) => {
    const tour = await fetchRequest.post(tourPartnerServiceUrl, data, {
        isProtected: true,
    });
    return tour;
};

export const updateTour = async (data: TourDataPatch) => {
    const tour = await fetchRequest.patch(tourPartnerServiceUrl, data, {
        isProtected: true,
    });
    return tour;
};

export const deleteTour = async (id: string) => {
    const result = await fetchRequest.delete(`${tourPartnerServiceUrl}/${id}`, {
        isProtected: true,
    });
    return result;
};

export const sendTourForApproval = async (id: string) => {
    const content = await fetchRequest.patch(`${tourPartnerServiceUrl}/sendForApproval/${id} `, null, {
        isProtected: true,
    });
    return content;
};

export const getTourById = async (id: string) => {
    const tour = await fetchRequest.get(`${tourPartnerServiceUrl}/${id}`, null, {
        isProtected: true,
    });
    return tour;
};
