import { ReactComponent as TooltipIcon } from "../../assets/images/icons/ic_clock_info.svg";
import styles from "./AttextationMessage.module.sass";
import { attestationForGuideMail } from "../../constants";
import useUserInfo from "../../hooks/useUserInfo";

const AttestationMessage = () => {
    const { isGuide, isNotGuideAttestation } = useUserInfo();

    if (!isGuide || !isNotGuideAttestation) {
        return <></>;
    }

    return (
        <div className="content-wrapper content-wrapper--large">
            <div className={styles.message}>
                <TooltipIcon />
                <div className={styles.message__text}>
                    С 1 марта 2025 года изменились правила туристической деятельности в России: теперь гидам необходима
                    аттестации для оказания услуг. Ваши экскурсии временно скрыты на сайте — пожалуйста, отправьте
                    аттестат на &nbsp;
                    <a className={styles.message__link} href={`mailto:${attestationForGuideMail}`}>
                        {attestationForGuideMail}
                    </a>
                    , чтобы принимать заявки и добавлять новые экскурсии
                </div>
            </div>
        </div>
    );
};

export default AttestationMessage;
