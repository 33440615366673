import history from "../../../../history";
import routes from "../../../../constants/routes";
import { ModalWindowRight } from "@russpass/partner-front-ui";
import React from "react";
import ActionForm from "../OfferNew/ActionForm";
import { Formik, FormikProps } from "formik";
import IndividualForm from "./OfferTypeForms/forms/IndividualForm";
import ULForm from "./OfferTypeForms/forms/ULForm";
import SEForm from "./OfferTypeForms/forms/SEForm";
import "./styles.sass";
import { offerFormInitialData } from "./constants";
import { GuideSESchemaValidation } from "./OfferTypeForms/validation-schema/SESchema";
import useSubmitOffer from "./OfferTypeForms/hooks/useSubmitOffer";
import useCheckBank from "./OfferTypeForms/hooks/useCheckBank";
import { BankDetailsContext } from "./OfferTypeForms/context/BankDetailsContext";
import { LegalStateEnum } from "../../../../enums/profile";
import useUserInfo from "../../../../hooks/useUserInfo";
import {
    GuideIndividualSchemaValidation,
    RestaurantIndividualSchemaValidation,
    ServiceIndividualSchemaValidation,
} from "./OfferTypeForms/validation-schema/IndividualSchema";
import { RestaurantULSchemaValidation, ServiceULSchemaValidation } from "./OfferTypeForms/validation-schema/ULSchema";

const CompanyInfoNew = () => {
    const { isSendingForm, handlerSubmitOffer } = useSubmitOffer();

    const { bankErrors, handleValidateBankAccount } = useCheckBank();
    const { userFullInfo, isGuide, isDisplayObject, isRestaurant } = useUserInfo();
    const { legalState } = userFullInfo;

    const isSE = legalState === LegalStateEnum.SELF_EMPLOYED;
    const isIP = legalState === LegalStateEnum.INDIVIDUAL;
    const isUL = legalState === LegalStateEnum.LEGAL_ENTITY;

    const getValidationSchema = () => {
        if (isIP) {
            if (isGuide) return GuideIndividualSchemaValidation;
            if (isRestaurant) return RestaurantIndividualSchemaValidation;
            if (isDisplayObject) return ServiceIndividualSchemaValidation;
        }
        if (isSE && isGuide) {
            return GuideSESchemaValidation;
        }
        if (isUL) {
            if (isRestaurant) return RestaurantULSchemaValidation;
            if (isDisplayObject) return ServiceULSchemaValidation;
        }
    };

    return (
        <BankDetailsContext.Provider
            value={{
                bankErrors,
                handleValidateBankAccount,
            }}
        >
            <Formik
                initialValues={offerFormInitialData}
                validationSchema={getValidationSchema()}
                enableReinitialize
                validateOnMount
                onSubmit={(values) => {
                    if (bankErrors.checkingAccount || bankErrors.corrAccount) return;
                    handlerSubmitOffer(values);
                }}
            >
                {({ handleSubmit }: FormikProps<any>) => {
                    return (
                        <ModalWindowRight
                            isOpened={true}
                            onClose={() => {
                                history.push(routes.offer);
                            }}
                            title={"Оферта"}
                            isLoadingWithContent={isSendingForm}
                            ComponentFooter={<ActionForm onClick={handleSubmit} buttonText="Подписать оферту" />}
                        >
                            <>
                                {isSE && <SEForm />}
                                {isIP && <IndividualForm />}
                                {isUL && <ULForm />}
                            </>
                        </ModalWindowRight>
                    );
                }}
            </Formik>
        </BankDetailsContext.Provider>
    );
};

export default CompanyInfoNew;
