import { russpassApiServices } from "../../constants/api";
import fetchRequest from "../manager";
import { DraftExcursionDataPost } from "../../containers/services/excursion/helpers/types";
import { ServiceCategory } from "../../containers/services/all/constants";

const filesDraftServiceUrl = `${russpassApiServices.draft}`;

export const saveExcursionDraft = async (externalPartnerId: string, draft: DraftExcursionDataPost) => {
    const data = await fetchRequest.post(
        `${filesDraftServiceUrl}/excursions`,
        {
            externalPartnerId,
            typeSource: "LKP",
            draft,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const patchExcursionDraft = async (id: string, draft: DraftExcursionDataPost) => {
    const data = await fetchRequest.patch(
        `${filesDraftServiceUrl}/excursions`,
        {
            id,
            draft,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const deleteDraft = async (id: string, category: ServiceCategory) => {
    const data = await fetchRequest.delete(`${filesDraftServiceUrl}/${category}/${id}`, {
        isProtected: true,
    });
    return data;
};

export const getDrafts = async (externalPartnerId: string, category: ServiceCategory) => {
    const data = await fetchRequest.get(
        `${filesDraftServiceUrl}/${category}`,
        {
            externalPartnerId,
        },
        {
            isProtected: true,
        }
    );
    return data;
};

export const getDraftExcursion = async (id: string) => {
    const data = await fetchRequest.get(`${filesDraftServiceUrl}/excursions/${id}`, null, {
        isProtected: true,
    });
    return data;
};
