import React, { useMemo, useState, useCallback } from "react";
import { CommonLayout, StatusRequestEnum, UploadPhotosItemNew, useModalAlertContext } from "@russpass/partner-front-ui";
import { navbarServiceComment, NavbarServiceNamesEnum, navbarServiceRatings } from "./helpers/constants";
import ServiceInfo from "./components/service-info";
import "./styles.sass";
import { NotificationProps } from "../../../components/notification";
import useService from "./hooks/useService";
import history from "../../../history";
import { useServiceActions } from "./hooks/useServiceActions";
import eventDispatcher from "../../../lib/event-dispatcher";
import ServiceComment from "../components/moderation-comment/service-comment";
import Tariffs from "../components/tariffs/tariffs";
import { useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { navbarServicesNames, navbarTypeEventNames, ServiceCategory } from "../all/constants";
import { Ratings } from "../components/ratings/ratings";
import { ServiceTariffsContext } from "../components/tariffs/tariffs-context";
import useTariffs from "../components/tariffs/useTariffs";
import ActionsContainer from "../components/actions-container";
import ServicePlace from "../components/service-place";
import useFacility from "../components/service-place/useFacility";
import { StatusNamesServiceItem } from "../all/types";
import { alertConfirmSaveText, alertNoBasicTariffText, backButtonText } from "./helpers/modalTexts";
import { getServiceEventPreviewData } from "./utils";

import _omit from "lodash/omit";
import { OnlineSaleSwitch } from "../components/OnlineSaleSwitch";
import { ReactComponent as IcExternalLink } from "../../../assets/images/icons/ic_external_link.svg";
import PhotosNew from "../components/photosNew";
import { isEqualObjects } from "../../../utils/common";
import { ServiceContext } from "./context/service-context";
import { EventPreviewModalNew } from "../components/EventPreviewModalNew";
import { deleteDraft } from "../../../api/draft/draft";
import { deleteDraftImage } from "../components/photosNew/utils";
import RouteLeavingGuard from "../../../components/RouteLeavingGuard/RouteLeavingGuard";
import { Tariff, TariffDates } from "../components/tariffs/types";
import useUserInfo from "../../../hooks/useUserInfo";

const ServiceComponent = () => {
    const { isConcluded } = useUserInfo();

    const [isOpenPreviewWindow, setIsOpenPreviewWindow] = useState(false);
    const [isEditMode, setEditMode] = useState(false);

    const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(true);

    const openServicePreviewWindow = useCallback(() => setIsOpenPreviewWindow(true), [setIsOpenPreviewWindow]);
    const closeServicePreviewWindow = useCallback(() => setIsOpenPreviewWindow(false), [setIsOpenPreviewWindow]);

    const {
        tariffs,
        initialTariffs,
        loadTariffList,
        onChangeTariffList,
        isLoadingTariffList,
        tariffDates,
        setTariffDates,
        initialTariffDates,
        currentBasicTariff,
        loadDraftTariffList,
    } = useTariffs();

    const { openModalAlert } = useModalAlertContext();

    const location = useLocation<{ status: string }>();

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const {
        data,
        initialData,
        step,
        optionsEventTypes,
        optionsAgeRestriction,
        optionsNdsRate,
        changeTab,
        onChangeData,
        reloadServiceData,
        initialLoading,
        hasChangesExceptBilling,
        hasBillingChanges,
        draftServiceId,
        isArchive,
    } = useService({ callNotification, loadTariffList, loadDraftTariffList });

    const {
        facility,
        city,
        region,
        selectedPartnerFacility,
        isExistingPartnerFacility,
        optionsPlaceTypes,
        optionsFacilityPlaces,
        onChangeFacility,
        onChangeCity,
        onChangeRegion,
        hasChangesFacility,
        changePartnerFacility,
        reloadFacilityData,
        isLoadingFacility,
    } = useFacility({
        callNotification,
        facilityId: data.facility,
        draftFacilityData: data?.facilityObject,
    });

    const reloadData = () => {
        reloadServiceData();
        reloadFacilityData();
    };

    const isAllowPublic = useMemo(() => {
        return isArchive && data.comment === "ранее опубликован, модерация не требуется";
    }, [isArchive, data]);

    const {
        sendServiceToModerate,
        sendServiceUpdate,
        isValidFacilityWorkingTime,
        isSubmittingForm,
        isFormSending,
        setIsFormSending,
        navbar,
        addTariffForExistingService,
        deleteTariffForExistingService,
        updateTariffForExistingService,
        addTariffForDraftService,
        deleteTariffForDraftService,
        updateTariffForDraftService,
        updateServiceFields,
        sendToDraft,
        isDraftSave,
    } = useServiceActions({
        data,
        initialData,
        tariffs: tariffs.content,
        facility,
        selectedPartnerFacility,
        isExistingPartnerFacility,
        onChangeTariff: onChangeTariffList,
        setEditMode,
        onChangeData,
        reloadData,
        isArchive,
    });
    const isCreateMode = data.status === StatusNamesServiceItem.Creating;
    const isViewMode = data.status !== StatusNamesServiceItem.Creating;

    const isViewComment = !!data.isRejected || !!facility.isRejected;
    const hasBasicTariff = tariffs.content.length > 0 && tariffs.content.find((i) => i.isBasic);

    const back = () => {
        history.push({
            pathname: routes.servicesAll,
            state: { ...location?.state, type: navbarTypeEventNames.events },
        });
    };

    const localNavbar = useMemo(() => {
        let _navbar = navbar;
        if (isViewComment) {
            _navbar = navbarServiceComment.concat(_navbar);
        }
        if (isViewMode && !draftServiceId) {
            _navbar = _navbar.concat(navbarServiceRatings);
        }

        return _navbar;
    }, [navbar, isViewComment, isViewMode, draftServiceId]);

    const currentTabIndex = localNavbar.findIndex((tab) => tab.name === step);

    const alertNoBasicTariff = () => {
        changeTab(NavbarServiceNamesEnum.Tariff);
        openModalAlert(alertNoBasicTariffText, () => {});
    };

    const alertConfirmSave = () => {
        openModalAlert(
            alertConfirmSaveText,
            () => sendServiceUpdate(hasBillingChanges, hasChangesExceptBilling),
            () => {}
        );
    };

    const isChangedForm = useMemo(() => {
        return (
            !isEqualObjects(_omit(data, "ticketPrice"), _omit(initialData, "ticketPrice")) ||
            !isEqualObjects(tariffs, initialTariffs) ||
            hasChangesFacility
        );
    }, [data, initialData, tariffs, initialTariffs, hasChangesFacility]);

    const backButton = {
        title: (isChangedForm && (isCreateMode || draftServiceId) && "Сохранить и выйти") || "Выйти",
        onClick: () => {
            if (!isChangedForm) {
                setIsVisibleConfirmModal(false);
                back();
                return;
            }
            if (isCreateMode || draftServiceId) {
                sendToDraft(tariffDates)
                    .then(() => {
                        setIsVisibleConfirmModal(false);
                        history.push({
                            pathname: routes.servicesAll,
                            state: { status: navbarServicesNames.draft },
                        });
                    })
                    .catch(() => {});
            } else {
                back();
            }
        },
    };

    const submitTariffRegistrationDates = useCallback(
        async (tariffDates: TariffDates) => {
            if (!isFormSending) {
                const tariffRequests = tariffs.content
                    .filter((tariff: Tariff) => {
                        const activeSinceTime = new Date(tariff.activeSince);
                        if (tariff.openedSince) {
                            activeSinceTime.setHours(Number(tariff.openedSince.split(":")[0]));
                            activeSinceTime.setMinutes(Number(tariff.openedSince.split(":")[1]));
                            activeSinceTime.setSeconds(Number(tariff.openedSince.split(":")[2]));
                        }
                        const currentTime = new Date().getTime();
                        return activeSinceTime.getTime() > currentTime;
                    })
                    .map((tariff: Tariff) => {
                        updateTariffForExistingService({ ...tariff, ...tariffDates }, false);
                    });

                await Promise.all(tariffRequests);
                setIsFormSending(false);
            }
        },
        [hasBasicTariff, isFormSending, tariffs]
    );

    const isLastTab = step === localNavbar[localNavbar.length - 1].name;
    const isTariffsTab = useMemo(() => step === NavbarServiceNamesEnum.Tariff, [step]);
    const isRatingsTab = step === NavbarServiceNamesEnum.Ratings;

    const isUnchangedRegistrationDates = useMemo(
        () =>
            isTariffsTab &&
            initialTariffDates.registrationOnDateByTime === tariffDates.registrationOnDateByTime &&
            initialTariffDates.registrationOffDateByTime === tariffDates.registrationOffDateByTime,
        [tariffDates, initialTariffDates, isTariffsTab]
    );

    const actionsObj = {
        setEdit: {
            text: "Редактировать",
            action: () => setEditMode(true),
        },
        sendModerate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                if (!isFormSending) {
                    setIsVisibleConfirmModal(false);
                    sendServiceToModerate(tariffDates)
                        .then(async () => {
                            if (draftServiceId) {
                                try {
                                    await deleteDraft(draftServiceId, ServiceCategory.Services);
                                    deleteDraftImage(data.imagesFiles);
                                } catch (err) {
                                    console.error(err);
                                    callNotification({
                                        status: StatusRequestEnum.Error,
                                        title: `Не удалось удалить черновик. Обратитесь в службу поддержки`,
                                    });
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setIsVisibleConfirmModal(true);
                        });
                }
            },
        },
        next: {
            text: "Далее",
            action: () => {
                if (step === NavbarServiceNamesEnum.Tariff && !hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                changeTab(localNavbar[currentTabIndex + 1].name);
            },
        },
        save: {
            text: "Сохранить",
            action: () => (hasBillingChanges ? alertConfirmSave() : setEditMode(false)),
        },
        sendServiceUpdate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                if (!isFormSending) {
                    setIsVisibleConfirmModal(false);
                    sendServiceUpdate(hasBillingChanges, hasChangesExceptBilling, undefined, isArchive);
                }
            },
        },
        sendPublish: {
            text: "Опубликовать",
            action: () => {
                setIsVisibleConfirmModal(false);
                sendServiceUpdate(false, true, false);
            },
        },
        saveTariffs: {
            text: "Сохранить",
            action: () => submitTariffRegistrationDates(tariffDates),
        },
    };

    const nextButton = () => {
        // если сохраняем поля продаж для тарифов
        if (isTariffsTab && !isCreateMode) {
            return actionsObj["saveTariffs"];
        }
        // при просмотре архива
        if (isArchive && !isEditMode && isAllowPublic && isConcluded) {
            return actionsObj["sendPublish"];
        }
        //при обычном просмотре
        if (isViewMode && !isEditMode) {
            return actionsObj["setEdit"];
        }
        //при создании
        if (!isViewMode && !isEditMode && isConcluded) {
            return isLastTab ? actionsObj["sendModerate"] : actionsObj["next"];
        }
        //при редактировании
        if (isEditMode && isConcluded) {
            if (draftServiceId) {
                return isLastTab ? actionsObj["sendModerate"] : actionsObj["next"];
            }
            //если изменили все остальные любые поля
            if (hasChangesExceptBilling || hasChangesFacility) {
                return actionsObj["sendServiceUpdate"];
            }
            // если изменили поля билинга
            if (!hasChangesExceptBilling) {
                return actionsObj["save"];
            }
            // по стандарту
            return actionsObj["save"];
        }
        if (((!isViewMode && !isEditMode) || (isEditMode && draftServiceId)) && !isConcluded && !isLastTab) {
            return actionsObj["next"];
        }
        return { action: undefined, text: "" };
    };

    const serviceProviderValue = useMemo(
        () => ({
            draftServiceId,
            isSubmittingForm,
        }),
        [draftServiceId, isSubmittingForm]
    );

    const tariffsProviderValue = useMemo(
        () => ({
            tariffs,
            loadTariffList,
            billingProductId: data.billingProductId,
            onChangeTariffList,
        }),
        [data.billingProductId, loadTariffList, onChangeTariffList, tariffs]
    );

    const onChangeImage = useCallback(
        (values: UploadPhotosItemNew[]) => onChangeData({ ...data, imagesFiles: values }),
        [data, onChangeData]
    );

    const handleToggleIsCanBuySwitch = async () => {
        if (isViewMode) {
            await updateServiceFields({
                isCanBuy: !data.isCanBuy,
            });
        } else {
            onChangeData({ ...data, isCanBuy: !data.isCanBuy });
            callNotification({
                status: StatusRequestEnum.Success,
                body: data.isCanBuy
                    ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                    : "Продажа на RUSSPASS включена",
            });
        }
    };

    const disabledFields = isViewMode && !isEditMode;

    const hasTariffs = tariffs.content.length > 0;

    const isVisibleActions = useMemo(() => {
        if (isRatingsTab) {
            return false;
        } else {
            return isEditMode ? true : !initialLoading && !isOpenPreviewWindow && !isUnchangedRegistrationDates;
        }
    }, [
        initialLoading,
        isEditMode,
        isOpenPreviewWindow,
        isTariffsTab,
        isViewMode,
        isRatingsTab,
        isUnchangedRegistrationDates,
    ]);

    const isDisabledFacilityForm = useMemo(() => {
        if (isCreateMode) {
            return facility.cmsFacilityId && facility.cmsFacilityId === selectedPartnerFacility?.cmsFacilityId;
        }
        return !isEditMode;
    }, [isCreateMode, facility, selectedPartnerFacility, isEditMode]);

    const topRightButton = useMemo(
        () =>
            data.status === StatusNamesServiceItem.Active
                ? {
                      title: (
                          <>
                              Открыть на RUSSPASS <IcExternalLink className="button__icon button__icon--right" />
                          </>
                      ),
                      onClick: () => {
                          window.open(`${data?.url}`);
                      },
                      iconMobile: <IcExternalLink className="button__icon button__icon--right" />,
                  }
                : undefined,
        [data]
    );

    const isShowError = useMemo(() => isSubmittingForm || isDraftSave, [isSubmittingForm, isDraftSave]);

    return (
        <ServiceContext.Provider value={serviceProviderValue}>
            <ServiceTariffsContext.Provider value={tariffsProviderValue}>
                <CommonLayout
                    pageTitle={data.title || "Новая услуга"}
                    backButton={backButton}
                    activeTabName={step}
                    handlerChangeActiveTab={changeTab as any} //props has bad types that reason why using any
                    isLoadingWithoutContent={initialLoading}
                    isLoadingWithContent={isFormSending || isLoadingTariffList || isLoadingFacility}
                    navbar={localNavbar}
                    fullHeight={step === NavbarServiceNamesEnum.Ratings}
                    isHideControlPanel
                    topRightButton={topRightButton}
                >
                    <div data-name={NavbarServiceNamesEnum.Comment} data-hide={!isViewComment}>
                        <ServiceComment reasons={[data.rejectionReason, facility.rejectionReason]} />
                    </div>
                    <div data-name={NavbarServiceNamesEnum.Service}>
                        <ServiceInfo
                            data={data}
                            optionsType={optionsEventTypes}
                            optionsAgeRestriction={optionsAgeRestriction}
                            optionsNdsRate={optionsNdsRate}
                            onChange={onChangeData}
                            isShowErrors={isShowError}
                            disabled={disabledFields}
                        />
                    </div>
                    <div data-name={NavbarServiceNamesEnum.Place}>
                        <ServicePlace
                            facility={facility}
                            isDraft={!!draftServiceId}
                            isSendToModerate={isSubmittingForm}
                            isDisabledChooserFacility={isViewMode && !isEditMode}
                            isDisabledFacilityForm={isDisabledFacilityForm || undefined}
                            optionsPlaceTypes={optionsPlaceTypes}
                            onChangeFacility={onChangeFacility}
                            isShowErrors={isShowError}
                            region={region}
                            onChangeRegion={onChangeRegion}
                            city={city}
                            onChangeCity={onChangeCity}
                            isShowChooser={draftServiceId ? isEditMode : true}
                            optionsFacilityPlaces={optionsFacilityPlaces}
                            selectedPartnerFacility={selectedPartnerFacility}
                            changePartnerFacility={changePartnerFacility}
                        />
                    </div>
                    <div data-name={NavbarServiceNamesEnum.Photo}>
                        <PhotosNew
                            items={data.imagesFiles}
                            disabled={disabledFields}
                            onChange={onChangeImage}
                            isShowError={isSubmittingForm}
                        />
                    </div>
                    <div data-name={NavbarServiceNamesEnum.Tariff}>
                        {hasTariffs && <OnlineSaleSwitch onToggle={handleToggleIsCanBuySwitch} value={data.isCanBuy} />}
                        <Tariffs
                            isExistingService={!isCreateMode && !draftServiceId}
                            addTariffForExistingService={
                                draftServiceId ? addTariffForDraftService : addTariffForExistingService
                            }
                            deleteTariffForExistingService={
                                draftServiceId ? deleteTariffForDraftService : deleteTariffForExistingService
                            }
                            updateTariffForExistingService={
                                draftServiceId ? updateTariffForDraftService : updateTariffForExistingService
                            }
                            isShowValiditySwitcher={isValidFacilityWorkingTime && facility.workingTime.length > 0}
                            facilityWorkingTime={facility.workingTime}
                            updateDataField={(val) => updateServiceFields({ ticketPrice: val })}
                            isDisplayRegistration={hasTariffs}
                            currentBasicTariff={currentBasicTariff}
                            tariffDates={tariffDates}
                            setTariffDates={setTariffDates}
                        />
                    </div>
                    <div data-name={NavbarServiceNamesEnum.Ratings}>
                        <Ratings id={data.cmsEventId} />
                    </div>
                    <>
                        {isVisibleActions && (
                            <ActionsContainer
                                onClickNextButton={nextButton().action}
                                nextButtonText={nextButton().text}
                                onClickPreviewButton={openServicePreviewWindow}
                                showPreviwButton={isCreateMode || isEditMode || (isViewMode && isTariffsTab)}
                                showSecondButton={!isEditMode && isArchive && isAllowPublic}
                                secondButtonText={actionsObj["setEdit"].text}
                                onClickSecondButton={actionsObj["setEdit"].action}
                            />
                        )}
                    </>
                </CommonLayout>
                <RouteLeavingGuard
                    when={isVisibleConfirmModal && (isCreateMode || draftServiceId)}
                    navigate={(path) => history.push(path)}
                    details={{
                        title: "Сохранить услугу в Черновике?",
                        OKButton: "Сохранить",
                        cancelButton: "Выйти без сохранения",
                    }}
                    shouldBlockNavigation={(nextLocation) => {
                        return isChangedForm && nextLocation.pathname !== location.pathname;
                    }}
                    onOKButtonClickAction={async () => {
                        try {
                            await sendToDraft(tariffDates);
                        } catch (err) {
                            return Promise.reject(new Error(err));
                        }
                    }}
                    isNavigateOnclickCancel={true}
                />

                <RouteLeavingGuard
                    when={isVisibleConfirmModal && !isCreateMode && !draftServiceId}
                    navigate={(path) => history.push(path)}
                    details={{
                        title: "При выходе внесенные изменения не сохранятся",
                        description: "Чтобы сохранить их, вернитесь к редактированию и отправьте данные на модерацию",
                        OKButton: "Вернуться к редактированию",
                        cancelButton: "Выйти без сохранения",
                    }}
                    shouldBlockNavigation={(nextLocation) => {
                        return isChangedForm && nextLocation.pathname !== location.pathname;
                    }}
                    onOKButtonClickAction={async () => {
                        return Promise.reject();
                    }}
                    isNavigateOnclickCancel={true}
                />

                <EventPreviewModalNew
                    isOpened={isOpenPreviewWindow}
                    onClose={closeServicePreviewWindow}
                    data={getServiceEventPreviewData(data, facility, optionsEventTypes)}
                />
            </ServiceTariffsContext.Provider>
        </ServiceContext.Provider>
    );
};

export default ServiceComponent;
