import { PaymentTypeEnum } from "./enums";

export const SERVICE_MAX_TITLE_LENGTH = 255;

export const PAYMENT_TYPE_OPTIONS = [
    {
        value: PaymentTypeEnum.SingleStage,
        label: "Без подтверждения",
        hint: "Моментальная оплата заказа без вашего подтверждения",
    },
    { value: PaymentTypeEnum.TwoStage, label: "С подтверждением", hint: "Оплата заказа после вашего подтверждения" },
];

export const PERSONALIZED_TICKET_OPTIONS = [
    { label: "Нет", value: false },
    { label: "Да", value: true },
];
