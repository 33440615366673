import { Divider, MultiMenu, MultiMenuRef, RadioButton } from "@russpass/partner-front-ui";
import React, { useCallback, useRef } from "react";
import cl from "./styles.module.sass";
import classNames from "classnames";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/ic_plus.svg";
import { PartnerFacilityType } from "../../../types";
import { partnerFacilityDefaultValue } from "../mock";
import { FORM_FIELD_REQUIRED_ERROR } from "../../../../../constants/errors";

type PartnerFacilityChooserProps = {
    options: PartnerFacilityType[];
    selectedPartnerFacility: PartnerFacilityType | null;
    handleChange: (value: PartnerFacilityType) => void;
    disabled: boolean;
    placeholder?: string;
    isShowErrors?: boolean;
};

type ActionsComponentProps = {
    handleClick: () => void;
    isShowDivider: boolean;
};
const ActionsComponent = ({ handleClick, isShowDivider }: ActionsComponentProps) => (
    <>
        {isShowDivider && <Divider fullWidth className={cl.divider} />}
        <button className={classNames(cl.AddButton, "button")} onClick={handleClick}>
            <Plus />
            <span className={cl.AddButton__text}>Добавить новое место</span>
        </button>
    </>
);

const PartnerFacilityChooser = ({
    options,
    selectedPartnerFacility,
    handleChange,
    disabled,
    isShowErrors,
}: PartnerFacilityChooserProps) => {
    const MenuRef = useRef<MultiMenuRef>(null);

    const closeMenu = useCallback(() => MenuRef.current?.closeMenu(), []);

    const handleClickByRadioButton = useCallback(
        (value: PartnerFacilityType) => {
            handleChange(value);
            closeMenu();
        },
        [closeMenu, handleChange]
    );

    const handleClickByAddButton = useCallback(() => {
        handleChange(partnerFacilityDefaultValue);
        closeMenu();
    }, [closeMenu, handleChange]);

    return (
        <MultiMenu
            noHover
            ref={MenuRef}
            placeholder="Выбрать"
            label="Место проведения"
            disabled={disabled}
            displayingValue={selectedPartnerFacility?.title}
            error={(isShowErrors && !selectedPartnerFacility && FORM_FIELD_REQUIRED_ERROR) || ""}
            hideArrow={disabled}
            actionsComponent={
                <ActionsComponent handleClick={handleClickByAddButton} isShowDivider={options.length > 0} />
            }
        >
            {options.map((option) => (
                <li key={option.cmsFacilityId} className={cl.RadioButton}>
                    <RadioButton
                        label={option.title}
                        value={option.cmsFacilityId}
                        isActive={option.cmsFacilityId === selectedPartnerFacility?.cmsFacilityId}
                        handleClick={() => handleClickByRadioButton(option)}
                    />
                </li>
            ))}
        </MultiMenu>
    );
};

export default PartnerFacilityChooser;
