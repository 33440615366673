import { ObjectItemType } from "@russpass/partner-front-ui";
import { DraftExcursionDataPost } from "../excursion/helpers/types";
import { DraftServiceDataPost } from "../service/helpers/types";
import { DraftTourDataPost } from "../tour/types";

export type StatusEntity = {
    id: number;
    name: string;
};

export type ListDraftItem = {
    createDate: string;
    businessStatus: string;
    id: string;
    updateDate: string;
};

export type ListExcursionDraftItem = ListDraftItem & {
    draft: DraftExcursionDataPost;
};

export type ListServiceDraftItem = ListDraftItem & {
    draft: DraftServiceDataPost;
};

export type ListTourDraftItem = ListDraftItem & {
    draft: DraftTourDataPost;
};

export type ListItem = {
    creationDt: string;
    isRejected: boolean;
    status: string;
    title: string;
    imageExplorePreview?: string[];
    cmsExcursionId?: string;
    cmsServiceId?: string;
    cmsTourId?: string;
    draftExcursionId?: string;
    draftServiceId?: string;
    draftTourId?: string;
    info?: DraftExcursionDataPost;
};

export type PromiseSettings = {
    promise: Promise<unknown>;
    cancel: () => void;
};

export type SortedServiceList = {
    draftServicesArray: ObjectItemType[];
    moderationServicesArray: ObjectItemType[];
    publishedServicesArray: ObjectItemType[];
    refusedServicesArray: ObjectItemType[];
    archiveServicesArray: ObjectItemType[];
    allServicesArray: ObjectItemType[];
};

export enum CmsIdAnchor {
    cmsServiceId = "cmsServiceId",
    cmsExcursionId = "cmsExcursionId",
    cmsTourId = "cmsTourId",
}

export enum StatusNamesServiceItem {
    Draft = "draft",
    Deleted = "deleted",
    Inactive = "inactive",
    Import = "import",
    Active = "active",
    Creating = "",
}
