import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import { maskedInputNumbers } from "../../../../../../utils/validation";

export const OKTMOField = () => (
    <InputField
        label="ОКТМО"
        name={"bank.oktmo"}
        noHover
        placeholder="89230718"
        hint="При наличии"
        errorSettings={{ showCustomError: true }}
        renderComponent={maskedInputNumbers}
        maxLength={11}
    />
);
